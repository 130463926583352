import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import CardList from '../../cardsList/index';
import appIcon from 'images/app-icon.png';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Blog Cards List - Grid'} />
        <div className="section">
          <CardList
            title="Lorem ipsum dolor sit amet consectetur"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            type="blog"
            data={[
              {
                title:
                  'Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
            ]}
          />
        </div>
      </div>
      <div className="demo">
        <Heading title={'Blog Cards List - Slider'} />
        <div className="section">
          <CardList
            title="Lorem ipsum dolor sit amet consectetur"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            type="blog"
            slider
            data={[
              {
                title:
                  'Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                className: 'custom-class',
              },
            ]}
          />
        </div>
      </div>
      <div className="demo">
        <Heading title={'Testimonial Cards List - Slider'} />
        <div className="section">
          <CardList
            title="Testimonials Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            type="testimonial"
            slider
            data={[
              {
                rating: 5,
                review:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                name: 'Julia Smith',
                designation: 'Co-founder at Company',
                className: 'custom-class',
              },
              {
                rating: 5,
                review:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                name: 'Julia Smith',
                designation: 'Co-founder at Company',
                className: 'custom-class',
              },
              {
                rating: 5,
                review:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                name: 'Julia Smith',
                designation: 'Co-founder at Company',
                className: 'custom-class',
              },
              {
                rating: 5,
                review:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                name: 'Julia Smith',
                designation: 'Co-founder at Company',
                className: 'custom-class',
              },
            ]}
          />
        </div>
      </div>
      <div className="demo">
        <Heading title={'Portfolio Cards List - Grid'} />
        <div className="section">
          <CardList
            title="Portfolio Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            type="portfolio"
            data={[
              {
                title:
                  'Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['React', 'Node', 'Web App'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['React Native', 'Node', 'Mobile App'],
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['Angular', 'Node.js', 'Web App'],
                className: 'custom-class',
              },
            ]}
          />
        </div>
      </div>
      <div className="demo">
        <Heading title={'Product Cards List - Grid'} />
        <div className="section">
          <CardList
            title="Products Lorem ipsum dolor sit amet"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            type="product"
            data={[
              {
                title:
                  'Edocsify | Documentation Builder Tool | Automate Documentation',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                appIcon: appIcon,
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                appIcon: appIcon,
                className: 'custom-class',
              },
              {
                title: 'Custome Lorem ipsum dolor sit amet consectetur',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                media:
                  'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                link: '',
                tags: ['tag1', 'tag2', 'tag3'],
                appIcon: appIcon,
                className: 'custom-class',
              },
            ]}
          />
        </div>
      </div>
      <div className="demo">
        <Heading title={'Text Cards List - Grid | View All - false'} />
        <div className="section">
          <CardList
            title="Highlighted Features"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            type="text"
            viewAll={false}
            data={[
              {
                title: 'Lorem ipsum dolor',
                text:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                icon: 'bar-chart',
                className: 'custom-class',
              },
              {
                title: 'Lorem ipsum dolor',
                text:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                className: 'custom-class',
              },
              {
                title: 'Lorem ipsum dolor',
                text:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                icon: 'bell',
                className: 'custom-class',
              },
              {
                title: 'Lorem ipsum dolor',
                text:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                icon: 'bar-chart',
                className: 'custom-class',
              },
              {
                title: 'Lorem ipsum dolor',
                text:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                className: 'custom-class',
              },
              {
                title: 'Lorem ipsum dolor',
                text:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                icon: 'bell',
                className: 'custom-class',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

Main.displayName = 'UI-Component-Card-List-Demo';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
