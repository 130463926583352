import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ title }) => {
  return (
    <div className="ui-heading">
      <p className="text">{title}</p>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

Main.displayName = 'UI-Heading-Component';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
