import React from 'react';
import './styles.scss';
import { Form, Input, Button, Icon } from 'antd';
import CKEditor from '../../../containers/ckeditor';
const { Item } = Form;
var view = function () {
  const { form, hideModal, translate } = this.props;
  const { title, route, html } = this.validations;
  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form">
        <div className="form">
          <Item hasFeedback={true} label={translate('staticPage.title.label')}>
            {getFieldDecorator(
              'title',
              title
            )(
              <Input
                addonBefore={<Icon type="edit" />}
                maxLength={200}
                className="input"
                placeholder={translate('staticPage.title.placeholder')}
              />
            )}
          </Item>
          <Item hasFeedback={true} label={translate('staticPage.route.label')}>
            {getFieldDecorator(
              'route',
              route
            )(
              <Input
                addonBefore={<Icon type="edit" />}
                maxLength={200}
                className="input"
                placeholder={translate('staticPage.route.placeholder')}
              />
            )}
          </Item>
          <Item hasFeedback={true} label={translate('staticPage.html.label')}>
            {getFieldDecorator('html', html)(<CKEditor />)}
          </Item>
        </div>
        <div className="actions">
          <Button htmlType={'submit'} type="primary" className="btn green-btn">
            {translate('staticPage.save')}
          </Button>
          <Button
            htmlType={'button'}
            onClick={hideModal}
            className="btn red-btn-text"
          >
            {translate('staticPage.cancel')}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
