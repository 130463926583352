import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Button } from 'antd';
import SampleImage from 'images/sample.jpg';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of information with quote
 * className : -
 * quoteText : -
 * image : -
 * heading : -
 * title : -
 * text : -
 * buttonText : -
 */

const Main = ({
  quoteText,
  image,
  heading,
  title,
  text,
  buttonText,
  className,
}) => {
  return (
    <div className={'information quote ' + className}>
      <div className="section">
        <div className="container">
          <div className="content">
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} className="column">
                <div className="left">
                  <p>
                    <span className="quotes">" </span>
                    <span className="text">
                      {quoteText || 'Lorem ipsum dolor sit amet'}
                    </span>
                    <span className="quotes"> "</span>
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12} className="column">
                <div className="right">
                  <div className="graphic">
                    <img src={image || SampleImage} />
                  </div>
                  <div className="faq">
                    <p className="heading">{heading}</p>
                    <p className="title">{title}</p>
                    <p className="description">{text}</p>
                    <div className="action">
                      <Button className="primary-btn-text">{buttonText}</Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
