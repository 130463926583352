import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Link from '@arivaa-react/components/link';
import Navigation from '../navigation';
import { Icon } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ navigationData, logo, buttonText }) => {
  return (
    <div className="ui-bottomHeader">
      <div className="content container">
        <div className="section">
          <div className="left">
            <div className="logo-container">
              <Link className="logo">
                <img src={logo} alt={'logo'} />
              </Link>
            </div>
          </div>
          <div className="right">
            <div className="sample1-navigation">
              {<Navigation mode="horizontal" data={navigationData} />}
              <div className="button">
                <Link className="ant-btn primary-btn">{buttonText}</Link>
              </div>
            </div>

            <div className="sider-trigger">
              <Icon className="trigger" type="menu" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
