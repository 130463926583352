import { Component } from 'react';
import ComponentView from './view';

/**
 * @description Sample Component
 * @type Component
 * @author Inderdeep
 */
export default class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: props.value || '#fff',
    };
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  handleChange(color) {
    this.setState({ color: color.rgb });
    const { onChange } = this.props;
    if (onChange && onChange instanceof Function) {
      onChange(color.hex, color);
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

Main.displayName = 'Sample-Component';
