import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Button, Icon } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of pricing without background
 * className : -
 * data : - array element -[]
 * heading : -
 * subDescription : -
 * buttonText : -
 */

const Main = ({ className, data, buttonText, heading, subDescription }) => {
  return (
    <div className={'pricing without-background ' + className}>
      <div className="container">
        <div className="pricing-heading">
          <p className="title">{heading || 'lorem Ipsum'}</p>
          <p className="sub-title">
            {subDescription || 'Lorem ipsum dolor sit amet'}
          </p>
        </div>
        <div className="plans">
          <Row type="flex">
            {data
              ? data.map((item, index) => {
                  const { type, price, duration, properties } = item;
                  return (
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
                      {/* <PriceCard
                                                cardType={type}
                                                price={price}
                                                duration={duration}
                                                features={properties}/> */}

                      <div className="plan">
                        <div className="section">
                          <p className="type">{type || 'Regular'}</p>
                          <p className="amount">${price || '00'}</p>
                          <p className="duration">{duration || 'per month'}</p>
                        </div>
                        <ul className="properties">
                          {properties.map((item, index1) => {
                            const { property } = item;
                            return (
                              <li key={index1}>
                                <Icon type="check" />
                                {property}
                              </li>
                            );
                          })}
                        </ul>
                        <div className="action">
                          <Button className="primary-btn-text button ">
                            {buttonText || 'Consectetur adipiscing'}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  );
                })
              : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
