import React from 'react';
import ApiTrigger from '../../containers/api-trigger';
import DuplicateForm from '../../containers/duplicate-entity';
import { REFRESH_CACHE, REFRESH_INDEX } from 'client/constants/api';

export default {
  entityName: 'Configurations',
  entity: 'configuration',
  pagination: {
    type: 'server',
  },
  selection: false,
  //addAction: false,
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  //deleteAction: false,
  rowActions: [
    (props) => {
      const { record, getData } = props;
      const { key } = record;
      return (
        <DuplicateForm
          entityName="configuration"
          entityId={key}
          getData={getData}
        />
      );
    },
  ],
  columns: ({ sharedState: { translate } }) => {
    return [
      {
        dataIndex: 'key',
        title: translate('configuration.key.label'),
      },
      {
        title: translate('configuration.type.label'),
        dataIndex: 'type',
      },
      {
        title: translate('configuration.value.label'),
        dataIndex: 'value',
        render: (value, { type }) => {
          if (value === null || typeof value === 'undefined') {
            value = '';
          }
          return type === 'file' ? (
            <div>
              <div className="image">
                <img
                  src={value}
                  style={{
                    width: 200,
                    marginBottom: 10,
                  }}
                />
              </div>
              <a target="_blank" href={value} rel="noreferrer">
                Download
              </a>
            </div>
          ) : (
            <div>{value.toString()}</div>
          );
        },
      },
      {
        title: translate('configuration.description.label'),
        dataIndex: 'description',
      },
    ];
  },
  search: {
    filters: [
      {
        title: 'Key',
        type: 'input',
        key: 'key',
      },
    ],
    searchOnSubmit: true,
  },
  headerActions: [
    () => (
      <ApiTrigger
        url={'/refresh-config'}
        title="Refresh Config"
        icon="sync"
        successMessage="Refreshing Config initiated. Might take about a minute"
      />
    ),
    () => (
      <ApiTrigger
        url={REFRESH_INDEX}
        title="Refresh Indexes"
        icon="sync"
        successMessage="Refreshing indexes initiated. Might take about a minute"
      />
    ),
    () => (
      <ApiTrigger
        url={REFRESH_CACHE}
        title="Refresh Cache"
        icon="sync"
        successMessage="Refreshing Cache initiated. Might take about a minute"
      />
    ),
  ],
  formId: 'ConfigurationForm',
};
