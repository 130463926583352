import React, { Component } from 'react';
import './style.scss';
import ParityProgress from 'components/parityProgress';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { GET_ENTITY } from 'app-redux/actions';

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({ pageData }) => {
  pageData = pageData || [];
  return (
    <div className="parity page">
      <div className="parity-banner">
        <p className="title">Bitcoin Parity</p>
        {/* <p className="description">
          Tracing Bitcoin's market captalization versus other popular assets.
          Follow the Flippening
        </p> */}
      </div>
      <div className="container">
        <div className="content">
          <div className="list">
            {pageData.map((item, index) => {
              return <ParityProgress key={index} {...item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
Main.routeProps = {
  getPageData: async function (route, { params }) {
    try {
      let data = (
        await createAction(GET_ENTITY, {
          entityName: 'bitcoin-stats-v2/parity',
        }).payload
      ).data;
      return data;
    } catch (e) {
      console.error('Error whle getting page data', { e });
      e.page = '/error404';
      throw e;
    }
  },
};
Main.displayName = 'Parity';
//Pre process the container with Redux Plugins
export default Main;
