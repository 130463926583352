import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import LocationWithAddress from '../../location/locationWithAddress';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Location'} />
        <LocationWithAddress
          description="While we are a tele-health company we do maintain a brick and mortar office that you are welcome to come visit if your in the middle TN area. We’re just 15 minutes outside Nashville, TN."
          address="695 President Place, Suite 200, Smyrna, TN 37167."
          className="custom-location"
        />
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
