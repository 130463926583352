export var SolidBg1Data = [
  {
    label: 'Need Help',
    menus: [
      {
        menu: "faq's",
        routeKey: '',
      },
      {
        menu: 'support',
        routeKey: '',
      },
    ],
  },
  {
    label: 'More Links',
    menus: [
      {
        menu: 'terms and conditions',
        routeKey: '',
      },
      {
        menu: 'privacy policy',
        routeKey: '',
      },
    ],
  },
  {
    label: 'contact',
    menus: [
      {
        menu: 'help@nationaltrtclinics.com',
        routeKey: '',
      },
    ],
  },
];

export var SolidBg2Data = [
  {
    menus: [
      {
        menu: 'Portfolio',
      },
      {
        menu: 'services',
      },
    ],
  },
  {
    menus: [
      {
        menu: 'industry',
      },
      {
        menu: 'about',
      },
    ],
  },
  {
    menus: [
      {
        menu: 'join our team',
      },
      {
        menu: 'contact us',
      },
      {
        menu: 'our partners',
      },
    ],
  },
];

export var ImageBgData = [
  {
    className: '',
    label: 'services',
    menus: [
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
    ],
  },
  {
    className: '',
    label: 'Industry',
    menus: [
      {
        menu: 'Industry Name',
        routeKey: '',
      },
      {
        menu: 'Industry Name',
        routeKey: '',
      },
      {
        menu: 'Industry Name',
        routeKey: '',
      },
      {
        menu: 'Industry Name',
        routeKey: '',
      },
    ],
  },
  {
    className: 'address',
    label: 'North America',
    email: 'admin@laxaar.com',
    menus: [
      {
        menu: 'Vancouver,Canada',
      },
      {
        menu: 'CA +91-9988123456',
      },
    ],
  },
];

export var SocialIconData = [
  {
    label: 'Useful Info',
    menus: [
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
    ],
  },
  {
    label: 'More Links',
    menus: [
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
    ],
  },
  {
    label: 'Industry',
    menus: [
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
      {
        menu: 'Service Name',
        routeKey: '',
      },
    ],
  },
];

export var NewsletterData = [
  {
    className: 'left',
    menus: [
      {
        menu: 'portfolio',
      },
      {
        menu: 'services',
      },
      {
        menu: 'industry',
      },
      {
        menu: 'about',
      },
    ],
  },
  {
    className: 'right',
    menus: [
      {
        menu: 'join our team',
      },
      {
        menu: 'contact us',
      },
      {
        menu: 'our partners',
      },
    ],
  },
];
