import React from 'react';
import './styles.scss';
import { Form, Button, Input, Icon, Tooltip, Select, InputNumber } from 'antd';
const { Option } = Select;
const { TextArea } = Input;
const { Item } = Form;
/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { partsIndex } = this.state;
  const {
    hideModal,
    form,
    getRules,
    data,
    translate,
    title,
    subtitle,
    formTitle,
  } = this.props;
  let { fields } = this.props;
  if (fields instanceof Function) {
    fields = fields(translate);
  }
  const { getFieldDecorator } = form;
  return (
    <div className="meta-tags-form">
      <Form onSubmit={this.handleSubmit}>
        <div className="new-form seo-form">
          <div className="form">
            <div className="form-section">
              <p className="section-label">{title}</p>
              <div className="content">
                <div className="crud">
                  <div className="header">
                    <p></p>
                    <Button className="green-btn" onClick={this.addPart}>
                      <Icon type="plus" />
                      {translate('seo.add.new')}
                    </Button>
                  </div>
                  <div className="crud-content">
                    {partsIndex.map((num, index) => {
                      return (
                        <div key={num} className="inline">
                          {fields.map((field, index2) => {
                            const {
                              type,
                              key,
                              label,
                              placeholder,
                              options,
                            } = field;
                            const rules = getRules(key, num, translate);
                            const initialValue =
                              data && data[num] && data[num][key];
                            let Component = (
                              <TextArea placeholder={placeholder} />
                            );
                            if (type === 'select') {
                              Component = (
                                <Select placeholder={placeholder}>
                                  {(options || []).map((item, index3) => {
                                    const { label, value } = item;
                                    return (
                                      <Option key={index3} value={value}>
                                        {label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              );
                            }
                            if (type === 'number') {
                              Component = (
                                <InputNumber placeholder={placeholder} />
                              );
                            }
                            return (
                              <Item
                                key={index2}
                                hasFeedback={true}
                                label={label}
                              >
                                {getFieldDecorator('data[' + num + '].' + key, {
                                  rules,
                                  ...(initialValue && { initialValue }),
                                })(Component)}
                              </Item>
                            );
                          })}
                          <Tooltip title={translate('seo.delete')}>
                            <Button
                              className="red-btn-text delete-btn"
                              onClick={() => {
                                this.removePart(index);
                              }}
                            >
                              <Icon type="delete" />
                            </Button>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="actions">
              <Button
                htmlType={'submit'}
                type="primary"
                className="btn green-btn"
              >
                {translate('seo.save')}
              </Button>
              <Button
                htmlType={'button'}
                onClick={hideModal}
                className="btn red-btn-text"
              >
                {translate('seo.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default view;
