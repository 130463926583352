import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import {
  BlogCardData,
  UserCardData,
  PortfolioCardData,
} from '../../config/cardList';
import BlogCardList from '../../cardList/blogCardList';
import UserCardList from '../../cardList/userCardList';
import PortfolioCardList from '../../cardList/portfolioCardList';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Blog Card List'} />
        <div className="section">
          <BlogCardList className="custom-blog-card-list" data={BlogCardData} />
        </div>
      </div>

      <div className="demo">
        <Heading title={'User Card List'} />
        <div className="section">
          <UserCardList className="custom-user-card-list" data={UserCardData} />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Portfolio Card List'} />
        <div className="section">
          <PortfolioCardList
            className="custom-portfolio-card-list"
            data={PortfolioCardData}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

Main.displayName = 'UI-Component-Card-List-Demo';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
