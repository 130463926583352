/*
 Bootstrap redux
 */
import { createStore } from '@arivaa-react/redux';
import middleware from './middleware';
import { createBrowserHistory, createMemoryHistory } from 'history';
import reducers from './reducers';
import {
  configureLocalization,
  localeReducer,
  initialState as localizeInitialState,
} from './third-party/localization';
import Translations from '../localization';
import { EventEmitter } from 'fbemitter';
import { isSSR } from '@arivaa-react/helpers/web';
import actions from './actions';
import initialState from './initial-state';

const history = isSSR() ? createMemoryHistory() : createBrowserHistory();

/**
 * Create Third party reducers
 * @type {{locale, routing: *}}
 */
const thirdPartyReducers = {
  locale: localeReducer,
};

/**
 * Get Third Party Plugins initial state while server side rendering e.g
 * localization
 * @param req
 * @returns {{locale: {languages, translations}}}
 */
const getThirdPartyInitialState = () => {
  return {
    //Localize Initial State is only need for the case of Server Side Rendering
    locale: localizeInitialState(),
  };
};

/**
 * Create Redux Store = initialPreloadedState might be computed on server side
 * In that case its concatenated as a argument
 * @param initialPreloadedState
 * @returns {*}
 */
const store = function (initialPreloadedState, serverConfig) {
  /**
   * This method is used on server side also so handling for both is done
   * Third party initial state is only need for server side rendering
   */
  const { req } = serverConfig || {};
  //Check for server side rendering
  let output;
  output = createStore({
    reducers: {
      ...reducers,
      ...thirdPartyReducers,
    },
    middleware,
    initialState: {
      ...initialPreloadedState,
      ...initialState(),
      emitter: new EventEmitter(),
      ...getThirdPartyInitialState(req),
    },
    configureThirdPartyActions: (dispatch) => {
      configureLocalization(dispatch, Translations);
    },
    actions,
  });
  return output;
};

export { history, initialState };
export default store;
