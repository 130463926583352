import React, { Component } from 'react';
import ComponentView from './view';
import { Form, message } from 'antd';
import { createAction } from '@arivaa-react/redux';
import preProcess from 'containers/preprocess';
import { UPDATE_ENTITY } from 'app-redux/actions';
const { create } = Form;
const spinningSelector = '.meta-tags-form';
/**
 * @description Sample Component
 * @type Component
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      partsIndex: [0],
    };
    this.removePart = this.removePart.bind(this);
    this.addPart = this.addPart.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  addPart() {
    const { partsIndex } = this.state;
    const lastInteger = Math.max.apply(Math, partsIndex);
    this.setState({
      partsIndex: [...partsIndex, lastInteger + 1],
    });
  }

  removePart(index) {
    let { partsIndex } = this.state;
    partsIndex.splice(index, 1);
    this.setState({
      partsIndex,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      form,
      hideModal,
      getTableData,
      getData,
      updateRecord,
      translate,
    } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      const { data } = values;
      window.startSpinning(spinningSelector);
      try {
        const { error, payload } = await updateRecord(
          (data || []).filter((item) => {
            return !!item;
          })
        );
        if (error) {
          throw payload.response;
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
        if (getData instanceof Function) {
          getData();
        }
      } catch (e) {
        message.error(translate('seo.error'));
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {
    const { data } = this.props;
    if (data && data.length) {
      this.setState({
        partsIndex: Array(data.length)
          .fill()
          .map((x, i) => i),
      });
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

Main.displayName = 'Sample-Component';
/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch, ownProps) => {
  const { entityId, entityName, fieldName } = ownProps;
  return {
    updateRecord: (data) => {
      return dispatch(
        createAction(UPDATE_ENTITY, {
          entityName,
          entityId,
          [fieldName]: data || [],
        })
      );
    },
  };
};

Main.displayName = 'SEO-Form';
//Pre process the container with Redux Plugins
export default preProcess(create()(Main), {
  connect: [null, bindAction],
  localize: true,
});
