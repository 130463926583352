import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Icon, Button, Input, Form } from 'antd';
const { TextArea } = Input;
const { Item } = Form;

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of with querry and information contact component
 * className : -
 * buttonText : -
 * address : -
 * email : -
 * phoneNumber1 : -
 * phneNumber2 : -
 */

const Main = ({
  className,
  buttonText,
  address,
  email,
  phoneNumber1,
  phoneNumber2,
}) => {
  return (
    <div className={'contact with-query-and-information ' + className}>
      <div className="container">
        <div className="form">
          <Form>
            <Row type="flex">
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <div className="left">
                  <Row>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      className="margin"
                    >
                      <Item>
                        <Input type="text" placeholder="Name" />
                      </Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      className="margin"
                    >
                      <Item>
                        <Input type="text" placeholder="Email" />
                      </Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      className="margin"
                    >
                      <Item>
                        <Input type="text" placeholder="Phone Number" />
                      </Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={12}
                      xl={12}
                      className="margin"
                    >
                      <Item>
                        <Input type="text" placeholder="Fax" />
                      </Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      className="margin"
                    >
                      <Item>
                        <TextArea placeholder="Message" className="message" />
                      </Item>
                    </Col>
                  </Row>
                  <div className="submit">
                    <Button htmlType="submit" className="primary-btn">
                      {buttonText || 'submit'}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <div className="right">
                  <p className="title">Contact Information</p>
                  <ul>
                    <li className="detail">
                      <Icon type="environment" />
                      {address || '10 Downing Street , California , USA '}
                    </li>
                    <li className="detail">
                      <Icon type="mail" />
                      {email || 'admin@admin.com'}
                    </li>
                    <li className="detail">
                      <Icon type="phone" />
                      {phoneNumber1 || '+91-876543210'}
                    </li>
                    <li className="detail">
                      <Icon type="mobile" />
                      {phoneNumber2 || '+91-887654321'}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
