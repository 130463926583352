import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';

import { Icon } from 'antd';
import Link from '@arivaa-react/components/link';
import { ellipsis } from '@arivaa-react/helpers/web';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of blog cards component
 * image : -
 * tags : - array element - []
 * title : -
 * description : -
 */

const Main = ({ data, index }) => {
  const { className, experience, location, title, link } = data[index];

  return (
    <Link routeKey={link} className={'card job ' + className}>
      <div className="card-details">
        <div className="title">
          {ellipsis(
            title || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            10
          )}
        </div>
        <div className="description">
          <p className="experience">
            <Icon type="book" /> {experience || '0'} years of experience
          </p>
          <p className="location">
            <Icon type="environment" /> {location || 'India'}
          </p>
        </div>
      </div>
    </Link>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
