import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from '../preprocess';
import { createAction } from '@arivaa-react/redux';
import {
  goToRoute,
  addOnRouteChange,
  getCurrentRoute,
} from '@arivaa-react/react-router5';
import axios from 'axios';
import { scrollToTopWindow } from '@arivaa-react/helpers/web';
import {
  setAuthorizationHeader,
  clearAuthorizationHeader,
  getAuthData,
} from '../../utils/security';
import { message } from 'antd';
import { isUserAllowed } from '../with-security';
import {
  CREATE_ENTITY,
  GET_CONFIGS,
  GET_DASHBOARD,
  GET_PROFILE,
  LOGOUT,
  REFRESH_TOKEN,
  REGISTER,
  RESET,
  SOCIAL_LOGIN,
  UI_SET_PROPERTY,
} from 'app-redux/actions';

const spinningSelector = 'body';

/**
 * @description Main Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    /**
     * Apply 401 Handler
     */
    this.apply401Handler();
    addOnRouteChange(this.onRouteChange.bind(this));
    // // Start Spinner only in case of checkLogin
    // if (typeof document != 'undefined') {
    //   window.startSpinning(spinningSelector);
    // }
    this.state = {
      drawer: false,
      pageReady: false,
    };
    this.toggle = this.toggle.bind(this);
    this.getConfigurations = this.getConfigurations.bind(this);
    this.configureRequestInterceptor();
  }

  /**
   * Toggle DropDown
   */
  toggle() {
    const { setUiProperty, ui } = this.props;
    setUiProperty({
      name: 'drawer',
      value: false,
    });
  }

  toggleDrawer() {
    this.setState({
      drawer: !this.state.drawer,
    });
  }

  /**
   * On Main Component Mount
   */
  async componentDidMount() {
    const { emitter } = this.props;
    emitter.addListener('AUTHENTICATED', this.onAuthenticate.bind(this));
    this.configureAntd();
    // this.getConfigurations();
    this.autoLogin();
    setTimeout(() => {
      if (
        window.changeGoogleTranslateLanguage instanceof Function &&
        window.app.defaultLanguage !== 'en'
      ) {
        window.changeGoogleTranslateLanguage(window.app.defaultLanguage);
      }
    });
  }

  /**
   * get Configurations
   * @returns {Promise<void>}
   */
  async getConfigurations() {
    const { getConfigs } = this.props;
    try {
      const { error, payload } = await getConfigs();
      if (error) {
        throw payload.response.data;
      }
    } catch (e) {
      console.error('Error while getting configuration from server', e);
      // Redirect to Website Down Error Page
    }
  }

  /**
   * On Route Change
   */
  onRouteChange() {
    this.checkAccess();
    this.scrollToTop();
    this.props.reset();
  }

  /**
   * Check access to route
   */
  checkAccess() {
    //
    const currentRoute = getCurrentRoute();
    const { user, auth } = this.props;
    const { providerData } = auth || {};
    const [provider] = providerData || [];
    const { providerId } = provider || {};
    if (
      auth &&
      auth.uid &&
      providerId === 'password' &&
      !auth.emailVerified &&
      currentRoute.routeKey !== 'emailNotConfirm'
    ) {
      goToRoute('emailNotConfirm');
      return false;
    }
    if (currentRoute.role && auth.uid) {
      if (
        !((user.role || '').toLowerCase() === currentRoute.role.toLowerCase())
      ) {
        goToRoute('home');
        message.error('You are not authorized to access this page');
        return false;
      }
    }
    if (!isUserAllowed(currentRoute, user)) {
      goToRoute('home');
      message.error('You are not authorized to access this page');
      return false;
    }
    return true;
  }

  /**
   * On Authenticate Handler
   */
  async onAuthenticate(payload) {
    payload = payload || {};
    const { status, type } = payload;
    const currentRoute = getCurrentRoute();
    if (status) {
      const { auth, translate, emitter } = this.props;

      try {
        await this.getProfile();
        emitter.emit('USER_FETCHED');
        if (!this.checkAccess()) {
          window.stopSpinning(spinningSelector);
          return;
        }
      } catch (e) {
        console.error('Error while getting user profile', { e });
        message.error(translate('common.server.error.unexpected'));
        goToRoute('login');
      }

      /**
       * check that email is confirm if not user does not get logged in
       */
      const { emailVerified } = auth;
      if (!emailVerified) {
        message.warn(translate('signUp.accountVerification.warning'));
        goToRoute('emailNotConfirm');
      } else {
        /**
         * if the route is guest only then redirect to home
         */
        if (currentRoute.guestOnly && auth.uid) {
          goToRoute('home');
        }
        if (currentRoute.path == '/') {
          goToRoute('home');
        }
      }
    } else {
      if (!currentRoute.public) {
        goToRoute('login');
      }
    }
    this.setState({
      pageReady: true,
    });
    window.stopSpinning(spinningSelector);
  }

  /**
   * Get Profile
   */
  async getProfile() {
    const { getProfile, getDashboard } = this.props;
    try {
      await getProfile();
      //await getDashboard();
    } catch (e) {
      throw e;
    }
  }

  /**
   * Configure Antd
   */
  configureAntd() {}

  /**
   * Auto login a user if already authenticated
   */
  async autoLogin() {
    const { props } = this;
    const { checkLogin, auth } = props;
    const currentRoute = getCurrentRoute();
    let isLoggedIn = false;
    if (auth) {
      if (
        !currentRoute.public ||
        (currentRoute.public && currentRoute.guestOnly)
      ) {
        window.startSpinning(spinningSelector);
      }
      try {
        await checkLogin({
          refreshToken: auth.refreshToken,
        });
        isLoggedIn = true;
      } catch (e) {
        console.debug('User not logged in ', { e });
        isLoggedIn = false;
      }
    }
    if (isLoggedIn) {
      props.emitter.emit('AUTHENTICATED', {
        status: true,
        type: 'CHECK_LOGIN',
      });
    } else {
      props.emitter.emit('AUTHENTICATED', {
        status: false,
        type: 'CHECK_LOGIN',
      });
    }
  }

  /**
   * Logout Handler
   * @param redirect
   */
  logout(redirect) {
    const { logout } = this.props;
    /**
     * Refresh whole page in order to reset the whole state
     */
    logout();
    /**
     * Clear Authorization Header
     */
    clearAuthorizationHeader();
    redirect
      ? goToRoute('login', {
          forceRefresh: true,
        })
      : null;
  }

  /**
   * 401 Handler in case a ajax request results in 401 Error
   */
  apply401Handler() {
    // Add a response interceptor to axios to handle session expiry
    axios.interceptors.response.use(
      (response) => {
        if (response.status === 401) {
          this.logout(true);
        }
        // Do something with response data
        return response;
      },
      (error) => {
        /**
         * If 401 error in ajax request logout
         */
        if (error.response && error.response.status == 401) {
          this.logout(true);
          //Show a modal here for the user to tell that his sesssion has expired.
        }
        return Promise.reject(error);
      }
    );
  }
  /**
   * Axios request interceptor for refreshing tokens
   */
  configureRequestInterceptor() {
    const { refreshToken } = this.props;
    axios.interceptors.request.use(
      async (config) => {
        if (
          config.headers.common.authorization &&
          config.url.startsWith(window.app.server)
        ) {
          try {
            const token = this.props.auth && this.props.auth.accessToken;
            if (token) {
              config.headers.common.authorization = token;
              refreshToken(token);
              // Optional : we can update user reducer if we require. Not done now
            }
          } catch (e) {
            console.log({ e });
          }
        }
        return config;
      },
      (error) => () => {
        return Promise.reject(error);
      }
    );
  }
  scrollToTop() {
    setTimeout(() => {
      scrollToTopWindow();
    }, 200);
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    logout: () => {
      return dispatch(createAction(LOGOUT));
    },
    reset: () => {
      return dispatch(createAction(RESET));
    },
    checkLogin: (data) => {
      const action = createAction(CREATE_ENTITY, {
        entityName: 'authenticate/refresh',
        fetchUserInfo: true,
        ...data,
      });
      action.type = action.type + '_auth_refresh';
      return dispatch(action);
    },
    getConfigs: () => {
      return dispatch(createAction(GET_CONFIGS));
    },
    getProfile: (data) => {
      return dispatch(createAction(GET_PROFILE, data));
    },
    getDashboard: () => {
      return dispatch(createAction(GET_DASHBOARD));
    },
    socialLogin: () => {
      return dispatch(createAction(SOCIAL_LOGIN));
    },
    registerSocialUser: (data) => {
      return dispatch(
        createAction(REGISTER, {
          ...data,
          type: 'social',
        })
      );
    },
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
    refreshToken: (data) => {
      return dispatch(createAction(REFRESH_TOKEN, data));
    },
  };
};
/**
 * Bind State to props
 * @returns {{Object}}
 * @param state
 */
const mapStateToProps = (state) => {
  const auth = state.auth;

  return {
    auth: auth,
    emitter: state.emitter,
    user: state.user,
    horizontalMenu: state.ui.horizontalMenu,
    ui: state.ui,
  };
};
Main.displayName = 'Main-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
