export function getName(profile) {
  return (profile.firstName || '') + ' ' + (profile.lastName || '');
}

/**
 * Get Profile Picture from firebase
 * auth object
 * @param auth
 * @param config
 * @returns {string}
 */
export function getProfilePicture(auth, config) {
  let url = 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png';
  config = config || {};
  if (auth.photoURL) {
    url = auth.photoURL;
    if (url.indexOf('graph.facebook') != -1) {
      url = url + `?height=${config.height || 300}`;
    }
  }
  return url;
}

/**
 * Get Email from firebase since in case of
 * Social Providers, Email is not present as primary email
 * It needs to be fetched from providerData
 * auth object
 * @param auth
 * @param config
 * @returns {string}
 */
export function getEmail(auth, config) {
  let email = auth.email || null;
  config = config || {};
  if (!email && auth.providerData) {
    for (let provider of auth.providerData) {
      if (provider.email) {
        email = provider.email;
        break;
      }
    }
  }
  return email;
}
