import React from 'react';
import { connect } from 'react-redux';

export function isUserAllowed(featureConfig, userConfig) {
  featureConfig = featureConfig || {};
  userConfig = userConfig || {};
  let {
    permissions: allowedPermissions,
    roles: allowedRoles,
    isAllowed,
    extraSecurityCheck,
  } = featureConfig;
  const { permissions, role } = userConfig;
  if (isAllowed instanceof Function) {
    return isAllowed(allowedPermissions, allowedRoles, permissions, role);
  }
  let allowed = true;
  if (allowedPermissions instanceof Function) {
    allowedPermissions = allowedPermissions(permissions, role);
  }
  if (allowedRoles instanceof Function) {
    allowedRoles = allowedRoles(permissions, role);
  }
  if (role && role.toLowerCase() !== 'admin') {
    if (typeof allowedRoles === 'string') {
      allowedRoles = [allowedRoles];
    }
    if (allowedRoles && allowedRoles.length > 0) {
      allowed = allowed && allowedRoles.indexOf(role) !== -1;
    }
    if (allowedPermissions && allowedPermissions.length > 0) {
      allowed =
        allowed &&
        permissions &&
        permissions.length > 0 &&
        allowedPermissions.findIndex((key) => {
          return (
            permissions.findIndex((perm) => {
              return key.toUpperCase() === perm.toUpperCase();
            }) !== -1
          );
        }) !== -1;
    }
  }
  if (extraSecurityCheck && extraSecurityCheck instanceof Function) {
    allowed =
      allowed &&
      extraSecurityCheck(allowedPermissions, allowedRoles, permissions, role);
  }
  return allowed;
}

export default (Main, config) => {
  let {
    permissions: allowedPermissions,
    role: allowedRoles,
    isAllowed,
    DefaultComponent,
  } = config;
  allowedPermissions = allowedPermissions || [];
  allowedRoles = allowedRoles || [];
  if (typeof allowedRoles === 'string') {
    allowedRoles = [allowedRoles];
  }
  if (typeof allowedPermissions === 'string') {
    allowedPermissions = [allowedPermissions];
  }
  let App = (props) => {
    const { permissions, role } = props;
    if (
      !isUserAllowed(
        {
          permissions: allowedPermissions,
          roles: allowedRoles,
          isAllowed,
        },
        {
          permissions,
          role,
        }
      )
    ) {
      if (DefaultComponent) {
        return <DefaultComponent {...props} />;
      }
      return null;
    }
    return <Main {...props} />;
  };
  const mapStateToProps = ({ user }) => {
    return {
      permissions: (user && user.permissions) || [],
      role: user && user.role,
    };
  };
  App = connect(mapStateToProps)(App);
  return App;
};
