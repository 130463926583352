import React from 'react';
import './styles.scss';
import { Layout } from 'antd';
import Sider from 'containers/sider';
import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';
import Link from '@arivaa-react/components/link';

import { renderRoutes } from 'react-router-config';
var view = function () {
  const { children, horizontalMenu, route, drawer } = this.props;
  return (
    <div className="home">
      <Layout style={{ minHeight: '100vh' }}>
        {drawer && mobileAndTabletcheck() ? (
          <Link className="overlay" onClick={this.closeDrawer.bind(this)} />
        ) : null}
        {!horizontalMenu ? <Sider route={route} /> : null}
        <Layout className="home-layout">{renderRoutes(route.routes)}</Layout>
      </Layout>
    </div>
  );
};
export default view;
