import React from 'react';
import CKEditor from '../../containers/ckeditor';
import TagInput from '@arivaa-react/components/tagInput';
import withApiData from '../../containers/with-api-data';
import { Select } from 'antd';
export default {
  entity: 'blog-post',
  entityName: 'Blog Posts',

  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  pagination: {
    type: 'server',
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: 'Title',
        key: 'title',
        type: 'input',
      },
      {
        title: 'Short Description',
        key: 'shortDescription',
        type: 'input',
      },
    ],
  },
  columns: [
    {
      title: 'Title',
      dataIndex: 'title',
    },
  ],
  form: {
    skipTranslate: true,
    elements: [
      {
        type: 'custom',
        name: 'category',
        label: 'Category',
        Component: withApiData(
          ({ data, ...props }) => (
            <Select className="select" {...props}>
              {(data || []).map(({ key, title }, index) => {
                return (
                  <Select.Option key={index} value={key}>
                    {title}
                  </Select.Option>
                );
              })}
            </Select>
          ),
          {
            requestConfig: {
              entityName: 'blog-category',
              all: true,
              sort: 'createdAt',
              sortType: 'desc',
            },
          }
        ),
        required: true,
      },
      {
        type: 'file',
        name: 'image',
        label: 'Image',
        required: true,
        fileCode: 'mediaFile',
      },
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        maxLength: 200,
      },
      {
        type: 'text',
        name: 'shortDescription',
        label: 'Short Description',
        required: true,
        maxLength: 500,
      },
      {
        type: 'custom',
        Component: CKEditor,
        name: 'description',
        label: 'Content',
        required: true,
      },
      {
        type: 'custom',
        Component: TagInput,
        name: 'tags',
        label: 'Tags',
        required: false,
      },
    ],
  },
};
