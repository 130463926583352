import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import MediaInformation from '../../information/mediaInformation';
import QuoteInformation from '../../information/quoteInformation';
import DoubleHeadingInformation from '../../information/doubleHeadingInformation';
import MultipleImageInformation from '../../information/multipleImageInformation';
import Video from 'images/video.mov';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Media Information : Video'} />
        <MediaInformation
          title="Lorem ipsum dolor sit amet consectetur"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          buttonText="Book Appointment"
          video={Video}
          className="custom-video-information"
        />
      </div>
      <div className="demo">
        <Heading title={'Media Information : Video | Reverse'} />
        <MediaInformation
          title="Lorem ipsum dolor sit amet consectetur"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          buttonText="Book Appointment"
          video={Video}
          reverse
          className="custom-video-information"
        />
      </div>
      <div className="demo">
        <Heading title={'Media Information : Video | Rectangle'} />
        <MediaInformation
          title="Lorem ipsum dolor sit amet consectetur"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          buttonText="Book Appointment"
          video={Video}
          mediaShape="rectangle"
          className="custom-video-information"
        />
      </div>
      <div className="demo">
        <Heading title={'Media Information : Video | All Options'} />
        <MediaInformation
          title="Offering expert testosterone replacement therapy (TRT) care through the convenience of telehealth"
          subHeading="Unbeatable Pricing and Expertise: 185$ per Month"
          condition="All inclusive: covers medication, labs, and consultations."
          description="Specialist care brought to your doorstep. Privacy, comfort, and convenience for one low monthly rate."
          buttonText="Let's get started"
          video={Video}
          amount="125"
          className="custom-video-information"
        />
      </div>
      <div className="demo">
        <Heading title={'Media Information : Image'} />
        <MediaInformation
          title="Lorem ipsum dolor sit amet consectetur"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          buttonText="Book Appointment"
          image={
            'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg'
          }
          className="custom-image-information"
        />
      </div>
      <div className="demo">
        <Heading title={'Media Information : Image | Reverse'} />
        <MediaInformation
          title="Lorem ipsum dolor sit amet consectetur"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          buttonText="Book Appointment"
          image={
            'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg'
          }
          reverse
          className="custom-image-information"
        />
      </div>
      <div className="demo">
        <Heading title={'Media Information : Image | Rectangle'} />
        <MediaInformation
          title="Lorem ipsum dolor sit amet consectetur"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          buttonText="Book Appointment"
          image={
            'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg'
          }
          mediaShape="rectangle"
          className="custom-image-information"
        />
      </div>
      <div className="demo">
        <Heading title={'Information with quote'} />
        <div className="section">
          <QuoteInformation
            quoteText="We are happy you dealt with our home reversion plan clearly and professionally."
            image="https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg"
            heading="Heading"
            title="Brief informative title"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget metus ut nisl e vitae orci. Curabitur sit amet facilisis tellus. Nulla consectetur."
            buttonText="find out more"
            className="custom-quote-information"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Information with double heading'} />
        <div className="section">
          <DoubleHeadingInformation
            sectionHeading="Section Heading"
            sectionTitle="Ghostly white figures in coveralls and helmets are soflty dancing of the cosmic"
            description1="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            description2="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            image="https://cdn.pixabay.com/photo/2019/04/06/06/44/astronaut-4106766_960_720.jpg"
            className="custom-heading-information"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Information with multiple pictures'} />
        <div className="section">
          <MultipleImageInformation
            sectionNumber="01"
            smallTitle="team"
            bigTitle={['meet our dedicated', 'and creative Team']}
            media={[
              'https://cdn.pixabay.com/photo/2016/01/19/17/51/girls-1149935_960_720.jpg',
              'https://cdn.pixabay.com/photo/2016/11/29/05/46/adult-1867618_960_720.jpg',
              'https://cdn.pixabay.com/photo/2016/09/24/03/20/people-1690965_960_720.jpg',
              'https://cdn.pixabay.com/photo/2016/11/21/12/42/beard-1845166_960_720.jpg',
            ]}
            subHeading="Dedication over all"
            whiteText="Culture billions upon billions a still more glorious dawn awaits extraplanetary across the centuries preserve and cherish that pale blue dot globular star cluster dispassionate."
            description={[
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            ]}
            className="custom-multiple-image-information"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
