import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Icon } from 'antd';
import SampleImage from 'images/sample.jpg';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of feature with Single Picture
 * className : -
 * data : - array element -[]
 * heading : -
 * subDescription : -
 */

const Main = ({ data, className, heading, subDescription }) => {
  return (
    <div className={'feature single-picture ' + className}>
      <div className="section">
        <div className="container">
          <div className="content">
            <div className="section-heading">
              <p className="title">{heading || 'Awesome Features'}</p>
              <p className="line" />
              <p className="sub-description">
                {subDescription ||
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget metus ut nisl eleifend auctor ut vitae orci.'}
              </p>
            </div>
            <div className="details">
              <Row>
                {data
                  ? data.map((item, index1) => {
                      const { column, data } = item;
                      return (
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={8}
                          xl={8}
                          key={index1}
                          className={column}
                        >
                          {column === 'middle' ? (
                            <div className="image">
                              <img src={data || SampleImage} />
                            </div>
                          ) : (
                            <div className="detail">
                              {data.map((item, index2) => {
                                const { icon, name, about } = item;
                                return (
                                  <div className="feature" key={index2}>
                                    <div className="about">
                                      <p className="feature-name">
                                        {name || 'Lorem Ipsum'}
                                      </p>
                                      <p className="description">
                                        {about ||
                                          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
                                      </p>
                                    </div>
                                    <div className="icon">
                                      <Icon
                                        type={icon || 'heart'}
                                        theme="twoTone"
                                        twoToneColor="#f34661"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
