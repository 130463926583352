import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { goToRoute, getRoute } from '@arivaa-react/react-router5';
import MenuKeys from 'containers/sider/config';
import { isUserAllowed } from 'containers/with-security';
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    const { permissions, role } = this.props;
    let menus = MenuKeys.filter((item) => {
      const routeProps = (getRoute(item.key) || {}).routeProps || {};
      return (
        isUserAllowed(
          {
            ...routeProps,
          },
          {
            permissions,
            role,
          }
        ) &&
        (item.isAllowed instanceof Function
          ? !!item.isAllowed(permissions, role)
          : true)
      );
    });
    if (menus.length > 0) {
      goToRoute(menus[0].key);
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ user }) => {
  const { permissions, role } = user || {};
  return {
    permissions,
    role,
  };
};
Main.displayName = 'Dashboard';
Main.notRoute = true;
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
