import React from 'react';
export default {
  entityName: 'System',
  entity: 'configuration',
  pagination: {
    type: 'server',
  },
  getRequestParams: () => {
    return {
      search: `key;GLOBAL_WEALTH,GOLD_SUPPLY,SILVER_SUPPLY,M2_MONEY,US_COMMERCIAL_BANKS,FED_BALANCE_SHEET,HOME_SECTION_1,HOME_SECTION_2,HOME_SECTION_3,ABOUT_SECTION_1,ABOUT_SECTION_2;in`,
    };
  },
  selection: false,
  deleteAction: false,
  addAction: false,
  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  columns: ({ sharedState: { translate } }) => {
    return [
      {
        dataIndex: 'description',
        title: 'Title',
      },
      {
        title: translate('configuration.value.label'),
        dataIndex: 'value',
        render: (value, { type }) => {
          if (value === null || typeof value === 'undefined') {
            value = '';
          }
          return type === 'html' ? (
            <strong> Click Edit to view full content</strong>
          ) : (
            value.toString()
          );
        },
      },
    ];
  },
  search: false,
  formId: 'SystemForm',
};
