export function toCurrencyFormat(price) {
  price = (price || '').toString();
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function toDecimal(price) {
  price = parseFloat(price);
  if (isNaN(price)) {
    return 0.0;
  }
  return price.toFixed ? price.toFixed(2) : price;
}

export function abbreviateCurrency(value, decimals) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b', 't'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(decimals || 3);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}
