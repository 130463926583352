import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import {
  WithoutRating1Data,
  WithoutRating2Data,
  WithRatingData,
} from '../../config/testimonial.js';
import WithoutRating1 from '../../testimonials/withoutRating-1';
import WithoutRating2 from '../../testimonials/withoutRating-2';
import WithRating from '../../testimonials/withRating';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Testimonial without rating template 1'} />
        <div className="section">
          <WithoutRating1
            className="custom-withoutRating-1"
            data={WithoutRating1Data}
            title="What Clients Say"
            subTitle="Reviews from our happy clients"
            buttonText="Write a Review"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Testimonial without rating template 2'} />
        <div className="section">
          <WithoutRating2
            className="custom-withoutRating-2"
            data={WithoutRating2Data}
            title="People say the nicest things"
            buttonText="Get a Quote"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Testimonial with rating'} />
        <div className="section">
          <WithRating
            className="custom-withRating"
            data={WithRatingData}
            title="People say the nicest things"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
