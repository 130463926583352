import React, { Component } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import Form from '@arivaa-react/components/form';
import { Button, message } from 'antd';
import {
  getObjectsDiff,
  getObjectsDiffKeys,
} from '@arivaa-react/helpers/common';
import { createAction, getApiActionExecuter } from '@arivaa-react/redux';
import { CREATE_ENTITY, UPDATE_ENTITY } from 'app-redux/actions';
const spinningSelector = '.form';
/**
 * @description Crud Form Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({
  elements,
  translate,
  onError,
  preSubmit,
  postSubmit,
  createRecord,
  updateRecord,
  hideModal,
  getTableData,
  data,
  uploadFile,
  skipTranslate,
}) => {
  let fileElements = [];
  let commonProps = { translate };
  elements = (elements || []).map((element) => {
    element = { ...element };
    if (element.label && !skipTranslate) {
      element.label = translate(element.label);
    }
    element.options = {
      ...element.options,
      initialValue: data ? data[element.name] : undefined,
    };
    if (element.type === 'file') {
      if (element.maxFileCount) {
        element.options.rules = element.options.rules || [];
        element.options.rules.push({
          validator: (rule, value, callback) => {
            if (value instanceof Array && value.length > element.maxFileCount) {
              callback(
                element.label +
                  ': ' +
                  translate('common.file.error.maxCount') +
                  ' ' +
                  element.maxFileCount
              );
            } else {
              callback();
            }
          },
        });
      }
      fileElements.push(element);
    }
    return element;
  });
  const onSubmit = async (values) => {
    window.startSpinning(spinningSelector);
    try {
      let action;
      let fileUploadError = null;
      //Check for files to be uploaded
      if (fileElements.length > 0) {
        await Promise.all(
          fileElements.map(async ({ fileCode, name, label }) => {
            try {
              let data;
              if (values[name] instanceof File) {
                data = await getApiActionExecuter(uploadFile)({
                  file: values[name].dataUri,
                  type: values[name].type,
                  name: values[name].name,
                  fileCode,
                });
                values[name] = encodeURI(data.url);
              } else if (values[name] instanceof Array) {
                values[name] = await Promise.all(
                  values[name].map(async (file) => {
                    if (file instanceof File) {
                      return (
                        await getApiActionExecuter(uploadFile)({
                          file: file.dataUri,
                          type: file.type,
                          name: file.name,
                          fileCode,
                        })
                      ).url;
                    } else {
                      return file;
                    }
                  })
                );
              }
            } catch (e) {
              console.error('Error while uploading files ', {
                e,
                name,
                fileCode,
              });
              fileUploadError = {
                e,
                name,
                fileCode,
                label,
              };
              //throw e;
            }
          })
        );
        if (fileUploadError) {
          message.error('common.changes.save.error');
          return;
        }
      }

      if (data) {
        let updatedKeys = getObjectsDiffKeys(data, values);
        let updatedValues = {};
        updatedKeys.forEach((key) => {
          updatedValues[key] = values[key];
        });
        if (preSubmit instanceof Function) {
          // Pass true to indicate its a update operation
          preSubmit(updatedValues, true, commonProps);
        }
        action = await updateRecord({
          ...updatedValues,
          key: data.key,
        });
      } else {
        if (preSubmit instanceof Function) {
          preSubmit(values, commonProps);
        }
        action = await createRecord(values);
      }
      if (!action.error) {
        message.success(translate('common.changes.save.success'));
        hideModal instanceof Function && hideModal();
      } else {
        throw action.payload;
      }
      if (getTableData instanceof Function) {
        getTableData();
      }
      if (postSubmit instanceof Function) {
        postSubmit(action, commonProps);
      }
    } catch (err) {
      console.error({
        err,
      });
      if (onError instanceof Function) {
        onError(err, commonProps);
      } else {
        err = (err.response || {}).data || {};
        message.error(translate('common.changes.save.error'));
      }
    }
    window.stopSpinning(spinningSelector);
  };
  const actions = [
    <Button
      key="cancel"
      htmlType={'button'}
      onClick={hideModal}
      className="btn red-btn-text"
    >
      {translate('common.cancel')}
    </Button>,
  ];
  return (
    <Form
      elements={elements}
      translate={translate}
      actions={actions}
      submitText={translate('common.submit')}
      onSubmit={onSubmit}
      requiredMessage={translate('common.field.error.required')}
    />
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch, ownProps) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: ownProps.entity,
          ...data,
        })
      );
    },
    updateRecord: ({ entityId, ...data }) => {
      const key = data.key;
      delete data.key;
      return dispatch(
        createAction(UPDATE_ENTITY, {
          entityName: ownProps.entity,
          entityId: key,
          ...data,
        })
      );
    },
    uploadFile: ({ file, type, name, fileCode }) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'storage',
          file,
          type,
          name,
          fileCode,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Crud-Form-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
