import React from 'react';
import { Select, Switch } from 'antd';
import UpdateEntity from '../../containers/update-entity';
import stockSplit from './stock-split';
import uploadCsv from './upload-csv';
export default {
  entity: 'symbol',
  entityName: 'Symbols',

  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  pagination: {
    type: 'server',
  },
  deleteAction: false,
  selection: false,
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: 'Ticker',
        key: 'key',
        type: 'input',
      },
      {
        title: 'Type',
        key: 'type',
        type: 'select',
        options: [
          {
            label: 'Fiat',
            value: 'currency',
          },
          {
            label: 'Equity Markets',
            value: 'stock',
          },
          {
            label: 'Fred',
            value: 'fred',
          },
          {
            label: 'Commodities',
            value: 'commodity',
          },
        ].map((item, index) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        )),
      },
      ,
      /*{
        title: 'Sub Type',
        key: 'subType',
        type: 'select',
        options :  [{
          label : 'Currency',
          value : 'currency'
        }].map((item,index)=><Select.Option key = {item.value} value = {item.value}>{item.label}</Select.Option>)
      },*/ {
        title: 'Title',
        key: 'name',
        type: 'input',
      },
    ],
  },
  columns: ({ sharedState: { translate }, getTableData }) => [
    {
      title: 'Ticker',
      dataIndex: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Enabled',
      dataIndex: 'disabled',
      render: (value, record) => {
        return (
          <UpdateEntity
            entityName="symbol"
            entityIdPropName="key"
            record={record}
            getChildren={({ loading, updateEntity, record }) => {
              return (
                <Switch
                  loading={loading}
                  checked={!record.disabled}
                  onChange={async (checked) => {
                    await updateEntity({ disabled: !checked });
                    getTableData();
                  }}
                />
              );
            }}
          />
        );
      },
    },
  ],
  addAction: false,
  rowActions: [uploadCsv, stockSplit],
};
