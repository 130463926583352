import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleImage from 'images/sample.jpg';

import Link from '@arivaa-react/components/link';
import { ellipsis } from '@arivaa-react/helpers/web';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of blog cards component
 * image : -
 * tags : - array element - []
 * title : -
 * description : -
 */

const Main = ({ data, index }) => {
  const { className, media, tags, title, description, link } = data[index];

  return (
    <Link routeKey={link} className={'card blog ' + className}>
      <div className="image">
        <img src={media || SampleImage} />
      </div>
      <div className="card-details">
        <div className="tags">
          {tags
            ? tags.map((item, index) => {
                return (
                  <Link key={index} className="tag">
                    {item}
                  </Link>
                );
              })
            : null}
        </div>
        <div className="title">
          {ellipsis(
            title || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            10
          )}
        </div>
        <p className="description">
          {ellipsis(
            description ||
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ',
            30
          )}
        </p>
      </div>
    </Link>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
