import React from 'react';
import './styles.scss';
import Link from '@arivaa-react/components/link';

/**
 * @default values of blog cards component
 * image : -
 * tags : - array element - []
 * title : -
 * description : -
 */

const Main = ({ data, index }) => {
  const { className, image, tags, title, shortDescription, key } = data;
  return (
    <Link
      routeKey={'blogDetails'}
      routeParams={{
        key,
      }}
      className={'card blog ' + className}
    >
      <div className="image">
        <img src={image} />
      </div>
      <div className="card-details">
        <div className="tags">
          {tags
            ? tags.map((item, index) => {
                return (
                  <a key={index} className="tag">
                    {item}
                  </a>
                );
              })
            : null}
        </div>
        <div className="title">{title}</div>
        <p className="description">{shortDescription}</p>
      </div>
    </Link>
  );
};

Main.displayName = 'Blog Card';
//Pre process the container with Redux Plugins
export default Main;
