import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import PricingWithBackground from '../../pricing/pricingWithBackground';
import PricingWithoutBackground from '../../pricing/pricingWithoutBackground';
import {
  WithoutBackgroundData,
  WithBackgroundData,
} from '../../config/pricing.js';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Pricing Without Background'} />
        <div className="section">
          <PricingWithoutBackground
            className="custom-without-background"
            heading="Free Number with Every Plan"
            subDescription="Get started with a number capable of texting large groups and having 2-way conversations. It’s on us! Need a different solution? We’re happy to help."
            data={WithoutBackgroundData}
            buttonText="Select Plan"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Pricing With Background'} />
        <div className="section">
          <PricingWithBackground
            className="custom-with-background"
            heading="Find the right plan"
            subDescription="All options start with a 14-day free trial that includes unlimited texting and agents.No credit card required."
            image="https://cdn.pixabay.com/photo/2015/05/15/14/50/concert-768722_960_720.jpg"
            data={WithBackgroundData}
            buttonText="Try Us Free"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
