import React from 'react';
import satoshi from '../images/stocks/satoshi-white.png';
import { toCurrencyFormat, toDecimal } from './currency';
export const getPriceInBTC = (text, sats) => {
  if (sats) {
    text = Math.round(text * 100000000);
  } else {
    text = text.toFixed(10);
  }
  return text;
};

export const renderPercentage = (bitcoinPriceDate, sats) => {
  return function (text, record, key) {
    if (text === null) {
      return '--';
    }
    let baseline;
    if (bitcoinPriceDate === 'today') {
      baseline = getPriceInBTC(record.yesterday, sats);
    } else {
      baseline = getPriceInBTC(record.today, sats);
    }
    text = getPriceInBTC(text, sats);
    text = toDecimal(
      ((bitcoinPriceDate === 'today' ? text - baseline : baseline - text) *
        100) /
        (bitcoinPriceDate == 'today' ? baseline : text)
    );
    return <span className={text >= 0 ? 'green' : 'red'}>{text} %</span>;
  };
};

export const renderPriceInBTC = (sats) => {
  return (text) => {
    text = getPriceInBTC(text, sats);
    return sats ? (
      <div className="sats">
        <span>{toCurrencyFormat(text)}</span>
        <img src={satoshi} className="satoshi" />
      </div>
    ) : (
      `${text} ₿`
    );
  };
};

export const symbolGroups = [
  {
    title: 'View All',
    type: 'all',
  },
  {
    title: 'Primary',
    type: 'primary',
  },
  {
    title: 'Fiat',
    type: 'currency',
  },
  {
    title: 'Precious Metals',
    type: 'metals',
  },

  {
    title: 'Commodities',
    type: 'commodity',
  },
  {
    title: 'Equity Markets',
    type: 'equity',
  },
  {
    title: 'Bond Markets',
    type: 'bonds',
  },
  {
    title: 'Housing',
    type: 'housing',
  },
  {
    title: 'Wages',
    type: 'average-yearly-wage',
  },
];
const primaryTableKeyMap = {
  USD: 'fiat',
  XAUUSD: 'metals',
  'GSPC.INDX': 'index',
  CL1: 'energy',
  MSPNHSUS: 'consumer-goods',
};
export const summaryTables = [
  {
    title: 'Primary',
    showSymbol: false,
    assetColumnName: 'PRIMARY',
    api: 'bitcoin-stats-v2/summary?key=USD,XAUUSD,GSPC.INDX,CL1,MSPNHSUS',
    group: 'primary',
    disableSortByOrder: true,
    // tableKey : 'primary',
    tableKey: (key) => {
      return primaryTableKeyMap[key] || 'primary';
    },
  },
  {
    title: 'Fiat',
    showSymbol: true,
    assetColumnName: 'CURRENCY',
    api: 'bitcoin-stats-v2/summary?type=currency',
    group: 'currency',
    tableKey: 'fiat',
  },
  {
    title: 'Precious Metals',
    api: 'bitcoin-stats-v2/summary?type=commodity&subType=metals',
    assetColumnName: 'METAL',
    group: 'metals',
    tableKey: 'metals',
  },
  {
    title: 'Commodities',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=commodity&subType=energy',
    assetColumnName: 'ENERGY',
    group: 'commodity',
    tableKey: 'energy',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=commodity&subType=agriculture',
    assetColumnName: 'AGRICULTURE',
    group: 'commodity',
    mobileAssetColumnName: 'AGRIC',
    tableKey: 'agriculture',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=commodity&subType=livestock',
    assetColumnName: 'LIVESTOCK',
    group: 'commodity',
    tableKey: 'livestock',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=commodity&subType=industrial',
    assetColumnName: 'INDUSTRIAL',
    group: 'commodity',
    tableKey: 'industrial',
    mobileAssetColumnName: 'INDUSTRY',
  },

  {
    title: 'Equity Markets',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=index',
    assetColumnName: 'INDEX',
    group: 'equity',
    tableKey: 'index',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=sector',
    assetColumnName: 'SECTOR',
    showSymbol: true,
    group: 'equity',
    tableKey: 'sector',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=faamg',
    assetColumnName: 'FAAMG',
    showSymbol: true,
    group: 'equity',
    tableKey: 'faamg',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=bankingandfinance',
    assetColumnName: 'BANKING & FINANCE',
    showSymbol: true,
    group: 'equity',
    tableKey: 'banking',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=bitcoinandcrypto',
    assetColumnName: 'BITCOIN & CRYPTO',
    showSymbol: true,
    group: 'equity',
    tableKey: 'crypto',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=renewables',
    assetColumnName: 'RENEWABLES',
    showSymbol: true,
    group: 'equity',
    mobileAssetColumnName: 'RENEW',
    tableKey: 'renewable',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=auto',
    assetColumnName: 'Auto',
    showSymbol: true,
    group: 'equity',
    tableKey: 'auto',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=aviation',
    assetColumnName: 'Airlines',
    showSymbol: true,
    group: 'equity',
    tableKey: 'airlines',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=arkinvest',
    assetColumnName: 'ARK Invest',
    showSymbol: true,
    group: 'equity',
    tableKey: 'ark-invest',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=goldsilveruranium',
    assetColumnName: 'Gold, Silver, & Uranium',
    showSymbol: true,
    group: 'equity',
    tableKey: 'gold-silver-uranium',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=oilgas',
    assetColumnName: 'OIL & GAS',
    showSymbol: true,
    group: 'equity',
    tableKey: 'oil-gas',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=vices',
    assetColumnName: 'VICES',
    showSymbol: true,
    group: 'equity',
    tableKey: 'vices',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=gaming',
    assetColumnName: 'GAMING',
    showSymbol: true,
    group: 'equity',
    tableKey: 'gaming',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=cloud',
    assetColumnName: 'CLOUD',
    showSymbol: true,
    group: 'equity',
    tableKey: 'cloud',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=pharma',
    assetColumnName: 'PHARMA',
    showSymbol: true,
    group: 'equity',
    tableKey: 'pharma',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=mediaandpress',
    assetColumnName: 'MEDIA & PRESS',
    showSymbol: true,
    group: 'equity',
    tableKey: 'media-press',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=telecom',
    assetColumnName: 'TELECOM',
    showSymbol: true,
    group: 'equity',
    tableKey: 'telecom',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=foodandbeverage',
    assetColumnName: 'FOOD AND BEVERAGE',
    showSymbol: true,
    group: 'equity',
    tableKey: 'food-beverage',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=transportation',
    assetColumnName: 'TRANSPORTATION',
    showSymbol: true,
    group: 'equity',
    mobileAssetColumnName: 'TRANS',
    tableKey: 'transport',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=hospitality',
    assetColumnName: 'HOSPITALITY & TRANSPORTATION',
    showSymbol: true,
    group: 'equity',
    mobileAssetColumnName: 'HOS & TRVL',
    tableKey: 'hospitality',
  },
  {
    title: '',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=retail',
    assetColumnName: 'RETAIL',
    showSymbol: true,
    group: 'equity',
    tableKey: 'retail',
  },
  {
    title: 'Bond Markets',
    api: 'bitcoin-stats-v2/summary?type=stock&subType=bond',
    assetColumnName: 'Bond ETFs',
    showSymbol: true,
    group: 'bonds',
    tableKey: 'bonds',
  },
  // {
  //   title: 'Consumer Goods',
  //   api: 'bitcoin-stats-v2/summary?type=fred&subType=fred',
  //   assetColumnName: 'Consumer Goods',
  //   showSymbol: false,
  //   group: 'consumerGoods',
  //   tableKey: 'consumer-goods',
  //   hideColumns: ['todayPercentage'],
  //   mobileAssetColumnName: 'GOODS',
  // },
  {
    title: 'Housing',
    api: 'bitcoin-stats-v2/summary?type=fred,commodity&subType=housing',
    assetColumnName: 'Housing',
    showSymbol: false,
    group: 'housing',
    tableKey: 'housing',
    hideColumns: ['todayPercentage'],
    mobileAssetColumnName: 'HOUSING',
  },
  {
    title: 'Wages',
    api: 'bitcoin-stats-v2/summary?type=commodity&subType=averageyearlywage',
    assetColumnName: 'Average Yearly Wage',
    showSymbol: false,
    group: 'average-yearly-wage',
    tableKey: 'average-yearly-wage',
    hideColumns: ['todayPercentage'],
    mobileAssetColumnName: 'YEARLY WAGE',
  },
];
