import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { createAction } from '@arivaa-react/redux';
import { GET_DASHBOARD, GET_ENTITIES } from 'app-redux/actions';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {
    try {
      const action = await this.props.getDashboard();
      if (action.error) {
        throw action;
      }
    } catch (e) {
      console.warn('Error while getting dashboard', e);
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getDashboard: () => {
      const action = createAction(GET_ENTITIES, {
        entityName: 'dashboard',
        url: 'stats',
      });
      action.type = GET_DASHBOARD;
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ dashboard, user }) => {
  return {
    user,
    dashboard,
  };
};
Main.displayName = 'Dashboard';
Main.notRoute = true;
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
