import React from 'react';
import './styles.scss';
import { BlockPicker } from 'react-color';

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { colors } = this.props;
  return (
    <div className="color-picker">
      <BlockPicker
        triangle={'hide'}
        colors={colors}
        color={this.state.color}
        onChange={this.handleChange.bind(this)}
      />
    </div>
  );
};
export default view;
