/**
 * Authentication Reducer
 */

import { getError } from '@arivaa-react/redux';
import { GET_PROFILE, LOGOUT, UPDATE_CURRENT_USER } from '../actions';

/**
 * Reducer Function
 * @param state
 * @param action
 * @returns {*}
 */
export default function (state = null, action) {
  switch (action.type) {
    case GET_PROFILE:
      if (!getError(action)) {
        let data = action.payload.data;
        return {
          ...data,
        };
      } else {
        return null;
      }
    case UPDATE_CURRENT_USER:
      if (!getError(action)) {
        let data = action.payload.data;
        return {
          ...state,
          ...data,
        };
      } else {
        return state;
      }
    case LOGOUT:
      return null;
  }
  return state;
}
