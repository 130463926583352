import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import ContactSection from '../../contact/contactSection';
import ContactWithOnlyQuery from '../../contact/withOnlyQuery';
import ContactWithoutQuery from '../../contact/withoutQuery';
import ContactWithQueryAndInformation from '../../contact/withQueryAndInformation';
import Envelope from 'images/envelope.png';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Contact Section'} />
        <div className="section">
          <ContactSection />
        </div>
      </div>
      <div className="demo">
        <Heading title={'Contact With Query and Information'} />
        <div className="section">
          <ContactWithQueryAndInformation
            className="custom-contact"
            buttonText="submit"
            address="695 President Place, Suite 200, Smyrna, TN 37167"
            email="help@nationaltrtclinics.com"
            phoneNumber1="615-205-7756"
            phoneNumber2="615-235-1392"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Contact Without Query'} />
        <div className="section">
          <ContactWithoutQuery
            className="custom-contact"
            phoneNumber="615-205-7756"
            email="help@nationaltrtclinics.com"
            address={[
              'Residential Reversions Ltd T/a Sixty Plus Finance',
              '18 Merrion Road',
              'Dublin',
              'D02 A316',
            ]}
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Contact With Only Query'} />
        <div className="section">
          <ContactWithOnlyQuery
            className="custom-contact"
            buttonText="send message"
            image={Envelope}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
