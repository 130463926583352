import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Col, Row } from 'antd';
import fb from 'images/fb.svg';
import tw from 'images/tw.svg';
import pin from 'images/pinterest.svg';
import yt from 'images/yt.svg';
import google from 'images/google.svg';
import Link from '@arivaa-react/components/link';
import SampleLogo from 'images/logo.png';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of footer with social icon
 * className : -
 * data : - array element -[]
 * logo : -
 * facebookIcon : -
 * googleIcon : -
 * twitterIcon :-
 * youtubeIcon : -
 * pinterestIcon : -
 * copyright : -
 */

const Main = ({
  data,
  logo,
  facebookIcon,
  googleIcon,
  twitterIcon,
  youtubeIcon,
  pinterestIcon,
  copyright,
  className,
}) => {
  return (
    <div className={'social-icon-footer ' + className}>
      <div className="main">
        <div className="container">
          <div className="content">
            <Row gutter={48}>
              {data
                ? data.map((item, index) => {
                    const { label, menus } = item;
                    return (
                      <Col xs={12} sm={8} md={8} lg={6} xl={6} key={index}>
                        <p className="label">{label || 'Label'}</p>
                        <ul>
                          {menus
                            ? menus.map((item, index2) => {
                                const { menu, routeKey } = item;
                                return (
                                  <li key={index2}>
                                    <Link routeKey={routeKey}>
                                      {menu || 'Menu'}
                                    </Link>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      </Col>
                    );
                  })
                : null}

              <Col xs={12} sm={24} md={24} lg={6} xl={6}>
                <p className="label">Social</p>
                <ul className="social">
                  {facebookIcon ? (
                    <li>
                      <a target={facebookIcon}>
                        <img src={fb} alt="Facebook" />
                      </a>
                    </li>
                  ) : null}
                  {googleIcon ? (
                    <li>
                      <a target={facebookIcon}>
                        <img src={google} alt="Google" />
                      </a>
                    </li>
                  ) : null}
                  {twitterIcon ? (
                    <li>
                      <a target={twitterIcon}>
                        <img src={tw} alt="Twitter" />
                      </a>
                    </li>
                  ) : null}
                  {youtubeIcon ? (
                    <li>
                      <a target={youtubeIcon}>
                        <img src={yt} alt="Youtube" />
                      </a>
                    </li>
                  ) : null}
                  {pinterestIcon ? (
                    <li>
                      <a target={pinterestIcon}>
                        <img src={pin} alt="Pinterest" />
                      </a>
                    </li>
                  ) : null}
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <div className="others">
          <div className="container">
            <div className="left">
              <ul>
                <li className="logo">
                  <img src={logo || SampleLogo} alt={'logo'} />
                </li>
              </ul>
            </div>
            <div className="right">
              <div className="copyright">
                <p>{copyright || 'Copyright © 2020 All Rights Reserved'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
