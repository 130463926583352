import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Icon } from 'antd';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ heading, email, phone, buttonText }) => {
  return (
    <div className="ui-topHeader">
      <div className="container">
        <div className="topline">
          <div className="top-left">
            <p>{heading}</p>
          </div>
          <div className="top-right">
            <ul>
              <li>
                <Icon type="mail" />
                {email}
              </li>
              <li>
                <Icon type="phone" />
                {phone}
              </li>
              <li>
                <Link className="ant-btn primary-btn">{buttonText}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
