import React, { Component, useState } from 'react';
import StatsTable from './stats-table';
import { Switch, Row, Col, Input } from 'antd';
import './style.scss';
import Banner from '../../components/banner';
import Link from '@arivaa-react/components/link';
import { summaryTables, symbolGroups } from '../../helpers/table';

const { Search } = Input;
const tableList = [...summaryTables];
const groups = [...symbolGroups];

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = (props) => {
  const [sats, setSats] = useState(true);
  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState(undefined);
  const filtersList = (
    <div className="filters-list">
      <ul>
        {groups.map(({ title, type }, index) => {
          return (
            <li key={index}>
              <Link
                key={index}
                className={type === filter ? 'selected' : ''}
                onClick={() => setFilter(type)}
              >
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
  return (
    <div className="landing page">
      <div className="container">
        <div className="content">
          <div className="tables">
            <div className="filters">
              <div className="left">
                <div className="switch">
                  <Switch
                    checked={sats}
                    onChange={(checked) => {
                      setSats(checked);
                    }}
                    size="small"
                  />
                  <span className="text">SATS</span>
                </div>
                {filtersList}
              </div>
              <div className="right">
                <div className="search">
                  <Search
                    placeholder="Search for Assets"
                    onChange={(e) => {
                      setSearch((e.target.value || '').trim());
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mobile-filters">{filtersList}</div>
            {tableList
              .filter(({ group }) =>
                filter === 'all' ? true : filter === group
              )
              .map((item, index) => {
                return (
                  <>
                    <StatsTable
                      {...item}
                      key={index}
                      sats={sats}
                      index={index}
                      showTitle={filter === 'all'}
                      search={search}
                    />
                    {/*<br />*/}
                  </>
                );
              })}
            <div className="about">
              <Row gutter={96}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div
                    className="points"
                    dangerouslySetInnerHTML={{
                      __html: window.app.ui.HOME_SECTION_1,
                    }}
                  ></div>
                </Col>
              </Row>
              <Row gutter={96}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="information">
                    <div
                      className="section"
                      dangerouslySetInnerHTML={{
                        __html: window.app.ui.HOME_SECTION_2,
                      }}
                    ></div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div
                    className="section"
                    dangerouslySetInnerHTML={{
                      __html: window.app.ui.HOME_SECTION_3,
                    }}
                  ></div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Main.displayName = 'Home';

//Pre process the container with Redux Plugins
export default Main;
