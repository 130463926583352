import React from 'react';
import { ModalUtils } from '@arivaa-react/components/modal';
import { jsUcfirst, camelize } from '@arivaa-react/helpers/common';
import CrudForm from '../containers/crud-form';
let obj = {};
export default obj;
function requireAll(r) {
  let crudForms = {};

  r.keys().map((r) => {
    if (r.endsWith('test.js')) {
      return;
    }
    if (
      r !== './index.js' &&
      r.endsWith('/index.js') &&
      r.split('/').length === 3
    ) {
      const name = r.replace('.js', '');
      const file = require(`${name}`).default;
      if (file) {
        const fileName = name.replace('./', '').replace('/index', '');
        obj[fileName] = file;
        if (file.form) {
          const formName = (jsUcfirst(camelize(fileName)) + 'Form').replace(
            /-/g,
            ''
          );
          file.formId = formName;
          crudForms[formName] = (props) => {
            return <CrudForm {...props} {...file.form} entity={file.entity} />;
          };
        }
      }
    } else if (
      r !== './index.js' &&
      r.endsWith('form/index.js') &&
      r.split('/').length === 4
    ) {
      const name = r.replace('.js', '');
      const formName = (jsUcfirst(camelize(r.split('/')[1])) + 'Form').replace(
        /-/g,
        ''
      );
      crudForms[formName] = require(`${name}`).default;
    }
    return r;
  });
  setTimeout(() => {
    Object.keys(crudForms).map((key) => {
      ModalUtils.registerModal(key, crudForms[key]);
    });
  });
}
requireAll(require.context('./', true, /\.js$/));
