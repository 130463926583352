var menuKeys = [
  {
    key: 'ui.banner',
    name: 'Banner',
    icon: 'desktop',
  },
  {
    key: 'ui.information',
    name: 'Information',
    icon: 'read',
  },
  {
    key: 'ui.cards',
    name: 'Cards',
    icon: 'border',
  },
  {
    key: 'ui.cardsList',
    name: 'Cards List',
    icon: 'profile',
  },
  {
    key: 'ui.devices',
    name: 'Devices',
    icon: 'mobile',
  },
  {
    key: 'ui.contact',
    name: 'Contact',
    icon: 'desktop',
  },
  {
    key: 'ui.features',
    name: 'Features',
    icon: 'desktop',
  },
  {
    key: 'ui.footer',
    name: 'Footer',
    icon: 'desktop',
  },
  {
    key: 'ui.header',
    name: 'Headers',
    icon: 'desktop',
  },
  {
    key: 'ui.introduction',
    name: 'Introduction',
    icon: 'desktop',
  },
  {
    key: 'ui.location',
    name: 'Location',
    icon: 'desktop',
  },
  {
    key: 'ui.newsletter',
    name: 'News Letter',
    icon: 'desktop',
  },
  {
    key: 'ui.pricing',
    name: 'Pricing',
    icon: 'desktop',
  },
  {
    key: 'ui.testimonials',
    name: 'Testimonials',
    icon: 'desktop',
  },
];

export default menuKeys;
