import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Quote from 'images/quote.png';
import SampleImage from 'images/sample.jpg';
import StarRatings from 'react-star-ratings';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of testimonial cards component
 * className : -
 * design : -
 * image : -
 * name : -
 * designation : -
 * comment : -
 */

const Main = ({ data, index }) => {
  const { className, rating, review, media, name, designation } = data[index];
  return (
    <div className={'card testimonial ' + className}>
      <div className="card-details">
        <div className="quote">
          <img src={Quote} />
        </div>
        <div className="stars">
          <StarRatings
            rating={rating || 5}
            starRatedColor="#f7be59"
            numberOfStars={5}
            name="rating"
            starDimension="25"
            starSpacing="2px"
          />
        </div>
        <div className="text">
          {review ||
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
        </div>
        <p className="line" />
      </div>
      <div className="author">
        <div className="image">
          <img src={media || SampleImage} />
        </div>
        <div className="about">
          <p className="name">{name || 'Lorem Ipsum'}</p>
          <p className="designation">{designation || 'Lorem Ipsum'}</p>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
