import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import BottomHeader from './bottomHeader';
import TopHeader from './topHeader';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
/**
 * @default values of double header
 * className : -
 * data : - array element -[]
 * Heading : -
 * Email : -
 * Phone : -
 * topButtonText : -
 * logoImage : -
 * bottomButtonText : -
 */

const Main = ({
  Heading,
  Email,
  Phone,
  topButtonText,
  data,
  logoImage,
  bottomButtonText,
  className,
}) => {
  return (
    <div className={'header-sample1 ' + className}>
      <TopHeader
        heading={Heading}
        email={Email}
        phone={Phone}
        buttonText={topButtonText}
      />
      <BottomHeader
        navigationData={data}
        logo={logoImage}
        buttonText={bottomButtonText}
      />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
