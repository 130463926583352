import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import SolidBgFooter1 from '../../footer/solidBgFooter1';
import SolidBgFooter2 from '../../footer/solidBgFooter2';
import ImageBgFooter from '../../footer/imageBgFooter';
import SocialIconFooter from '../../footer/socialIconFooter';
import NewsletterFooter from '../../footer/newsletterFooter';
import Logo from 'images/logo.png';
import {
  SolidBg1Data,
  SolidBg2Data,
  ImageBgData,
  SocialIconData,
  NewsletterData,
} from '../../config/footer.js';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Footer with solid color as background type 1'} />
        <div className="section">
          <SolidBgFooter1
            data={SolidBg1Data}
            facebook="https://www.facebook.com/"
            linkedin="https://www.linkedin.com/"
            twitter="https://www.twitter.com/"
            copyright="Copyright © 2020. All Rights Reserved."
            className="custom-footer"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Footer with solid color as background type 2'} />
        <div className="section">
          <SolidBgFooter2
            data={SolidBg2Data}
            logo={Logo}
            companyslogan="Always a text away!"
            facebook="https://www.facebook.com/"
            linkedin="https://www.linkedin.com/"
            twitter="https://www.twitter.com/"
            instagram="https://www.instagram.com/"
            copyright="Copyright © 2020. All Rights Reserved."
            className="custom-footer2"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Footer with background image'} />
        <div className="section">
          <ImageBgFooter
            data={ImageBgData}
            logo={Logo}
            bgImage="https://png.pngtree.com/thumb_back/fh260/back_pic/02/66/55/50578b1ecd8c4ae.jpg"
            facebook="https://www.facebook.com/"
            linkedin="https://www.linkedin.com/"
            copyright="Copyright © 2020. All Rights Reserved."
            className="custom-bg-footer"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Footer with social media colorful icon'} />
        <div className="section">
          <SocialIconFooter
            data={SocialIconData}
            logo={Logo}
            facebookIcon="https://www.facebook.com/"
            linkedinIcon="https://www.linkedin.com/"
            twitterIcon="https://www.twitter.com/"
            googleIcon="https://www.google.com/"
            youtubeIcon="https://www.youtube.com/"
            pinterestIcon="https://www.pinterest.com/"
            copyright="Copyright © 2020. All Rights Reserved."
            className="custom-social-icon-footer"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Footer with newsletter'} />
        <div className="section">
          <NewsletterFooter
            data={NewsletterData}
            logo={Logo}
            facebook="https://www.facebook.com/"
            linkedin="https://www.linkedin.com/"
            twitter="https://www.twitter.com/"
            instagram="https://www.instagram.com/"
            copyright="Copyright © 2020. All Rights Reserved."
            className="custom-newsletter-footer"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
