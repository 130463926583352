export var withMultipleImageData = [
  {
    image:
      'https://cdn.pixabay.com/photo/2015/06/24/15/45/ipad-820272_960_720.jpg',
    counter: 'Feature One',
    name: 'A mobile website option',
    about:
      'Online shoppers need to be offered convenience and instant ease-of-access to your online store, no matter what device they’re using. Your online store should be built on a responsive design template and its features need to be available to all users, at any time, and from anywhere. ',
    responsive_row: 'normal',
  },
  {
    image:
      'https://cdn.pixabay.com/photo/2020/03/25/13/44/space-4967335_960_720.jpg',
    counter: 'Feature Two',
    name: 'Free or competitive shipping options',
    about:
      'Free or competitive shipping options, when done well, can drive your success. The right formula will have your clients increasing their cart total in order to get free shipping! And of course, there are other shipping options that appeal to online shoppers… you just have to understand your niche market well enough to make the most of them.',
    responsive_row: 'reverse',
  },
  {
    image:
      'https://cdn.pixabay.com/photo/2015/06/27/16/34/food-823607_960_720.jpg',
    counter: 'Feature Three',
    name: 'Superior photos and image options',
    about:
      ' The photos should also be taken from different points of view to give you a clearer idea of the product. Image options should include viewing angles, zoom, multiple images, and more. This, however, does not mean that the pictures should be overly enhanced or exaggerated. ',
    responsive_row: 'normal',
  },
  {
    image:
      'https://cdn.pixabay.com/photo/2018/09/24/15/04/board-3700116_960_720.jpg',
    counter: 'Feature Four',
    name: 'Customer reviews of the product',
    about:
      'Customer reviews now take the place of your friendly, neighborly recommendation and are an effective selling tactic for online businesses. Such reviews are vital when it comes to virtual shopping. If possible, include video reviews in the customer reviews, with detailed personal experiences and how-to-use tutorials. ',
    responsive_row: 'reverse',
  },
];
export var withSingleImageData = [
  {
    column: 'left',
    data: [
      {
        icon: 'picture',
        name: 'Hide WhatsApp group photos and videos from gallery',
        about:
          'Just open a WhatsApp group and tap on the group name to access the option (as shown in the image). This method won’t remove already existing WhatsApp images in your gallery (you will have to delete them) and will hide new incoming media only.',
      },
      {
        icon: 'unlock',
        name: 'Secure your WhatsApp using Fingerprint lock',
        about:
          ' To do so, go to settings >> Accounts >> Privacy and scroll down to fingerprint lock.Before using the fingerprint unlock, you must know that in case the fingerprint authentication fails, there is no option to unlock using pattern.',
      },
      {
        icon: 'environment',
        name: 'Share Live Location (Real Time)',
        about:
          'The location is updated in real-time and you can keep a tab on exact whereabouts of specific contacts. You can share location for 15 minutes, 1 Hour or 8 Hours straight.',
      },
    ],
  },
  {
    column: 'middle',
    data:
      'https://cdn.pixabay.com/photo/2019/09/05/18/18/phone-4454743_960_720.png',
  },
  {
    column: 'right',
    data: [
      {
        icon: 'sound',
        name: 'Assign sound to different notifications.',
        about:
          'If you are running Android Oreo, just go to the app info page (you can also do this by long-pressing app notifications) and then select the manage notification option.',
      },
      {
        icon: 'pushpin',
        name: 'Pin conversation or make shortcuts',
        about:
          ' You can simply long-press the conversation and select the Pin icon in the options menu that appears on top. You can also press the triple dot menu and select create shortcut option to place the conservation shortcut on the main page.',
      },
      {
        icon: 'eye-invisible',
        name: 'Hide a WhatsApp chat',
        about:
          'Just long-press the conversation and select the Archive button on the top. This particular chat will now disappear from the list. You can access it by scrolling all the way to the bottom.',
      },
    ],
  },
];

export var withNoImageData = [
  {
    icon: 'heart',
    featureTitle: 'Community',
    featureDetail:
      'We work every day to give back to the community we live, play, and work in.',
    bgColor: 'light',
  },
  {
    icon: 'safety-certificate',
    featureTitle: 'Integrity',
    featureDetail:
      'We listen and respond with genuine transparency - you can trust us to stick to our word.',
    bgColor: 'dark',
  },
  {
    icon: 'usergroup-add',
    featureTitle: 'Collaboration',
    featureDetail:
      'We work together to grow and reach new heights through honest communication.',
    bgColor: 'dark',
  },
  {
    icon: 'crown',
    featureTitle: 'Leadership',
    featureDetail:
      'We inspire our team to go above and beyond by working alongside them - teaching and encouraging.',
    bgColor: 'darker',
  },
  {
    icon: 'global',
    featureTitle: 'Relationship',
    featureDetail:
      'We build relationships. This is the heart of our company because we want to be a true partner throughout your entire building process.',
    bgColor: 'darker',
  },
  {
    icon: 'star',
    featureTitle: 'Creativity',
    featureDetail:
      'We think outside the box to challenge the norms, solve problems and achieve your vision.',
    bgColor: 'darkest',
  },
];
