/**
 * Root Reducer for all reducers
 */
import createEntityReducer from '../../crud-module/helpers/create-entity-reducer';
import createSingleEntityReducer from '../../crud-module/helpers/create-single-entity-reducer';
import CrudEntityConfig from '../../crud-module/entity-configs';

let obj = {
  emitter: (state = null) => state,
  bitcoinStats: (state = null) => state,
};

Object.keys(CrudEntityConfig).map((key) => {
  obj[key] = createEntityReducer(key, CrudEntityConfig[key].reducer);
  obj[key + '_single'] = createSingleEntityReducer(
    key,
    CrudEntityConfig[key].reducer
  );
});
export default obj;

function requireAll(r) {
  r.keys()
    .map((r) => {
      if (r !== 'index.js') {
        const name = r.replace('.js', '');
        const file = require(`${r}`);
        if (file) {
          obj[name.replace('./', '')] = file.default;
        }
      }
      return r;
    })
    .forEach(r);
}
requireAll(require.context('./', true, /\.js$/));
