import { getError } from '@arivaa-react/redux';
import { SERVER } from '../../constants/api';
import { GET_ENTITIES } from '../actions';
/**
 * Reducer Function
 * @param state
 * @param action
 * @returns {{}}
 */
export default function (state = {}, action) {
  switch (action.type) {
    case GET_ENTITIES + '_timeline':
      if (!getError(action)) {
        const data = action.payload.data;
        const requestPath = action.payload.config.url.replace(SERVER, '');
        state = {
          ...state,
          [requestPath]: data,
        };

        return state;
      }
  }
  return state;
}
