import React, { Component, useEffect, useState } from 'react';
import './style.scss';
import { Spin, Switch, Icon } from 'antd';
import ReactHighstock from 'react-highcharts/ReactHighstock';
import preprocess from '../../containers/preprocess';
import { GET_ENTITIES } from '../../redux/actions';
import { createAction } from '../../@arivaa-react/redux/helpers/action';
import Link from '@arivaa-react/components/link';
import satoshi from '../../images/stocks/satoshi-yellow.png';
import { toCurrencyFormat } from '../../helpers/currency';
import Highcharts from 'react-highcharts';

/**
 * @description Chart Component
 * @type component
 * @author Jasjot
 */
const Main = ({ match, symbolData, getData, data }) => {
  const [loading, setLoading] = useState(false);
  const [sats, setSats] = useState(true);
  const [log, setLog] = useState(false);
  const [selected, setSelected] = useState(0);
  const { symboltype, symbol } = match.params;
  const config = {
    rangeSelector: {
      selected: selected,
      buttons: [
        {
          type: 'week',
          count: 1,
          text: '1w',
          title: 'View 1 Week',
        },
        {
          type: 'month',
          count: 1,
          text: '1m',
          title: 'View 1 month',
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
          title: 'View 3 months',
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
          title: 'View 6 months',
        },
        {
          type: 'ytd',
          text: 'YTD',
          title: 'View year to date',
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
          title: 'View 1 year',
        },
        {
          type: 'year',
          count: 3,
          text: '3y',
          title: 'View 3 years',
        },

        // {
        //   type: 'year',
        //   count: 5,
        //   text: '5y',
        //   title: 'View 5 years',
        // },
        {
          type: 'all',
          text: '5y',
          title: 'View 5 years',
        },
      ].map((item, index) => {
        item.events = {
          click: () => {
            setSelected(index);
          },
        };
        return item;
      }),
      buttonTheme: {
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        r: 4,
        style: {
          color: '#fff',
          fontWeight: 'bold',
        },
      },
      inputBoxHeight: 30,
      inputBoxWidth: 120,
      inputStyle: {
        fontWeight: 'bold',
      },
      labelStyle: {
        color: '#fff',
        fontWeight: 'bold',
      },
    },

    title: {
      text: `${decodeURIComponent(symbol)}/${
        sats ? 'SATS' : 'BTC'
      } Price History`,
      align: 'left',
    },
    plotOptions: {
      series: {
        color: '#f5922f',
      },
      area: {
        fillOpacity: 0.2,
        /*lineWidth: 1,
        step: 'center',*/
      },
    },

    xAxis: {
      gridLineWidth: 1,
      gridLineColor: '#1c202e',
      labels: {
        style: {
          fontSize: 12,
          color: '#b4b7c2',
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          fontSize: 12,
          color: '#b4b7c2',
        },
      },
    },
    ...(log
      ? {
          // xAxis: {
          //   type: 'logarithmic',
          // },
          yAxis: {
            type: 'logarithmic',
            pointStart: 1,
          },
        }
      : {}),
    series: [
      {
        name: symbol + ' Price',
        data: !sats
          ? data.map((item) => {
              item = [...item];
              item[1] = item[1] / 100000000;
              return item;
            })
          : data, //require('./aapl-c.json'),
        // type: 'spline',
        tooltip: {
          valueDecimals: 2,
        },
        type: 'area',
      },
    ],
  };
  useEffect(() => {
    Highcharts.Highcharts.setOptions({
      lang: {
        rangeSelectorFrom: 'From 1',
        rangeSelectorTo: 'To 123',
      },
    });
    (async () => {
      setLoading(true);
      try {
        await getData(symboltype + '/' + symbol);
      } catch (e) {
        console.error('Error while getting data for stats table', {
          e,
        });
      }
      setLoading(false);
    })();
  }, []);
  // : symboltype === 'currency'
  // ? `${symbol}/${sats ? 'SATS' : 'BTC'}`
  return (
    <div className="chart page">
      <div className="container">
        <div className="content">
          <div className="switch">
            <Switch
              checked={sats}
              onChange={(checked) => {
                setSats(checked);
              }}
              size="small"
            />
            <span className="text">SATS</span>
            <Switch
              style={{
                marginLeft: 10,
              }}
              checked={log}
              onChange={(checked) => {
                setLog(checked);
              }}
              size="small"
            />
            <span className="text">LOG SCALE</span>
          </div>
          <p className="title">
            <Link routeKey="landing" className="back">
              <Icon type="arrow-left" />
            </Link>
            {symboltype === 'metal' || symboltype === 'currency'
              ? symbolData && symbolData.title
              : (symbolData && symbolData.instrument_name) || ''}
          </p>
          {data && data[data.length - 1] && (
            <p className="stats">
              <span>
                {sats
                  ? toCurrencyFormat(Math.round(data[data.length - 1][1]))
                  : (data[data.length - 1][1] / 100000000).toFixed(6)}
              </span>
              &nbsp;
              {sats ? <img src={satoshi} className="satoshi" /> : '₿'}
            </p>
          )}
          <div className="render-chart">
            <Spin spinning={loading}>
              <ReactHighstock config={config} />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getData: (entityId) => {
      const action = createAction(GET_ENTITIES, {
        entityName: 'bitcoin-stats/series',
        entityId,
      });
      action.type = action.type + '_timeline';
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ timeline, bitcoinStats }, { match }) => {
  const data =
    timeline[
      `bitcoin-stats/series/${match.params.symboltype}/${match.params.symbol}`
    ];
  console.log({ data });
  return {
    data: (data && data.data) || [],
    symbolData: (data && data.symbolData) || {},
    bitcoinPrices: bitcoinStats.prices,
  };
};

Main.displayName = 'Chart';
Main.url = 'chart/:symboltype/:symbol';
//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
