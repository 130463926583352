import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import mackbook from 'images/macbook-screen.png';
import ipad from 'images/ipad.png';
import iphone from 'images/iphone.png';
import android from 'images/android.png';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ type, image }) => {
  function uiDevice() {
    switch (type) {
      case 'macbook':
        type = (
          <div className="device device-macbook device-spacegray">
            <div className="device-frame">
              <div className="device-content">
                <img src={image || mackbook} />
              </div>
            </div>
            <div className="device-stripe" />
            <div className="device-header" />
            <div className="device-sensors" />
            <div className="device-btns" />
            <div className="device-power" />
          </div>
        );
        break;
      case 'ipad':
        type = (
          <div className="device device-ipad-pro device-spacegray">
            <div className="device-frame">
              <div className="device-content">
                <img src={image || ipad} />
              </div>
            </div>
            <div className="device-stripe" />
            <div className="device-header" />
            <div className="device-sensors" />
            <div className="device-btns" />
            <div className="device-power" />
          </div>
        );
        break;
      case 'iphone':
        type = (
          <div className="device device-iphone-x">
            <div className="device-frame">
              <div className="device-content">
                <img src={image || iphone} />
              </div>
            </div>
            <div className="device-stripe" />
            <div className="device-header" />
            <div className="device-sensors" />
            <div className="device-btns" />
            <div className="device-power" />
            <div className="device-home" />
          </div>
        );
        break;
      case 'imac':
        type = (
          <div className="device device-imac-pro">
            <div className="device-frame">
              <div className="device-content">
                <img src={image || mackbook} />
              </div>
            </div>
            <div className="device-stripe" />
            <div className="device-header" />
            <div className="device-sensors" />
            <div className="device-btns" />
            <div className="device-power" />
          </div>
        );
        break;
      case 'android':
        type = (
          <div className="device device-google-pixel-2-xl">
            <div className="device-frame">
              <div className="device-content">
                <img src={image || android} />
              </div>
            </div>
            <div className="device-stripe" />
            <div className="device-header" />
            <div className="device-sensors" />
            <div className="device-btns" />
            <div className="device-power" />
          </div>
        );
        break;
      default:
        type = (
          <div className="device device-iphone-x">
            <div className="device-frame">
              <div className="device-content">
                <img src={image || iphone} />
              </div>
            </div>
            <div className="device-stripe" />
            <div className="device-header" />
            <div className="device-sensors" />
            <div className="device-btns" />
            <div className="device-power" />
            <div className="device-home" />
          </div>
        );
    }
    return type;
  }

  return <div>{uiDevice()}</div>;
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

Main.displayName = 'UI-Devices';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
