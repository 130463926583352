import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from 'antd';
import TestimonialCard from '../../cards/testimonialCard';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of testimonials without rating type 1
 * className : -
 * data : - array element -[]
 * title : -
 * subTitle : -
 * buttonText : -
 */

const Main = ({ className, data, title, subTitle, buttonText }) => {
  return (
    <div className={'testimonial withoutRating-1 ' + className}>
      <div className="container">
        <div className="section-heading">
          <p className="title">{title || 'Lorem Ipsum'}</p>
          <p className="sub-title">
            {subTitle ||
              'Ut eget metus ut nisl e vitae orci. Curabitur sit amet facilisis tellus'}
          </p>
        </div>
        <Slider dots={true}>
          {data
            ? data.map((data, index1) => {
                const { slide, content } = data;
                return (
                  <div className={'slider ' + slide} key={index1}>
                    <div className="single-slide">
                      {content
                        ? content.map((data, index2) => {
                            const {
                              comment,
                              image,
                              name,
                              position,
                              bgColor,
                            } = data;
                            return (
                              <TestimonialCard
                                design="template2"
                                comment={comment}
                                image={image}
                                name={name}
                                designation={position}
                                bgColor={bgColor}
                              />
                            );
                          })
                        : null}
                    </div>
                  </div>
                );
              })
            : null}
        </Slider>
        <div className="button">
          <Button className="primary-btn">
            {buttonText || 'Write a review'}
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
