import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleBgImage from 'images/startup.jpg';
import { Button } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of introduction with background image
 * className : -
 * bgImage : -
 * title : -
 * description : -
 * buttonText : -
 */

const Main = ({ bgImage, title, description, buttonText, className }) => {
  return (
    <div
      className={'introduction bg-image ' + className}
      style={
        bgImage
          ? { backgroundImage: 'url(' + bgImage + ')' }
          : { backgroundImage: 'url(' + SampleBgImage + ')' }
      }
    >
      <div className="container">
        <div className="overlay" />
        <div className="sections">
          <div className="circle">
            <div className="content">
              <p className="title">{title || 'Lorem ipsum dolor sit amet'}</p>
              <p className="small-text">
                {description ||
                  'Ut eget metus ut nisl e vitae orci. Curabitur sit amet facilisis tellus. Nulla consectetur.'}
              </p>
              <div className="button">
                <Button className="primary-btn">
                  {buttonText || "Let's get started"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
