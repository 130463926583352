import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col } from 'antd';
import Link from '@arivaa-react/components/link';
import BlogCard from '../../cards/blogCard';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of blog cards list component
 * image : -
 * data : - array element - []
 */

const Main = ({ className, data }) => {
  return (
    <div className={'cards-list blog ' + className}>
      <div className="container">
        <p className="title">Trending</p>
        <Link className="link">See All</Link>
        <Row gutter={48}>
          {data
            ? data.map((child, index) => {
                const { image, tags, title, description, link } = child;
                return (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    className="column"
                  >
                    <BlogCard
                      image={image}
                      tags={tags}
                      title={title}
                      description={description}
                      link={link}
                    />
                  </Col>
                );
              })
            : null}
        </Row>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
