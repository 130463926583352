import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleImage from 'images/sample.jpg';
import { Row, Col } from 'antd';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of portfolio cards list component
 * className : -
 * data : - array element - []
 */

const Main = ({ className, data }) => {
  return (
    <div className={'card-list portfolio ' + className}>
      <div className="container">
        <div className="cards">
          <Row>
            {data
              ? data.map((item, index) => {
                  const { imageUrl, name } = item;
                  return (
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} key={index}>
                      <Link>
                        <div className="border">
                          <div className="detail">
                            <div className="image">
                              <img src={imageUrl || SampleImage} />
                            </div>
                            <div className="overlay" />
                            <div className="content">
                              <p className="name">{name || 'Lorem Ipsum'}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  );
                })
              : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
