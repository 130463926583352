export default [
  {
    key: 'home.dashboard',
    name: 'sider.dashboard',
    icon: 'desktop',
  },

  { key: 'home.blog-post', name: 'sider.blog-post', icon: 'edit' },
  { key: 'home.blog-category', name: 'sider.blog-category', icon: 'appstore' },
  { key: 'home.symbol', name: 'sider.symbol', icon: 'tag' },
  { key: 'home.system', name: 'sider.system', icon: 'file-text' },
  { key: 'home.settings', name: 'sider.settings', icon: 'setting' },
];
