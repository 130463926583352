import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Input, Icon, Form } from 'antd';

import Banner from '../../banner';

const { Item } = Form;
const { TextArea } = Input;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ description, title }) => {
  const handleSubmit = () => {};
  return (
    <div className="card contact">
      <Banner
        className={'custom-image-banner'}
        image="https://images.unsplash.com/photo-1473773508845-188df298d2d1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
        size="small"
      >
        <p className="description">{description || 'Discuss Your Project'}</p>
        <p className="title">{title || 'Get in Touch'}</p>
        <p className="line"></p>
      </Banner>
      <div className="card-details">
        <div className="form">
          <Form onSubmit={handleSubmit}>
            <Item hasFeedback={true}>
              <Input
                addonBefore={<Icon type="user" />}
                placeholder={'Full Name'}
                className="input"
              />
            </Item>
            <Item hasFeedback={true}>
              <Input
                addonBefore={<Icon type="mail" />}
                placeholder={'Email'}
                className="input"
              />
            </Item>
            <Item hasFeedback={true}>
              <Input
                addonBefore={<Icon type="phone" />}
                placeholder={'Phone No.'}
                className="input"
              />
            </Item>
            <Item hasFeedback={true}>
              <TextArea placeholder="Your Message" />
            </Item>
            <div className="action">
              <Button htmlType={'submit'} className="btn primary-btn">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
