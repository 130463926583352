import React, { Component } from 'react';
import './style.scss';
import { Row, Col } from 'antd';
import BlogCard from '../../components/blogCard';
import Banner from '../../components/banner';
import { createAction } from '../../@arivaa-react/redux/helpers/action';
import { GET_ENTITIES } from '../../redux/actions';

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({ pageData }) => {
  const blogCategories = pageData || [];

  return (
    <div className="publications page">
      <div className="container">
        {blogCategories.map(({ posts, title }, index) => {
          console.log(posts);
          return (
            <div className="content" key={index}>
              <p className="title">{title}</p>
              <Row gutter={24}>
                {posts
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .map((post, index) => {
                    return (
                      <Col xs={24} sm={24} md={6} lg={6} xl={6} key={index}>
                        <BlogCard
                          data={{
                            ...post,
                            link: 'blogDetails',
                            className: 'custom-class',
                          }}
                          index={0}
                        />
                      </Col>
                    );
                  })}
              </Row>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Main.displayName = 'Publications';

Main.routeProps = {
  getPageData: async function () {
    let { payload } = createAction(GET_ENTITIES, {
      entityName: 'blog-category/posts',
    });
    payload = await payload;
    return payload.data;
  },
};
//Pre process the container with Redux Plugins
export default Main;
