import Component from './main';
import {
  isAudio,
  isImage,
  isVideo,
  FileComponent,
  FileViewComponent,
} from './view';
export default Component;
export { isAudio, isImage, isVideo, FileComponent, FileViewComponent };
