import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

/**
 * View for the component
 * @returns {XML}
 */
var view = function () {
  const link = this.getRoute();
  const props = this.getLinkProps();
  return !link ? (
    <a rel="noreferrer" target="_blank" {...props} />
  ) : (
    <Link to={link} {...props} />
  );
};
export default view;
