import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Col, Row, Icon, Input, Button } from 'antd';
import Link from '@arivaa-react/components/link';
import SampleLogo from 'images/logo.png';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of footer with newsletter
 * className : -
 * data : - array element -[]
 * logo : -
 * facebook : -
 * linkedin : -
 * twitter : -
 * instagram : -
 * copyright : -
 */

const Main = ({
  data,
  logo,
  facebook,
  linkedin,
  twitter,
  instagram,
  copyright,
  className,
}) => {
  return (
    <div className={'newsletter-footer ' + className}>
      <div className="container">
        <div className="section">
          <div className="logo">
            <img src={logo || SampleLogo} />
          </div>
          <Row>
            <Col xs={24} sm={10} md={10} lg={10} xl={10}>
              <div className="columns">
                {data
                  ? data.map((item, index) => {
                      const { className, menus } = item;
                      return (
                        <div className={className} key={index}>
                          {menus
                            ? menus.map((item, index2) => {
                                const { menu } = item;
                                return (
                                  <p>
                                    <Link>{menu || 'Menu'}</Link>
                                  </p>
                                );
                              })
                            : null}
                          {className == 'right' ? (
                            <div className="social">
                              {facebook ? (
                                <Link href={facebook}>
                                  <Icon type="facebook" theme="filled" />
                                </Link>
                              ) : null}
                              {linkedin ? (
                                <Link href={linkedin}>
                                  <Icon type="linkedin" theme="filled" />
                                </Link>
                              ) : null}
                              {twitter ? (
                                <Link href={twitter}>
                                  <Icon type="twitter-circle" theme="filled" />
                                </Link>
                              ) : null}
                              {instagram ? (
                                <Link href={instagram}>
                                  <Icon type="instagram" theme="filled" />
                                </Link>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      );
                    })
                  : null}
              </div>
            </Col>
            <Col xs={24} sm={14} md={14} lg={14} xl={14}>
              <div className="newsletter">
                <p className="newsletter-title">
                  get news <span>+</span>updates
                </p>
                <p className="text">
                  Stay connected with us! Get updates, follow projects from
                  sketchpad to completion, and more.
                </p>
                <div className="form">
                  <Input type="text" placeholder="Enter your email address" />
                  <Button className="primary-btn">
                    <Icon type="right" />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="legal">
        <div className="container">
          <div className="left">
            <p>{copyright || 'Copyright © 2020. All Rights Reserved.'}</p>
          </div>
          <div className="right">
            <p>
              <Link>Privacy Policy</Link>
            </p>
            <span>|</span>
            <p>
              <Link>Terms of Use</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
