import { delay } from '@arivaa-react/helpers/common';
import { LOGIN, LOGOUT } from 'app-redux/actions';
import React, { useEffect } from 'react';
import { message } from 'antd';
import preprocess from './preprocess';
import { createAction } from '@arivaa-react/redux/helpers/action';
import { goToRoute } from '../@arivaa-react/react-router5';
export default (Main, config) => {
  config = config || {};
  let App = (props) => {
    const { login, translate, emitter, logout } = props;
    useEffect(() => {
      if (props.auth) {
        logout();
        goToRoute('login');
      }
    }, []);
    const callLogin = async (values) => {
      let loggedIn = false;
      let isDisabled = false;
      let response;
      config.spinningSelector && window.startSpinning(config.spinningSelector);
      try {
        response = await login(values);
        loggedIn = true;
      } catch (e) {
        console.error({ e });
        if (e.code === '403') {
          isDisabled = true;
        }
      }
      if (loggedIn) {
        emitter.emit('AUTHENTICATED', {
          status: true,
          type: 'LOGIN',
        });
        await delay(1);
        if (props.auth && props.auth.emailVerified) {
          message.success(translate('login.success'));
        }
      } else {
        if (isDisabled) {
          message.error(translate('login.disabled'));
        } else {
          message.error(translate('login.invalid'));
        }
      }
      config.spinningSelector && window.stopSpinning(config.spinningSelector);
      return response;
    };
    return <Main {...props} login={callLogin} />;
  };
  /**
   * Bind State to props
   * @param dispatch
   * @returns {{Object}}
   */
  const mapStateToProps = ({ auth, emitter }) => {
    return {
      auth,
      emitter,
    };
  };
  /**
   * Bind Redux Actions
   * @param dispatch
   * @returns {{Object}}
   */
  const bindAction = (dispatch) => {
    return {
      login: (data) => {
        return dispatch(
          createAction(LOGIN, {
            ...data,
          })
        );
      },
      logout: () => {
        return dispatch(createAction(LOGOUT));
      },
    };
  };
  App = preprocess(App, {
    connect: [mapStateToProps, bindAction],
    localize: true,
  }); //connect(mapStateToProps, bindAction)(App);
  return App;
};
