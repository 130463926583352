import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { Form, message } from 'antd';
import { getObjectsDiff } from '@arivaa-react/helpers/common';
import { createAction } from '@arivaa-react/redux';
import { CREATE_ENTITY, UPDATE_ENTITY } from 'app-redux/actions';

const spinningSelector = '.new-form';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.setValidations();
  }

  /**
   * Sets Validations for fields
   */
  setValidations() {
    const { data } = this.props;
    this.validations = {
      title: {
        rules: [
          {
            required: true,
            message: 'Title is Required',
          },
        ],
        initialValue: data ? data.title : null,
      },
      route: {
        rules: [
          {
            required: true,
            message: 'Route is Required',
          },
          {
            validator: (rule, value, callback) => {
              if (value.indexOf(' ') !== -1) {
                callback('Route does not contain space');
              } else {
                callback();
              }
            },
          },
        ],
        initialValue: data ? data.route : null,
      },
      html: {
        rules: [
          {
            required: true,
            message: 'HTML is Required',
          },
        ],
        initialValue: data ? data.html : null,
      },
    };
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  handleSubmit(event) {
    event.preventDefault();
    const {
      form,
      translate,
      createRecord,
      hideModal,
      updateRecord,
      data,
      getTableData,
    } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      window.startSpinning(spinningSelector);
      try {
        if (data) {
          const { key } = data;
          const { error, payload } = await updateRecord({
            key,
            ...getObjectsDiff(values, data),
          });
          if (error) {
            throw payload.response;
          }
        } else {
          const { error, payload } = await createRecord(values);
          if (error) {
            throw payload.response;
          }
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        if (getTableData instanceof Function) {
          getTableData();
        }
      } catch (e) {
        console.log(e);
        if (e && e.status === 404) {
          message.error('Record Already Exist');
        } else {
          message.error(translate('common.changes.save.error'));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    createRecord: (data) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          entityName: 'page',
          ...data,
        })
      );
    },
    updateRecord: ({ entityId, ...data }) => {
      const key = data.key;
      delete data.key;
      return dispatch(
        createAction(UPDATE_ENTITY, {
          entityName: 'page',
          entityId: key,
          ...data,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'User-Form';
//Pre process the container with Redux Plugins
export default preProcess(Form.create()(Main), {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
