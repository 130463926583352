/**
 * Authentication Reducer
 */

import { getError } from '@arivaa-react/redux';
import { GET_ENTITY, LOGOUT } from 'app-redux/actions';

/**
 * Create entity reducer based on configuration
 * @param entityName
 * @param config
 * @returns {Function}
 */
export default function (entityName, config) {
  config = config || {};
  const initialState = null;
  return function (state = initialState, action) {
    switch (action.type) {
      case GET_ENTITY + '_' + entityName:
        if (!getError(action)) {
          state = {
            ...state,
          };
          state = {
            ...action.payload.data,
          };
          return state;
        } else {
          return initialState;
        }
      case LOGOUT:
        return initialState;
    }
    return state;
  };
}
