import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Input, Button, Form, Checkbox, Icon, message } from 'antd';
import Link from '@arivaa-react/components/link';
import SocialSignIn from 'containers/social-signin';
import logo from 'images/stocks/logo-new.png';
import { createAction } from '@arivaa-react/redux';
import { delay } from '@arivaa-react/helpers/common';
import { LOGIN, UI_SET_PROPERTY } from 'app-redux/actions';
import withLogin from '../with-login';

const spinningSelector = '.general-form.login';
const { Item } = Form;

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ translate, form, ui, login, setUiProperty }) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const validations = {
    email: {
      rules: [
        { required: true, message: translate('common.email.error.required') },
        { type: 'email', message: translate('common.email.error.invalid') },
      ],
    },
    password: {
      rules: [
        {
          required: true,
          message: translate('common.password.error.required'),
        },
      ],
    },
  };
  /**
   * toggleForm function switches between modals of join form
   * @param name
   */
  const toggleForm = (name) => {
    setUiProperty({
      name: 'activeTab',
      value: name,
    });
  };

  /**
   * On Submit of  Form
   * @param event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      values = { ...values };
      delete values.rememberMe;
      login({
        ...values,
        type: 'local',
      });
    });
  };

  return (
    <div className="general-form login">
      <div className="content">
        <div className="logo-container">
          {/*<span className="text">Priced In</span>*/}
          <img src={logo} align="logo" />
        </div>
        <div className="form">
          <div className="fields">
            <p className="title">{translate('common.login')}</p>
            <Form onSubmit={handleSubmit}>
              <Item hasFeedback={true}>
                {getFieldDecorator(
                  'email',
                  validations.email
                )(
                  <Input
                    addonBefore={<Icon type="mail" />}
                    placeholder={translate('common.email.placeholder')}
                    className="input"
                  />
                )}
              </Item>
              <Item hasFeedback={true}>
                {getFieldDecorator(
                  'password',
                  validations.password
                )(
                  <Input
                    type={'password'}
                    addonBefore={<Icon type="lock" />}
                    placeholder={translate('common.password.placeholder')}
                    className="input"
                  />
                )}
              </Item>
              <div className="more-links inline">
                {/* <div className="remember">
                  <Item hasFeedback={true}>
                    {getFieldDecorator('rememberMe', {
                      valuePropName: 'checked',
                    })(<Checkbox>{translate('login.rememberme')}</Checkbox>)}
                  </Item>
                </div> */}
                <div className="forgot">
                  <Link
                    onClick={ui.activeTab ? () => toggleForm('forgot') : null}
                    routeKey={ui.activeTab ? null : 'forgotPassword'}
                  >
                    {translate('login.forgot')}
                  </Link>
                </div>
              </div>
              <div className="action">
                <Button htmlType={'submit'} className="btn primary-btn">
                  {translate('common.login')}
                </Button>
              </div>
            </Form>
          </div>
          {/*<div className="options shared">
            <div className="option">
              <p className="optionLabel">{translate('login.new')}</p>
              <Link
                className="textLink"
                onClick={ui.activeTab ? () => toggleForm('Register') : null}
                routeKey={ui.activeTab ? null : 'register'}
              >
                {translate('login.register')}
              </Link>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    setUiProperty: (data) => {
      return dispatch(createAction(UI_SET_PROPERTY, data));
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ ui }) => {
  return {
    ui,
  };
};

Main.displayName = 'Login-Form';
//Pre process the container with Redux Plugins
export default preProcess(
  Form.create()(
    withLogin(Main, {
      spinningSelector: 'body',
    })
  ),
  {
    connect: [mapStateToProps, bindAction],
    localize: true,
  }
);
