import { getAuthData } from 'utils/security';
import { isSSR, mobileAndTabletcheck } from '@arivaa-react/helpers/web';

export default () => {
  const check = mobileAndTabletcheck();

  return {
    ui: {
      horizontalMenu: false,
      drawer: !check,
    },
    auth: isSSR() ? null : getAuthData(),
  };
};
