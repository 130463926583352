import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Link from '@arivaa-react/components/link';
import { Menu } from 'antd';

const { SubMenu, Item } = Menu;

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ mode, data }) => {
  return (
    <div>
      <Menu mode={mode}>
        {data
          ? data.map((item, index) => {
              const { name, children, key } = item;
              if (children) {
                return (
                  <SubMenu key={index} title={name}>
                    {children.map((child, index) => {
                      const { name, key } = child;
                      return (
                        <Item key={key} className="sample2-submenu">
                          <Link>
                            <span>{name}</span>
                          </Link>
                        </Item>
                      );
                    })}
                  </SubMenu>
                );
              } else {
                return (
                  <Item key={key}>
                    <Link>
                      <span>{name}</span>
                    </Link>
                  </Item>
                );
              }
            })
          : null}
      </Menu>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
