export var WithoutBackgroundData = [
  {
    type: 'Basic',
    price: '49',
    duration: 'per month',
    properties: [
      {
        property: 'Lunch and networking',
      },
      {
        property: 'keynote talk',
      },
      {
        property: 'Talk to editor session',
      },
    ],
  },
  {
    type: 'Regular',
    price: '100',
    duration: 'per month',
    properties: [
      {
        property: 'Lunch and networking',
      },
      {
        property: 'keynote talk',
      },
      {
        property: 'Talk to editor session',
      },
      {
        property: 'Coffee break',
      },
      {
        property: 'Lunch and networking',
      },
      {
        property: 'keynote talk',
      },
    ],
  },
  {
    type: 'Premium',
    price: '150',
    duration: 'per month',
    properties: [
      {
        property: 'One day conference ticker',
      },
      {
        property: 'Coffee break',
      },
      {
        property: 'Lunch and networking',
      },
      {
        property: 'keynote talk',
      },
    ],
  },
];

export var WithBackgroundData = [
  {
    type: '1 day pass',
    amount: '$129',
    zoom: false,
    children: [
      {
        properties: 'Lunch and networking',
      },
      {
        properties: 'keynote talk',
      },
      {
        properties: 'Talk to editor session',
      },
    ],
  },
  {
    type: 'full pass',
    amount: '$199',
    zoom: true,
    children: [
      {
        properties: 'One day conference ticker',
      },
      {
        properties: 'Coffee break',
      },
      {
        properties: 'Lunch and networking',
      },
      {
        properties: 'keynote talk',
      },
      {
        properties: 'Talk to editor session',
      },
      {
        properties: 'Coffee break',
      },
      {
        properties: 'Lunch and networking',
      },
      {
        properties: 'keynote talk',
      },
    ],
  },
  {
    type: 'Group pass',
    amount: '$79',
    zoom: false,
    children: [
      {
        properties: 'One day conference ticker',
      },
      {
        properties: 'Coffee break',
      },
      {
        properties: 'Lunch and networking',
      },
      {
        properties: 'keynote talk',
      },
      {
        properties: 'Talk to editor session',
      },
    ],
  },
];
