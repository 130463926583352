import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Col, Row, Icon } from 'antd';
import Link from '@arivaa-react/components/link';
import SampleLogo from 'images/logo.png';
import SampleBgImage from 'images/footer.jpg';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of footer with image background
 * className : -
 * data : - array element -[]
 * logo : -
 * bgImage : -
 * copyright :-
 * facebook : -
 * linkedin : -
 */

const Main = ({
  data,
  logo,
  bgImage,
  copyright,
  facebook,
  linkedin,
  className,
}) => {
  return (
    <div className={'image-bg-footer ' + className}>
      <div
        className="content"
        style={
          bgImage
            ? { backgroundImage: 'url(' + bgImage + ')' }
            : { backgroundImage: 'url(' + SampleBgImage + ')' }
        }
      >
        <div className="container">
          <div className="main">
            <Row gutter={48}>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="logo">
                  <img src={logo || SampleLogo} alt={'logo'} />
                </div>
              </Col>
              {data
                ? data.map((item, index) => {
                    const { className, label, menus, email } = item;
                    return (
                      <Col xs={12} sm={12} md={8} lg={6} xl={6} key={index}>
                        <div
                          className={
                            'properties ' +
                            (className == 'address' ? 'address' : '')
                          }
                        >
                          <p className="label">{label}</p>
                          <ul>
                            {menus
                              ? menus.map((item, index2) => {
                                  const { menu, routeKey } = item;
                                  return (
                                    <li key={index2}>
                                      <Link
                                        className="name"
                                        routeKey={routeKey}
                                      >
                                        {menu || 'menu'}
                                      </Link>
                                    </li>
                                  );
                                })
                              : null}
                            {className == 'address' ? (
                              <li className="name">
                                <span className="email">Email :</span> {email}
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      </Col>
                    );
                  })
                : null}
            </Row>
            <p className="line"></p>
            <div className="copyright">
              <div className="left">
                <p>{copyright || 'Copyright © 2020. All Rights Reserved.'}</p>
              </div>
              <div className="right">
                <ul className="border-right">
                  <li>
                    <p className="right-content">Terms</p>
                  </li>
                  <li>
                    <p className="right-content">Policy</p>
                  </li>
                </ul>
                <ul>
                  <li>
                    {facebook ? (
                      <Link href={facebook}>
                        <p className="right-content">
                          <Icon type="facebook" />
                        </p>
                      </Link>
                    ) : null}
                  </li>
                  <li>
                    {linkedin ? (
                      <Link href={linkedin}>
                        <p className="right-content">
                          <Icon type="linkedin" />
                        </p>
                      </Link>
                    ) : null}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
