import React, { Component } from 'react';
import ComponentView from './view';
import { Form, message } from 'antd';
import { createAction } from '@arivaa-react/redux';
import preProcess from 'containers/preprocess';
import { CREATE_ENTITY } from 'app-redux/actions';
const { create } = Form;
const spinningSelector = '.test-form';
/**
 * @description Sample Component
 * @type Component
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { form, hideModal, createRecord, translate, isPhone } = this.props;
    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        return;
      }
      const { value } = values;
      window.startSpinning(spinningSelector);
      try {
        const { error, payload } = await createRecord(value);
        if (error) {
          throw payload.response;
        }
        if (hideModal instanceof Function) {
          hideModal();
        }
        message.success('Message Sent Successfully');
      } catch (e) {
        if (e && e.status === 400 && isPhone) {
          message.error('Invalid Phone Number');
        } else {
          message.error(translate('common.changes.save.error'));
        }
      }
      window.stopSpinning(spinningSelector);
    });
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

Main.displayName = 'Sample-Component';
/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch, ownProps) => {
  const { fieldName, actionData } = ownProps;
  return {
    createRecord: (value) => {
      return dispatch(
        createAction(CREATE_ENTITY, {
          [fieldName]: value,
          ...actionData,
        })
      );
    },
  };
};

Main.displayName = 'SEO-Form';
//Pre process the container with Redux Plugins
export default preProcess(create()(Main), {
  connect: [null, bindAction],
  localize: true,
});
