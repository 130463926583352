import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';

import Footer from 'containers/footer';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ title, html }) => {
  return (
    <div className="public-page terms">
      <div className="container">
        <div className="section">
          <div className="heading">
            {title && title !== '' && (
              <div className="title">
                <p>{title}</p>
              </div>
            )}
          </div>
          <div className="details">
            <div
              className="editor-content"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Blog';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
