import React, { Component } from 'react';
import './style.scss';
import { Row, Col } from 'antd';
import logo from '../../images/stocks/logo-new.png';

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = () => {
  return (
    <div className="about page">
      <div className="container">
        <div className="content">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="section left">
                <div className="logo">
                  <img src={logo} />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: window.app.ui.ABOUT_SECTION_1,
                  }}
                ></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <div
                className="section right"
                dangerouslySetInnerHTML={{
                  __html: window.app.ui.ABOUT_SECTION_2,
                }}
              ></div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

Main.displayName = 'About';
//Pre process the container with Redux Plugins
export default Main;
