import React from 'react';
import './country.scss';
import './styles.scss';
import Flags from './flags.png';
import ReactTelInput from 'react-telephone-input';
/**
 * @description Telephone Input Component
 * @type Component
 * @author
 */
const Main = ({ props }) => {
  return (
    <ReactTelInput defaultCountry="in" flagsImagePath={Flags} {...props} />
  );
};
Main.displayName = 'Telephone-Input';
export default Main;
