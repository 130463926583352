import React, { Component } from 'react';
import { summaryTables, symbolGroups } from '../../helpers/table';
import StatsTable from '../landing/stats-table';
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({ group, symbolData, sats }) => {
  // const groupData = symbolGroups.find(({ type }) => type === group);
  let tableConfig = summaryTables.find(({ tableKey }) => tableKey === group);
  /*if(tableConfig.getTableKey instanceof Function){
    tableConfig = summaryTables.find(({ tableKey }) => tableKey=== tableConfig.getTableKey(symbolData.key))
  }*/
  return /*groupData &&*/ tableConfig ? (
    <div className="returns-table">
      {/* <p>{groupData.title}</p> */}
      <StatsTable
        {...tableConfig}
        sats={sats}
        filterData={(item) => {
          return item.key !== symbolData.key;
        }}
      />
    </div>
  ) : null;
};

Main.displayName = 'Returns-Table';
//Pre process the container with Redux Plugins
export default Main;
