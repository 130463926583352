import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of information with multiple images
 * className : -
 * sectionNumber : -
 * smallTitle : -
 * bigTitle : - array element -[]
 * media : - array element -[]
 * subHeading : -
 * whiteText : -
 * description : - array element -[]
 */

const Main = ({
  sectionNumber,
  smallTitle,
  bigTitle,
  media,
  subHeading,
  whiteText,
  description,
  className,
}) => {
  return (
    <div className={'information multiple-image ' + className}>
      <div className="sections">
        <div className="container">
          <div>
            <div className="section-heading">
              <div className="section-number">
                <p>{sectionNumber}</p>
              </div>
              <div className="section-title">
                <p className="small-title">{smallTitle}</p>
                <div className="big-text">
                  {bigTitle
                    ? bigTitle.map((item, index2) => {
                        return <p key={index2}>{item}</p>;
                      })
                    : null}
                </div>
              </div>
            </div>
            <Row>
              <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <div className="pictures">
                  <Row>
                    {media
                      ? media.map((item, index3) => {
                          return (
                            <Col
                              xs={24}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              key={index3}
                            >
                              <div className="image">
                                <img src={item} />
                              </div>
                            </Col>
                          );
                        })
                      : null}
                  </Row>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <div className="detail">
                  <p className="sub-heading">{subHeading}</p>
                  <p className="white-text">{whiteText}</p>
                  {description
                    ? description.map((item, index4) => {
                        return (
                          <p className="small-text" key={index4}>
                            {item}
                          </p>
                        );
                      })
                    : null}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
