import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleImage from '../../../../images/sample.jpg';
import { Icon } from 'antd';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of testimonial cards component
 * className : -
 * design : -
 * image : -
 * name : -
 * designation : -
 * comment : -
 */

const Main = ({ data, index }) => {
  const { className, title, text, media, icon } = data[index];
  return (
    <div className={'card text ' + className}>
      {media ? (
        <div className="image">
          <img src={media || SampleImage} />
        </div>
      ) : (
        <div className="icon">
          <Icon type={icon || 'check'} />
        </div>
      )}
      <div className="card-details">
        <div className="title">{title || 'Lorem ipsum dolor'}</div>
        {text ? <div className="text">{text}</div> : null}
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {};

Main.displayName = 'UI-Components';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
