import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col } from 'antd';
import SampleImage from 'images/sample.jpg';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of feature with multiple pictures component
 * className : -
 * data : - array element -[]
 * heading : -
 * subDescription : -
 */

const Main = ({ className, data, heading, subDescription }) => {
  return (
    <div className={'feature multiple-pictures ' + className}>
      <div className="container">
        <div className="content">
          <div className="section-heading">
            <p className="title"> {heading || 'Our Features'}</p>
            <p className="description">
              {subDescription ||
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget metus ut nisl eleifend auctor ut vitae orci.'}
            </p>
          </div>
          <div className="details">
            {data
              ? data.map((item, index) => {
                  const { image, counter, name, about, responsive_row } = item;
                  return (
                    <div className="detail" key={index}>
                      <Row className={responsive_row}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className="graphics">
                            <img src={image || SampleImage} alt={'image'} />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className="info">
                            <p className="counter">
                              {counter || 'Feature one'}
                            </p>
                            <p className="name">{name || 'Lorem Ipsum'}</p>
                            <p className="about">
                              {about ||
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
