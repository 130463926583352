export var BlogCardData = [
  {
    image:
      'https://cdn.pixabay.com/photo/2019/07/31/06/03/zodiac-sign-4374404__340.jpg',
    title: 'Aries',
    description:
      'Aries is a cardinal sign that kicks off not only the spring season but also the entire zodiac wheel. ',
    tags: ['passionate', 'motivated'],
    link: 'card',
  },
  {
    image:
      'https://c4.wallpaperflare.com/wallpaper/98/766/329/artistic-zodiac-horoscope-libra-astrology-zodiac-sign-hd-wallpaper-preview.jpg',
    title: 'Libra',
    description:
      'Libra individuals are known for having logical minds and a fair judgment. Librans strive for fairness and justice constantly.',
    tags: ['confident', 'helpful'],
    link: 'card',
  },
  {
    image:
      'https://img5.goodfon.com/wallpaper/nbig/5/ce/znak-zodiaka-kosmos-skorpion.jpg',
    title: 'Scorpio',
    description:
      'Scorpio are focused and competitive. When they want something they just go for it.',
    tags: ['emotional', 'mysterious'],
    link: 'card',
  },
];

export var UserCardData = [
  {
    image: 'https://i.ytimg.com/vi/qwhf0zQTwz4/maxresdefault.jpg',
    name: 'Mark Z. Stone',
    designation: 'CEO',
    description:
      'Working at TDP has had a huge impact on the professional I am today. Having the guidance of a company that houses so much knowledge has challenged me to test the limits of my abilities and push beyond what I thought I could do, to what I know I can achieve!',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/explore',
    linkedin: 'https://in.linkedin.com/',
  },
  {
    image:
      'https://elitedochealthandbeauty.com/wp-content/uploads/2018/06/portrait-of-a-smiling-young-boy-in-the-bathroom-picture-id852415974.jpg',
    name: 'Kate M. Stonehold',
    designation: 'Designer',
    description:
      'Being fortunate myself to have had a truly dedicated mentor early in my professional career, I now take great pride in helping sculpt our young professionals to stay creative at heart, to be strong independent thinkers, and to never ever fear to ask for help. ',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/explore',
    linkedin: 'https://in.linkedin.com/',
  },
  {
    image:
      'https://img.freepik.com/free-photo/woman-smiling-face-with-curly-hair_1139-582.jpg?size=626&ext=jpg',
    name: 'Terry Jackobs,',
    designation: 'Coder',
    description:
      'Building a talented creative team for TDP has been my goal from day one. TDP has an incredible culture within our firm, one that caters to continuing education, collaboration, and assists staff to grow their strengths.',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/explore',
    linkedin: 'https://in.linkedin.com/',
  },
];

export var PortfolioCardData = [
  {
    imageUrl:
      'https://cdn.pixabay.com/photo/2020/02/02/17/10/plane-4813601_960_720.jpg',
    name: 'Aerospace Engineering',
  },
  {
    imageUrl:
      'https://cdn.pixabay.com/photo/2016/02/23/16/20/pigment-1218038_960_720.jpg',
    name: 'Chemical Engineering',
  },
  {
    imageUrl:
      'https://cdn.pixabay.com/photo/2014/12/15/14/04/construction-worker-569149_960_720.jpg',
    name: 'Civil Engineering',
  },
  {
    imageUrl:
      'https://cdn.pixabay.com/photo/2018/01/24/17/33/light-bulb-3104355_960_720.jpg',
    name: 'Electrical/Electronic Engineering',
  },
  {
    imageUrl:
      'https://cdn.pixabay.com/photo/2017/03/23/09/34/artificial-intelligence-2167835_960_720.jpg',
    name: 'Computer Enginerring',
  },
  {
    imageUrl:
      'https://cdn.pixabay.com/photo/2015/09/25/21/12/automobile-958184_960_720.jpg',
    name: 'Mechanical Engineering',
  },
];
