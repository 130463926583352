import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Col, Row, Icon } from 'antd';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of feature with background of solid color
 * className : -
 * data : - array element -[]
 * facebook : -
 * linkedin : -
 * twitter : -
 * copyright : -
 */

const Main = ({ data, facebook, linkedin, twitter, copyright, className }) => {
  return (
    <div className={'solid-bg-footer-1 ' + className}>
      <div className="footer">
        <div className="container">
          <Row gutter={48}>
            {data
              ? data.map((item, index) => {
                  const { label, menus } = item;
                  return (
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                      <div className="properties left" key={index}>
                        <p className="label">{label || 'Label'}</p>
                        <ul>
                          {menus
                            ? menus.map((item, index2) => {
                                const { menu, routeKey } = item;
                                return (
                                  <li key={index2}>
                                    <Link
                                      className="name"
                                      routeKey={routeKey || ''}
                                    >
                                      {menu || 'Menu'}
                                    </Link>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      </div>
                    </Col>
                  );
                })
              : null}
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <div className="properties right">
                <div className="icons">
                  {facebook ? (
                    <Link href={facebook}>
                      <Icon type="facebook" />
                    </Link>
                  ) : null}
                  {linkedin ? (
                    <Link href={linkedin}>
                      <Icon type="linkedin" />
                    </Link>
                  ) : null}
                  {twitter ? (
                    <Link href={twitter}>
                      <Icon type="twitter" />
                    </Link>
                  ) : null}
                </div>
                <p className="name">
                  {copyright || 'Copyright © 2020. All Rights Reserved.'}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
