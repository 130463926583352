import React from 'react';
import { CREATE_ENTITY } from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux/helpers/action';
import preprocess from 'containers/preprocess';
import { Tooltip, Button, Icon, message, DatePicker } from 'antd';
import { ConfirmForm } from '@arivaa-react/components/confirmInput';
import { ModalTrigger } from '@arivaa-react/components/modal';
import moment from 'moment';
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({ record, multiply, translate }) => {
  const { key } = record;
  const confirmConfig = {
    inputLabel: 'Multiplication Factor',
    placeholder: 'Enter Multiplication Factor',
    inputType: 'number',
    onSubmit: async ({ value, dateRange }, { hideModal }) => {
      window.startSpinning('.stock-override');
      try {
        await multiply({
          factor: value,
          symbol: key,
          fromDate: dateRange[0].toISOString(),
          toDate: dateRange[1].toISOString(),
        });
        message.success(translate('common.changes.save.success'));
        hideModal();
      } catch (e) {
        console.error('Error while saving stock override', { e });
        message.error(translate('common.changes.save.error'));
      }
      window.stopSpinning('.stock-override');
    },
    extraItems: [
      {
        label: 'Date Range',
        key: 'dateRange',
        validationRules: [
          { required: true, message: 'Please select the date range' },
        ],
        value: [moment('01/01/2010', 'DD/MM/YYYY'), moment()],
        element: (
          <DatePicker.RangePicker
            disabledDate={function (current) {
              return (
                current &&
                (current > moment().endOf('day') ||
                  current < moment('01/01/2010', 'DD/MM/YYYY'))
              );
            }}
            hideDisabledOptions={true}
          />
        ),
      },
    ],
  };
  return (
    <ModalTrigger
      content={<ConfirmForm {...confirmConfig} />}
      modalProps={{
        title: 'Stock Split Override',
        footer: null,
        className: 'duplicate-form stock-override small-width',
      }}
    >
      <Tooltip title="Stock Split" onClick={() => {}}>
        <Button className="btn blue-btn-text">
          <Icon type="stock" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    multiply: (data) => {
      const action = createAction(CREATE_ENTITY, {
        entityName: 'symbolPrice/multiply',
        ...data,
      });
      return dispatch(action);
    },
  };
};

Main.displayName = 'Stats-Table';

//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [null, bindAction],
  localize: true,
});
