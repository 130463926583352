import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import WithMultiplePictures from '../../features/withMultiplePictures';
import WithNoPicture from '../../features/withNoPicture';
import WithSinglePicture from '../../features/withSinglePicture';
import {
  withMultipleImageData,
  withSingleImageData,
  withNoImageData,
} from '../../config/features.js';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Features With Multiple Pictures'} />
        <div className="section">
          <WithMultiplePictures
            className="multiple -image-feature"
            heading="Features That Online Shoppers Demand From an Online Store"
            subDescription="If you’re the owner of an online store, you need to be up-to-date on these seven key features that online shoppers demand from their internet-based shopping experience."
            data={withMultipleImageData}
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Features With Single Picture'} />
        <div className="section">
          <WithSinglePicture
            className="single-image-feature"
            heading="Latest WhatsApp Hidden Features"
            subDescription="If you frequently use WhatsApp for professional or personal reasons and are looking for ways to enhance your chatting experience, here are some useful Whatsapp Latest tips and tricks that you can benefit from."
            data={withSingleImageData}
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Features With No Picture'} />
        <div className="section">
          <WithNoPicture
            className="no-image-feature"
            data={withNoImageData}
            heading="Our Core Values"
            subDescription=" “Profitability. Growth. Quality. Exceeding customer expectations. These are not examples of values. These are examples of corporate strategies being sold to you as values.” "
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
