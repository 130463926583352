import React from 'react';
import './styles.scss';
import { Row, Col, Icon } from 'antd';
import Link from '@arivaa-react/components/link';

var view = function () {
  const { dashboard, translate } = this.props;
  const { posts, categories } = dashboard || {};

  const array = [
    {
      count: categories,
      label: 'Blog Categories',
      route: 'home.blog-category',
      icon: 'appstore',
    },
    {
      count: posts,
      label: 'Blog Posts',
      route: 'home.blog-post',
      icon: 'edit',
    },
  ];
  return (
    <div className="dashboard">
      <div className="content">
        <p className="page-title mobile-only">{translate('dashboard.title')}</p>
        <Row gutter={16}>
          {array.map((item, index) => {
            const { count, label, route, icon } = item;
            return (
              <Col key={index} xs={24} sm={12} md={12} lg={6} xl={6}>
                <Link routeKey={route}>
                  <div className="dashboard-card">
                    <div className="info">
                      <div className="icon">
                        <Icon type={icon} />
                      </div>
                      <div className="details">
                        <p className="count">{count || 0}</p>
                        <p className="label">{label}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
export default view;
