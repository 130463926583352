import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Button } from 'antd';
import Banner from '../../banner';
import Heading from '../../heading';
import SampleVideo from 'images/bannerVideo.mp4';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({}) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading
          title={'Image Banner with large size | Content - Left Aligned'}
        />
        <Banner
          className={'custom-image-banner'}
          image="https://images.unsplash.com/photo-1473773508845-188df298d2d1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
          size="large"
        >
          <p className="description">Little description</p>
          <p className="title">Title about the website</p>
          <p className="line"></p>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
            metus ut nisl eleifend auctor ut vitae orci. Curabitur sit amet
            facilisis tellus. Nulla placerat dui in cursus consectetur.
          </p>
          <Button className="primary-btn">Let's get started</Button>
        </Banner>
      </div>
      <div className="demo">
        <Heading
          title={'Image Banner with large size | Content - Center Aligned'}
        />
        <Banner
          className={'custom-image-banner'}
          image="https://images.unsplash.com/photo-1471958680802-1345a694ba6d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2032&q=80"
          size="large"
          textAlignment="center"
          contentAlignment="center"
        >
          <p className="description">Little description</p>
          <p className="title">Title about the website</p>
          <p className="line"></p>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
            metus ut nisl eleifend auctor ut vitae orci. Curabitur sit amet
            facilisis tellus. Nulla placerat dui in cursus consectetur.
          </p>
          <Button className="primary-btn">Let's get started</Button>
        </Banner>
      </div>
      <div className="demo">
        <Heading
          title={'Image Banner with large size | Content - Right Aligned'}
        />
        <Banner
          className={'custom-image-banner'}
          image="https://images.unsplash.com/photo-1553949345-eb786bb3f7ba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
          size="large"
          contentAlignment="flex-end"
        >
          <p className="description">Little description</p>
          <p className="title">Title about the website</p>
          <p className="line"></p>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
            metus ut nisl eleifend auctor ut vitae orci. Curabitur sit amet
            facilisis tellus. Nulla placerat dui in cursus consectetur.
          </p>
          <Button className="primary-btn">Let's get started</Button>
        </Banner>
      </div>
      <div className="demo">
        <Heading title={'Video banner with large size'} />
        <Banner
          className={'custom-video-banner'}
          video={SampleVideo}
          size="large"
          contentAlignment="flex-end"
        >
          <p className="description">Little description</p>
          <p className="title">Title about the website</p>
          <p className="line"></p>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget
            metus ut nisl eleifend auctor ut vitae orci. Curabitur sit amet
            facilisis tellus. Nulla placerat dui in cursus consectetur.
          </p>
          <Button className="primary-btn">Let's get started</Button>
        </Banner>
      </div>
      <div className="demo">
        <Heading title={'Image banner with default size '} />
        <Banner
          title="About Us"
          image="https://images.unsplash.com/photo-1471729679899-d5ce5908205a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1952&q=80"
        />
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
