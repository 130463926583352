import React from 'react';
import './styles.scss';
import Header from '../header';
import Footer from '../footer';
import { renderRoutes } from 'react-router-config';
import { getCurrentRoute } from '@arivaa-react/react-router5';

var view = function () {
  const { children, horizontalMenu, route: propRoute } = this.props;
  const { drawer, pageReady } = this.state;
  const route = getCurrentRoute();
  let { header, footer } = route;
  return (
    <div className="app-container">
      {(route.public && !route.guestOnly) || pageReady ? (
        <div>
          {header !== false ? (
            <Header
              type={header}
              route={propRoute}
              drawer={drawer}
              toggleDrawer={this.toggleDrawer.bind(this)}
            />
          ) : null}
          <div
            className={
              'main-container ' +
              (!route.public ? 'secured ' : '') +
              (header == false
                ? ' no-header '
                : (header && header.type) || '') +
              (horizontalMenu ? ' with-horizontal-menu' : {})
            }
          >
            {renderRoutes(propRoute.routes)}
          </div>
          {footer !== false && <Footer />}
        </div>
      ) : null}
    </div>
  );
};
export default view;
