import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleImage from 'images/sample.jpg';
import { Icon } from 'antd';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of user cards component
 * className : -
 * image : -
 * name : -
 * designation : -
 * description : -
 * facebbok : -
 * twitter : -
 * linkedin : -
 */
const Main = ({
  className,
  image,
  name,
  designation,
  description,
  facebook,
  twitter,
  linkedin,
}) => {
  return (
    <div className={'card user ' + className}>
      <div className="detail">
        <div className="image">
          <img src={image || SampleImage} />
        </div>
        <p className="name">{name || 'Default Name'}</p>
        <p className="designation">{designation || 'Default Designation'}</p>
        <p className="line"></p>
        <p className="description">{description || 'Default Description'}</p>
        <div className="social">
          {facebook ? (
            <Link href={facebook}>
              <Icon type="facebook" />
            </Link>
          ) : null}
          {twitter ? (
            <Link href={twitter}>
              <Icon type="twitter" />
            </Link>
          ) : null}
          {linkedin ? (
            <Link href={linkedin}>
              <Icon type="linkedin" />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
