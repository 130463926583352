/**
 * Authentication Reducer
 */
import { getError } from '@arivaa-react/redux';
import { clearAuthData, saveAuthData } from 'utils/security';
import { CREATE_ENTITY, LOGIN, LOGOUT } from '../actions';

/**
 * Reducer Function
 * @param state
 * @param action
 * @returns {*}
 */
export default function (state = null, action) {
  switch (action.type) {
    case LOGIN:
      if (!getError(action)) {
        let data = action.payload.data;
        saveAuthData(action.payload.data);
        return {
          ...data,
        };
      } else {
        return null;
      }
    case CREATE_ENTITY + '_auth_refresh':
      if (!getError(action)) {
        return {
          ...state,
          ...action.payload.data,
        };
      } else {
        clearAuthData();
        return null;
      }
    case LOGOUT:
      clearAuthData();
      return null;
  }
  return state;
}
