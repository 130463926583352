import React, { Component } from 'react';
import './style.scss';
import { Progress } from 'antd';
import bitcoin from 'images/logo.svg';
import earth from 'images/stocks/earth.png';
import moon from 'images/stocks/moon.png';
import { abbreviateCurrency, toCurrencyFormat } from '../../helpers/currency';
function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.round(Math.sign(num) * (Math.abs(num) / 1000)) + 'K'
    : Math.sign(num) * Math.abs(num);
}
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({
  leftSymbollabel,
  rightSymbollabel,
  marketCap,
  image,
  leftComparisonLabel,
  rightComparisonLabel,
  percentage,
  price,
  leftLabel,
  rightLabel,
}) => {
  return (
    <div className="parity-progress">
      <div className="comparison">
        <div className="item">
          <div className="graphic bitcoin">
            <img src={bitcoin} />
          </div>
          <div className="type">
            <p className="name">
              {leftSymbollabel}
              {/* <span className="short">BTC</span> */}
            </p>
            <p className="cap">
              {leftComparisonLabel} : $
              {leftLabel < 1 ? leftLabel : toCurrencyFormat(leftLabel)}
            </p>
          </div>
        </div>
        <span className="versus">VS</span>
        <div className="item">
          <div className="graphic asset">
            <img src={'/images/' + image} />
          </div>
          <div className="type">
            <p className="name">
              {rightSymbollabel}
              {/* <span className="short">{assetShort}</span> */}
            </p>
            <p className="cap">
              {rightComparisonLabel}: ${toCurrencyFormat(rightLabel)}
            </p>
          </div>
        </div>
      </div>
      <br />
      <div className="progress">
        {/* <img src={earth} className="earth" /> */}
        <Progress
          strokeColor={{
            '0%': '#f5902f',
            '100%': '#f56c2f',
          }}
          strokeLinecap="square"
          strokeWidth={52}
          percent={percentage}
        />
        <p className="amount">${abbreviateCurrency(price, 1)}</p>
        {/* <img src={moon} className="moon" /> */}
      </div>
    </div>
  );
};

Main.displayName = 'About';
//Pre process the container with Redux Plugins
export default Main;
