import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Icon, Button } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of price cards component
 * className : -
 * cardType : -
 * price : -
 * duration : -
 * features : -
 * buttonText: -
 */

const Main = ({ data, index }) => {
  const { className, title, price, duration, features, buttonText } = data[
    index
  ];
  return (
    <div className={'card price ' + className}>
      <div className="plan">
        <div className="top">
          <p className="type">{title || 'Card Type'}</p>
          <p className="amount">${price || '00'}</p>
          <p className="duration">{duration || 'per month'}</p>
        </div>
        <ul className="properties">
          {features
            ? features.map((item, index) => {
                return (
                  <li key={index}>
                    <Icon type="check" />
                    {item}
                  </li>
                );
              })
            : null}
        </ul>
        <div className="action">
          <Button className="primary-btn-text button ">
            {buttonText || 'Get Started'}
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {};

Main.displayName = 'UI-Components';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
