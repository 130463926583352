import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Device from '../../devices';
import Heading from '../../heading';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'UI Device - Macbook'} />
        <div className="section">
          <div className="container">
            <Device type="macbook" />
          </div>
        </div>
      </div>
      <div className="demo">
        <Heading title={'UI Device - iMac'} />
        <div className="section">
          <div className="container">
            <Device type="imac" />
          </div>
        </div>
      </div>
      <div className="demo">
        <Heading title={'UI Device - iPad'} />
        <div className="section">
          <div className="container">
            <Device type="ipad" />
          </div>
        </div>
      </div>
      <div className="demo">
        <Heading title={'UI Device - iphone'} />
        <div className="section">
          <div className="container">
            <Device type="iphone" />
          </div>
        </div>
      </div>
      <div className="demo">
        <Heading title={'UI Device - android'} />
        <div className="section">
          <div className="container">
            <Device type="android" />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

Main.displayName = 'UI-Devices-Demo';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
