import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import BlogCard from '../../cards/blogCard';
import TestimonialCard from '../../cards/testimonialCard';
import PortfolioCard from '../../cards/portfolioCard';
import ProductCard from '../../cards/productCard';
import TextCard from '../../cards/textCard';
import ContactCard from '../../cards/contactCard';
import UserCard from '../../cards/userCard';
import EcommerceCard from '../../cards/EcommerceProductCard';
import PriceCard from '../../cards/priceCard';
import appIcon from 'images/app-icon.png';
import reactIcon from 'images/react.png';
import BusinessCard from '../../cards/businessCard';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Blog Card | Case Study Card'} />
        <div className="section">
          <div className="container">
            <BlogCard
              data={[
                {
                  title:
                    'Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur',
                  description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                  media:
                    'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                  link: '',
                  tags: ['tag1', 'tag2', 'tag3'],
                  className: 'custom-class',
                },
              ]}
              index={0}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Testimonial Card'} />
        <div className="section">
          <div className="container">
            <TestimonialCard
              data={[
                {
                  rating: 5,
                  review:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                  media:
                    'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                  name: 'Julia Smith',
                  designation: 'Co-founder at Company',
                  className: 'custom-class',
                },
              ]}
              index={0}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Portfolio Card'} />
        <div className="section">
          <div className="container">
            <PortfolioCard
              data={[
                {
                  title:
                    'Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur Custome Lorem ipsum dolor sit amet consectetur',
                  description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                  media:
                    'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                  link: '',
                  tags: ['React', 'Node', 'Web App'],
                  className: 'custom-class',
                },
              ]}
              index={0}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Product Card'} />
        <div className="section">
          <div className="container">
            <ProductCard
              data={[
                {
                  title: 'Custome Lorem ipsum dolor sit amet consectetur',
                  description:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
                  media:
                    'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_960_720.jpg',
                  link: '',
                  tags: ['tag1', 'tag2', 'tag3'],
                  appIcon: appIcon,
                  className: 'custom-class',
                },
              ]}
              index={0}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Text Card - Image'} />
        <div className="section">
          <div className="container">
            <TextCard
              data={[
                {
                  title: 'Lorem ipsum dolor',
                  text:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  className: 'custom-class',
                  media: reactIcon,
                },
              ]}
              index={0}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Text Card - Icon'} />
        <div className="section">
          <div className="container">
            <TextCard
              data={[
                {
                  title: 'Lorem ipsum dolor',
                  text:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                  icon: 'bar-chart',
                  className: 'custom-class',
                },
              ]}
              index={0}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Contact Card'} />
        <div className="section">
          <div className="container">
            <ContactCard />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Business Card'} />
        <div className="section">
          <div className="container">
            <BusinessCard />
            <BusinessCard
              description={'Have another project ?'}
              title={'Get 30% off'}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'User Card'} />
        <div className="section">
          <div className="container">
            <UserCard
              className={'custom-user-cards'}
              image={
                'https://images.unsplash.com/photo-1542103749-8ef59b94f47e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'
              }
              name={'Courtney Cook'}
              designation={'Graphic Designer'}
              description={
                'Hi, I’m Court! I’m a graphic designer who builds branding & websites for businesses & influencers. I have a wide range of clientele, but I love working with small businesses who are passionate about what they do.'
              }
              facebook={'https://www.facebook.com/'}
              twitter={'https://twitter.com/explore'}
              linkedin={'https://in.linkedin.com/'}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Ecommerce Product Card'} />
        <div className="section">
          <div className="container">
            <EcommerceCard
              className={'custom-Ecommerce-product-cards'}
              images={[
                'https://cdn.pixabay.com/photo/2016/04/15/08/04/strawberries-1330459_960_720.jpg',
                'https://cdn.pixabay.com/photo/2016/06/07/17/15/yogurt-1442034_960_720.jpg',
                'https://cdn.pixabay.com/photo/2016/05/17/14/22/strawberries-1398159_960_720.jpg',
              ]}
              name={'Strawberry - Fruit Seeds'}
              description={
                '1 packet contains Strawberry - 30 Seeds. The fruit is widely appreciated for its characteristic aroma, bright red color, juicy texture, and sweetness.'
              }
              price={'50'}
              pickup={'true'}
              delivery={'true'}
              venderName={'Suyog Food Products'}
              cityName={'Mumbai'}
            />
          </div>
        </div>
      </div>

      <div className="demo">
        <Heading title={'Price Card'} />
        <div className="section">
          <div className="container">
            <PriceCard
              className={'custom-price-cards'}
              cardType={'Premium'}
              price={'100'}
              duration={'per month'}
              features={[
                'One day conference ticker',
                'Lunch and networking',
                'Talk to editor session',
                'One day conference ticker',
                'Coffee break',
                'keynote talk',
              ]}
              buttonText={'Buy Ticket'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

Main.displayName = 'UI-Component-Card-Demo';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
