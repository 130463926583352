import React, { Component } from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import Link from '@arivaa-react/components/link';
import fb from '../../images/fb.png';
import tw from '../../images/tw.png';
import insta from '../../images/ig.png';
import linkedIn from '../../images/linkedin.png';
import logo from '../../images/stocks/logo-new.png';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  return (
    <div className="auth">
      <div className="media">
        <div className="overlay" />
        <div className="text">
          <img src={logo} align="logo" />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};
Main.displayName = 'Sample-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
