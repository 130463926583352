/**
 * Security and authentication related helper methods
 */
import axios from 'axios';
import { parseJSON } from '@arivaa-react/helpers/common';

export const AUTH_STORAGE_KEY = 'arivaa-suite-auth';

/**
 * Set Public API Key for public tables access
 * @type {string}
 */
axios.defaults.headers.common['authorization'] = '';

/**
 * Set Authorization Header
 * @param auth
 */
export function setAuthorizationHeader(auth) {
  if (auth && auth.accessToken) {
    axios.defaults.headers.common['authorization'] = auth.accessToken;
  }
}

/**
 * Clear Authorization Header
 *
 */
export function clearAuthorizationHeader() {
  axios.defaults.headers.common['authorization'] = '';
}

/**
 * Save Auth Data to local storage
 * @param {*} auth
 */
export function saveAuthData(auth) {
  window.localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(auth));
  setAuthorizationHeader(auth);
}
/**
 * Get Auth Data from local storage
 * @param {*} auth
 */
export function getAuthData() {
  let auth = window.localStorage.getItem(AUTH_STORAGE_KEY);
  auth = auth && parseJSON(auth);
  if (auth) {
    setAuthorizationHeader(auth);
  }
  return auth;
}
/**
 * Remove Auth Data from local storage
 * @param {*} auth
 */
export function clearAuthData() {
  window.localStorage.removeItem(AUTH_STORAGE_KEY);
}
