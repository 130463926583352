import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Icon } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of contact with no picture component
 * className : -
 * data : - array element -[]
 * heading : -
 * subDescription : -
 */

const Main = ({ data, className, heading, subDescription }) => {
  return (
    <div className={'feature no-picture ' + className}>
      <div className="container">
        <div className="section-heading">
          <p className="title">{heading || 'Features'}</p>
          <p className="description">
            {subDescription ||
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget metus ut nisl eleifend auctor ut vitae orci.'}
          </p>
        </div>
        <div className="content">
          <Row type="flex">
            {data
              ? data.map((item, index) => {
                  const { icon, featureTitle, featureDetail, bgColor } = item;
                  return (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                      <div className="feature">
                        <div className={'overlay ' + (bgColor || 'light')} />
                        <div className="flex">
                          <div className="icon">
                            <Icon type={icon || 'fire'} />
                          </div>
                          <div className="detail">
                            <p className="feature-title">
                              {featureTitle || 'Lorem Ipsum'}
                            </p>
                            <p className="feature-detail">
                              {featureDetail ||
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget metus ut nisl eleifend auctor ut vitae orci.'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
              : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
