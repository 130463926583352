import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of information with double heading
 * className : -
 * sectionHeading : -
 * sectionTitle : -
 * description1 : -
 * description2 : -
 * image : -
 */

const Main = ({
  sectionHeading,
  sectionTitle,
  description1,
  description2,
  image,
  className,
}) => {
  return (
    <div className={'information double-heading ' + className}>
      <div className="sections">
        <div className="container">
          <div className="content">
            <div className="section-title">
              <p>{sectionHeading}</p>
            </div>
            <div className="section-content">
              <div className="sub-title">
                <p>{sectionTitle}</p>
              </div>
              <div className="flex">
                <div className="left">
                  <p>{description1}</p>
                  <p>{description2}</p>
                </div>
                <div className="right">
                  <div className="image">
                    <img src={image} />
                    <div className="border" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
