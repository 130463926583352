import filesystemRouting from '@arivaa-react/helpers/filesystem-routing';
import UIComponent from '@arivaa-react/ui-components';
import withPageData from 'containers/with-page-data';

let obj = {};
export default obj;

/**
 * Import all the routes in pages directory
 */
filesystemRouting(obj, {
  /**
   * Both need  to be passed through directory
   */
  context: require.context('./', true, /\.js$/),
  fileRequire: function (name) {
    return require(`${name}`);
  },
  wrapComponent: withPageData,
});

// For UI Demos
obj.ui = {
  url: 'ui',
  children: {},
  component: UIComponent,
  routeProps: {
    public: true,
    header: false,
    footer: false,
  },
};

/**
 * Import all the routes in ui-components/demo directory
 */
filesystemRouting(obj.ui.children, {
  parentRouteProps: obj.ui.routeProps,
  /**
   * Both need  to be passed through directory
   */
  context: require.context(
    '../@arivaa-react/ui-components/demos',
    true,
    /\index.js$/
  ),
  fileRequire: function (name) {
    return require(`../@arivaa-react/ui-components/demos/${name
      .replace('./', '')
      .replace('index', '')}`);
  },
});
