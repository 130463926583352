import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Icon, Button, Carousel, Tooltip } from 'antd';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of Ecommerce cards component
 * className : -
 * images : -
 * name : -
 * description : -
 * price : -
 * pickup : -
 * delivery : -
 * venderName : -
 * cityName : -
 */

const Main = ({
  className,
  images,
  name,
  description,
  price,
  pickup,
  delivery,
  venderName,
  cityName,
}) => {
  return (
    <div className={'card ecommerce-product ' + className}>
      <Link routeKey="">
        <div className="slider">
          <Carousel
            pauseOnHover={false}
            pauseOnFocus={false}
            autoplaySpeed={1000}
            autoplay={true}
          >
            {images && images.length > 0 ? (
              images.map((item, index) => {
                return (
                  <div key={index} className="slide">
                    <span
                      className="image"
                      style={{
                        backgroundImage: 'url(' + item + ')',
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div className="slide">
                <span className="image one" />
              </div>
            )}
          </Carousel>
          <div className="wish-list">
            <Tooltip placement="top" title="Add to Wish List">
              <Button className="btn">
                <Icon type="heart" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </Link>
      <div className="details">
        <Link routeKey="">
          <p className="name">{name || 'Lorem ipsum dolor sit amet'}</p>
        </Link>
        <p className="price">${price || ' 00'}</p>
        <p className="description">
          {description ||
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco'}
        </p>
        <div className="delivery-options">
          {pickup == 'true' ? (
            <p>
              <Icon type="check" /> Available for Pickup
            </p>
          ) : null}
          {delivery == 'true' ? (
            <p>
              <Icon type="check" /> Available for Delivery
            </p>
          ) : null}
        </div>
        <p className="vendor">
          <Icon type="shop" />
          Sold By <a>{venderName || 'Vendor Name '}</a> from
          <p>
            <Icon type="environment" /> {cityName || 'City Name'}
          </p>
        </p>
      </div>
      <div className="actions">
        <Button type="primary">Add to cart</Button>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */

const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
