/**
 This file is used to add middlewares. Add middlewares here
 */
// import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
// import { browserHistory } from 'react-router';

/*
 Create History
 */
const middleware = [thunk, promise /*, routerMiddleware(browserHistory)*/];
export default middleware;
