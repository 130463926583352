import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import MultipleInputNewsletter from '../../newsletter/multipleInputNewsletter';
import SingleInputNewsletter from '../../newsletter/singleInputNewsletter';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Newsletter With Mutiple Inputs '} />
        <div className="section">
          <MultipleInputNewsletter
            className="custom-multiple-input"
            heading="start it now!"
            buttonText="Send Message"
          />
        </div>
      </div>

      <div className="demo">
        <Heading title={'Newsletter With Single Input '} />
        <div className="section">
          <SingleInputNewsletter
            className="custom-single-input"
            heading="subscribe to receive the latest product trends"
            buttonText="Subscribe"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
