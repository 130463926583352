import React from 'react';
import './styles.scss';
import Sider from './sider';

import preProcess from '../../containers/preprocess';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ children }) => {
  return (
    <div className="ui-components">
      <div className="layout">
        <Sider />
        <div className="ui-content">{children}</div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
