import React from 'react';
import './styles.scss';
import { Input, Button, Form, Icon } from 'antd';

const FormItem = Form.Item;

/**
 * Returns the JSX Markup for the view
 * @returns {XML}
 */
var view = function () {
  const { translate, form, auth, user } = this.props;
  const { passwordState } = this.state;
  const { getFieldDecorator } = form;
  this.setValidations();
  const { providerData } = user;
  const isSocial =
    user.providerData && providerData[0].providerId !== 'password';
  const { email, password, confirmPassword, oldPassword } = this.validations;
  return isSocial ? null : (
    <div className="section setting security">
      <div className="security-form">
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <FormItem hasFeedback={true} label="Change Email">
            {getFieldDecorator(
              'email',
              email
            )(
              <Input
                addonBefore={<Icon type="mail" />}
                placeholder={translate('common.email.placeholder')}
                className="input"
              />
            )}
          </FormItem>
          <FormItem hasFeedback={true} label="Current Password">
            {getFieldDecorator(
              'oldPassword',
              oldPassword
            )(
              <Input
                addonBefore={<Icon type="lock" />}
                type={passwordState.oldPassword ? 'text' : 'password'}
                prefix={
                  <a
                    onClick={this.togglePasswordVisible.bind(
                      this,
                      'oldPassword'
                    )}
                  >
                    <Icon type={passwordState.oldPassword ? 'eye' : 'eye-o'} />
                  </a>
                }
                placeholder={translate('common.currentPassword.placeholder')}
                className="input"
              />
            )}
          </FormItem>
          <FormItem hasFeedback={true} label="New Password">
            {getFieldDecorator(
              'password',
              password
            )(
              <Input
                addonBefore={<Icon type="lock" />}
                type={passwordState.newPassword ? 'text' : 'password'}
                prefix={
                  <a
                    onClick={this.togglePasswordVisible.bind(
                      this,
                      'newPassword'
                    )}
                  >
                    <Icon type={passwordState.newPassword ? 'eye' : 'eye-o'} />
                  </a>
                }
                placeholder={translate('common.newPassword.placeholder')}
                className="input"
              />
            )}
          </FormItem>
          <div className="action">
            <Button htmlType={'submit'} className="btn primary-btn">
              {translate('common.save.changes')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default view;
