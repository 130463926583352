import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Button, Input } from 'antd';
import Envelope from 'images/envelope.png';
const { TextArea } = Input;

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of with only querry contact component
 * className : -
 * buttonText : -
 * image : -
 */
const Main = ({ className, buttonText, image }) => {
  return (
    <div className={'contact with-only-query ' + className}>
      <div className="container">
        <div className="section-heading">
          <p className="title">have some questions?</p>
        </div>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="image">
              <img src={image || Envelope} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="form">
              <Input type="text" placeholder="First Name" />
              <Input type="text" placeholder="Last Name" />
              <Input type="text" placeholder="What's your Email?" />
              <TextArea placeholder="Your Question" className="message" />
              <Button className="primary-btn">{buttonText || 'submit'}</Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
