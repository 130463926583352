import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import DoubleHeader from '../../header/doubleHeader';
import SingleHeader1 from '../../header/singleHeaderSmallText';
import SingleHeader2 from '../../header/singleHeaderLargeText';
import { Header1Data, Header2Data, Header3Data } from '../../config/header.js';
import logo from 'images/logo.png';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="samples">
      <div className="header-sample">
        <Heading title={'Double Header'} />
        <DoubleHeader
          className="custom-double-header"
          Heading="Welcome to National TRT Clinics"
          Email="help@nationaltrtclinics.com"
          Phone="615-205-7756"
          topButtonText="Make Payment"
          data={Header1Data}
          logoImage={logo}
          bottomButtonText="Get Appointment"
        />
      </div>

      <div className="header-sample">
        <Heading title={'Single header with small text'} />
        <SingleHeader1
          className="custom-single-header-small-text"
          data={Header2Data}
          logoImage={logo}
        />
      </div>
      <div className="header-sample">
        <Heading title={'Single header with large text'} />
        <SingleHeader2
          className="custom-single-header-large-text"
          Data={Header3Data}
          Logo={logo}
          ButtonText="start your project"
        />
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
