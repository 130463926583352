import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col } from 'antd';
import UserCard from '../../cards/userCard';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of user cards list component
 * className : -
 * data : - array element - []
 */

const Main = ({ className, data }) => {
  return (
    <div className={'cards-list user ' + className}>
      <div className="container">
        <div className="members">
          <Row type="flex">
            {data
              ? data.map((item, index) => {
                  const {
                    image,
                    name,
                    designation,
                    description,
                    facebook,
                    twitter,
                    linkedin,
                  } = item;
                  return (
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
                      <UserCard
                        image={image}
                        name={name}
                        designation={designation}
                        description={description}
                        facebook={facebook}
                        twitter={twitter}
                        linkedin={linkedin}
                      />
                    </Col>
                  );
                })
              : null}
          </Row>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
