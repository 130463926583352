import React, { useEffect, useState } from 'react';
import { Table, Icon } from 'antd';
import { GET_ENTITIES } from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux/helpers/action';
import preprocess from 'containers/preprocess';
import Link from '@arivaa-react/components/link';
import { toCurrencyFormat, toDecimal } from '../../helpers/currency';
import satoshi from '../../images/stocks/satoshi-white.png';
import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';

let apiMap = {};
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({
  data,
  api,
  bitcoinPrices,
  title,
  sats,
  getData,
  assetColumnName,
  showSymbol,
  search,
  showTitle,
}) => {
  const isMobile = mobileAndTabletcheck();
  const [loading, setLoading] = useState(false);
  const getPriceInBTC = (text, date) => {
    text = text / parseInt(bitcoinPrices[date || 'today']);
    if (sats) {
      text = Math.round(text * 100000000);
    } else {
      text = text.toFixed(10);
    }
    return text;
  };
  const renderPriceInBTC = (text) => {
    text = getPriceInBTC(text);
    return sats ? (
      <div className="sats">
        <span>{toCurrencyFormat(text)}</span>
        <img src={satoshi} className="satoshi" />
      </div>
    ) : (
      `${text} ₿`
    );
  };
  const renderPercentage = (bitcoinPriceDate) => {
    return function (text, record, key) {
      let baseline;
      if (bitcoinPriceDate === 'today') {
        baseline = getPriceInBTC(record.yesterday, 'yesterday');
      } else {
        baseline = getPriceInBTC(record.today, 'today');
      }
      text = getPriceInBTC(text, bitcoinPriceDate);
      text = toDecimal(
        ((bitcoinPriceDate === 'today' ? text - baseline : baseline - text) *
          100) /
          (bitcoinPriceDate == 'today' ? baseline : text)
      );
      return <span className={text >= 0 ? 'green' : 'red'}>{text} %</span>;
    };
  };

  const columns = [
    {
      title: assetColumnName || 'Asset',
      dataIndex: 'type',
      key: 'type',
      width: isMobile ? 120 : 300,
      render: (text, record) => {
        return (
          <Link
            routeKey="chart"
            routeParams={{
              symbol: record.symbol,
              symboltype: record.symbolType,
            }}
            className="asset-type"
          >
            {isMobile ? '' : text}
            {showSymbol || isMobile
              ? isMobile
                ? `${record.symbol}`
                : ` (${record.symbol})`
              : ''}
          </Link>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'priceToday',
      width: 150,
      render: renderPriceInBTC,
    },
    {
      title: 'Day',
      dataIndex: 'today',
      key: 'today',
      render: renderPercentage('today'),
    },
    {
      title: 'Week',
      dataIndex: 'weekly',
      key: 'weekly',
      render: renderPercentage('weekly'),
    },
    {
      title: 'Month',
      dataIndex: 'monthly',
      key: 'monthly',
      render: renderPercentage('monthly'),
    },
    {
      title: 'YTD',
      dataIndex: 'ytd',
      key: 'ytd',
      render: renderPercentage('ytd'),
    },
    {
      title: '1Y',
      dataIndex: 'yearly',
      key: 'yearly',
      render: renderPercentage('yearly'),
    },
    {
      title: '3Y',
      dataIndex: 'yearly3',
      key: 'yearly3',
      render: renderPercentage('yearly3'),
    },
    {
      title: '5Y',
      dataIndex: 'yearly5',
      key: 'yearly5',
      render: renderPercentage('yearly5'),
    },
    //   {
    //     title: '10 Yr',
    //     dataIndex: 'tenYear',
    //     key: 'tenYear',
    //     render: renderPercentage,
    //   },
  ];
  useEffect(() => {
    (async () => {
      if (api && data.length === 0 && !apiMap[api]) {
        setLoading(true);
        apiMap[api] = {
          loaded: false,
        };
        try {
          await getData({
            entityName: api,
          });
          apiMap[api].loaded = true;
        } catch (e) {
          console.error('Error while getting data for stats table', {
            api,
            title,
            e,
          });
          delete apiMap[api];
        }
        setLoading(false);
      } else {
        if (apiMap[api] && !apiMap[api].loaded) {
          setLoading(true);
          setInterval(() => {
            if (apiMap[api].loaded) {
              setLoading(false);
            }
          }, 500);
        }
      }
    })();
  }, []);
  return search && data.length === 0 ? null : (
    <div className="table metals">
      {showTitle && <p className="label">{title}</p>}

      {api ? (
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      ) : null}
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getData: (data) => {
      const action = createAction(GET_ENTITIES, data);
      action.type = action.type + '_timeline';
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (
  { timeline, bitcoinStats },
  { api, dataFormatter, search, showSymbol }
) => {
  let data = timeline[api];
  if (dataFormatter instanceof Function) {
    data = dataFormatter(data);
  }
  data = data || [];
  if (search) {
    data = data.filter(({ type, symbol }) => {
      type = type || '';
      symbol = symbol || '';
      const searchText = search.toLowerCase();
      return (
        type.toLowerCase().indexOf(searchText) !== -1 ||
        (showSymbol && symbol.toLowerCase().indexOf(searchText) !== -1)
      );
    });
  }
  return {
    data,
    bitcoinPrices: (bitcoinStats && bitcoinStats.prices) || {},
  };
};

Main.displayName = 'Stats-Table';

//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
