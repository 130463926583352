import React from 'react';
import './styles.scss';
import { Form, Input, Button, Select, Row, Col, Icon } from 'antd';
import CodeEditor from 'components/codeEditor';

const { TextArea } = Input;
const { Item } = Form;
const { Option } = Select;
var view = function () {
  const { form, hideModal, data, translate } = this.props;
  const { data: templates } = this.state;
  const {
    from,
    subject,
    template,
    key,
    pdfTemplate,
    templateVariables,
  } = this.validations;
  const { getFieldDecorator, getFieldValue } = form;

  const templateValue = getFieldValue('template') || (data && data.template);

  return (
    <div className="new-form form pdf-template-form">
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="build">
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <div className="inline">
                <Item
                  hasFeedback={true}
                  label={translate('template.key.label')}
                >
                  {getFieldDecorator(
                    'key',
                    key
                  )(
                    <Input
                      addonBefore={<Icon type="edit" />}
                      maxLength={100}
                      disabled={!!data}
                      className="input uppercase"
                      placeholder={translate('template.key.placeholder')}
                    />
                  )}
                </Item>
                <Item
                  hasFeedback={true}
                  label={translate('template.from.label')}
                >
                  {getFieldDecorator(
                    'from',
                    from
                  )(
                    <Input
                      addonBefore={<Icon type="edit" />}
                      maxLength={200}
                      className="input"
                      placeholder={translate('template.from.placeholder')}
                    />
                  )}
                </Item>
                <Item
                  hasFeedback={true}
                  label={translate('template.subject.label')}
                >
                  {getFieldDecorator(
                    'subject',
                    subject
                  )(
                    <Input
                      addonBefore={<Icon type="edit" />}
                      maxLength={200}
                      className="input"
                      placeholder={translate('template.subject.placeholder')}
                    />
                  )}
                </Item>
              </div>
              <div className="inline">
                <Item
                  hasFeedback={true}
                  label={translate('template.templateVariables.label')}
                >
                  {getFieldDecorator(
                    'templateVariables',
                    templateVariables
                  )(
                    <TextArea
                      maxLength={2000}
                      style={{ resize: 'none' }}
                      rows={4}
                    />
                  )}
                </Item>
                <Item label={translate('template.pdfTemplate.label')}>
                  {getFieldDecorator(
                    'pdfTemplate',
                    pdfTemplate
                  )(
                    <Select
                      placeholder={translate(
                        'template.pdfTemplate.placeholder'
                      )}
                      className="select"
                    >
                      {(templates || []).map((template, index) => {
                        const { key, name } = template;
                        return (
                          <Option key={index} value={key}>
                            {key}
                          </Option>
                        );
                      })}
                      <Option key="empty">
                        {translate('template.pdfTemplate.empty')}
                      </Option>
                    </Select>
                  )}
                </Item>
              </div>
              <div className="editor">
                <Item
                  hasFeedback={true}
                  label={translate('template.template.label')}
                >
                  {getFieldDecorator('template', template)(<CodeEditor />)}
                </Item>
              </div>
              <div className="actions">
                <Button htmlType={'submit'} className="btn green-btn save">
                  {translate('template.save')}
                </Button>
                <Button
                  htmlType={'button'}
                  className="btn cancel"
                  onClick={hideModal}
                >
                  {translate('template.cancel')}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col xs={0} sm={0} md={12} lg={12} xl={12}>
          <div className="preview">
            <p className="title">{translate('template.preview')}</p>
            <div className="content">
              <iframe className="iframe" srcDoc={templateValue} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default view;
