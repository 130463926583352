export default {
  entity: 'blog-category',
  entityName: 'Blog Categories',

  defaultSort: {
    sort: 'createdAt',
    sortType: 'desc',
  },
  pagination: {
    type: 'server',
  },
  search: {
    searchOnSubmit: true,
    filters: [
      {
        title: 'Title',
        key: 'title',
        type: 'input',
      },
    ],
  },
  columns: [
    {
      title: 'Title',
      dataIndex: 'title',
    },
  ],
  form: {
    skipTranslate: true,
    elements: [
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        maxLength: 200,
      },
      {
        type: 'textarea',
        name: 'description',
        label: 'Description',
        required: false,
      },
    ],
  },
};
