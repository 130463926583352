import React from 'react';
import './styles.scss';
import preProcess from '../../../containers/preprocess';
import { Layout, Menu, Icon } from 'antd';
import menus from './config';
import { goToRoute } from '@arivaa-react/react-router5';

const { SubMenu, Item } = Menu;
const { Sider } = Layout;

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ drawer }) => {
  /**
   * onClick menu navigate to specific route
   * @returns {*}
   */
  const handleMenuClick = (e) => {
    goToRoute(e.key);
  };

  return (
    <Sider className="sider ui-components-sider" width={250}>
      <Menu onClick={handleMenuClick}>
        {menus.map((item, index) => {
          const { icon, name, children, key } = item;

          if (children) {
            return (
              <SubMenu
                key={index}
                title={
                  <span>
                    <Icon type={icon} />
                    <span>{name}</span>
                  </span>
                }
              >
                {children.map((child, index) => {
                  const { icon, name, key } = child;
                  return (
                    <Item key={key}>
                      <Icon type={icon} />
                      <span>{name}</span>
                    </Item>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Item key={key}>
                <Icon type={icon} />
                <span>{name}</span>
              </Item>
            );
          }
        })}
      </Menu>
    </Sider>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
