import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from 'antd';
import TestimonialCard from '../../cards/testimonialCard';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of testimonial without rating type 2
 * className : -
 * data : - array element -[]
 * title : -
 * buttonText : -
 */

const Main = ({ data, className, title, buttonText }) => {
  return (
    <div className={'testimonial withoutRating-2 ' + className}>
      <div className="sections">
        <div className="container">
          <div className="section-heading">
            <p className="title">{title || 'Lorem Ipsum'}</p>
          </div>
          <Slider dots={true}>
            {data
              ? data.map((data, index1) => {
                  const { slide, content } = data;
                  return (
                    <div className={'slider ' + slide} key={index1}>
                      <div className="single-slide">
                        {content.map((data) => {
                          const { comment, image, name } = data;
                          return (
                            <TestimonialCard
                              design="template3"
                              comment={comment}
                              image={image}
                              name={name}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              : null}
          </Slider>
          <div className="button">
            <Button className="primary-btn">
              {buttonText || 'Write your review'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
