import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Layout, Icon } from 'antd';
import Link from '@arivaa-react/components/link';
import Navigation from '../navigation';

const { Header } = Layout;

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ headerData, logoImage, buttonText }) => {
  return (
    <Header className="header-sample3 fixed">
      <div className="container">
        <div className="content">
          <div className="left">
            <div className="logo-container">
              <Link className="logo">
                <img src={logoImage} alt={'logo'} />
              </Link>
            </div>
          </div>
          <div className="right">
            <div className="sample3-navigation">
              <Navigation mode="horizontal" data={headerData} />
            </div>
          </div>
          <div className="start">
            <p>{buttonText}</p>
          </div>
          <div className="siderIcon">
            <Icon className="trigger" type="menu" />
          </div>
        </div>
      </div>
    </Header>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
