import React from 'react';
import preProcess from 'containers/preprocess';
import Heading from '../../heading';
import IntroductionWithBgImage from '../../introduction/introductionWithBgImage';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({ data }) => {
  return (
    <div className="demos">
      <div className="demo">
        <Heading title={'Introduction Sample 1'} />
        <div className="section">
          <IntroductionWithBgImage
            bgImage="https://cdn.pixabay.com/photo/2018/09/15/08/14/cat-3678858_960_720.jpg"
            title="Let's make something good together"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore exercitation."
            buttonText="get in touch"
            className="custom-introduction"
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
