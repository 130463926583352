import React, { Component } from 'react';
import './style.scss';
import { Row, Col } from 'antd';
import logo from '../../images/stocks/logo-new.png';

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = () => {
  return (
    <div className="about page">
      <div className="container">
        <div className="content">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="section left">
                <div className="logo">
                  <img src={logo} />
                </div>
                <p className="title">What is pricedinbitcoin21?</p>
                <p className="text">
                  Pricedinbitcoin21 denominates and tracks various assets in
                  Bitcoin, including precious metals such as Gold and Silver,
                  public companies such as Apple and Tesla, ETFs (Exchange
                  Traded Funds) such as Select Sector SPDR ETFs and iShares
                  Treasury and Corporate Bond ETFs. More assets are coming soon!
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <div className="section right">
                <p className="title">Why Price Everything in Bitcoin?</p>
                <p className="text">
                  Bitcoin is durable, portable, fungible, verifiable, divisible,
                  scarce, and censorship-resistant. Unlike fiat currency,
                  Bitcoin has a hard cap of 21 million coins, providing a
                  constant measurement. We believe Bitcoin is the best money.
                </p>
              </div>
              <div className="section right">
                <p className="title">How to use this site?</p>
                <p className="text">
                  The homepage shows prices in Bitcoin or sats rather than USD.
                  This is done by dividing the price of an asset in dollars by
                  the price of Bitcoin in dollars. For example, assuming 1oz of
                  gold is $2000 and 1 Bitcoin is $40,000, the price of gold in
                  Bitcoin is 0.05 BTC or 5,000,000 sats. Essentially, it’s just
                  a ratio and pricedinbitcoin21 tracks the ratio over various
                  periods from 1 day to 5 years. When you see a red, negative
                  number that means Bitcoin has increased in price. On the flip
                  side, a green, positive number means that Bitcoin had
                  decreased in price. You can click on any asset and pull up a
                  chart to track prices visually. Currently, prices are updated
                  every weekday at 12 AM UTC, excluding weekends.
                </p>
                <p className="text">
                  We have grouped assets/items under 4 categories: Fiat,
                  Precious Metals, Equity Markets, and U.S. Bond Markets. More
                  groups and assets are coming. In the pipeline, we have
                  commodities (energy, agriculture, livestock, etc.) and common
                  consumer goods (housing, auto, eggs, etc.).
                </p>
              </div>
              <div className="section right">
                <p className="title">Mission and Goal </p>
                <p className="text">
                  Mission: To provide a quick and easy way to track historical
                  prices in Bitcoin.
                </p>
                <p className="text">
                  Goal: We strive to add the most relevant assets and items.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

Main.displayName = 'About';
//Pre process the container with Redux Plugins
export default Main;
