import React, { Component, useState } from 'react';
import StatsTable from './stats-table';
import { Switch, Row, Col, Input } from 'antd';
import './style.scss';
import Banner from '../../components/banner';
import Link from '@arivaa-react/components/link';

const { Search } = Input;

const groups = [
  {
    title: 'View All',
    type: 'all',
  },
  {
    title: 'Fiat',
    type: 'currency',
  },
  {
    title: 'Precious Metals',
    type: 'metals',
  },
  {
    title: 'U.S. Equity Markets',
    type: 'equity',
  },
  {
    title: 'U.S. Bond Markets',
    type: 'bonds',
  },
];
const tableList = [
  {
    title: 'Fiat',
    showSymbol: true,
    assetColumnName: 'CURRENCY',
    api: 'bitcoin-stats/currencies',
    group: 'currency',
  },
  {
    title: 'Precious Metals',
    // api: 'bitcoin-stats/metals',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'METAL',
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'metal';
      });
    },
    group: 'metals',
  },
  {
    title: 'U.S. Equity Markets',
  },
  {
    title: '',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'INDEX',
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'index';
      });
    },
    group: 'equity',
  },
  {
    title: '',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'SECTOR',
    showSymbol: true,
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'sector';
      });
    },
    group: 'equity',
  },
  {
    title: '',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'FAANG',
    showSymbol: true,
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'faang';
      });
    },
    group: 'equity',
  },
  // {
  //   title: 'Stock',
  //   api: 'bitcoin-stats/markets',
  //   dataFormatter: (data) => {
  //     return (data || []).filter(({ symbolType }) => {
  //       return symbolType === 'stock';
  //     });
  //   },
  // },
  {
    title: '',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'TAAND',
    showSymbol: true,
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'taand';
      });
    },
    group: 'equity',
  },
  {
    title: '',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'BITCOIN & CRYPTO',
    showSymbol: true,
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'bitCoinAndCrypto';
      });
    },
    group: 'equity',
  },
  {
    title: '',
    api: 'bitcoin-stats/markets',
    showSymbol: true,
    assetColumnName: 'MVP',
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'mvp';
      });
    },
    group: 'equity',
  },
  {
    title: '',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'OLD MONEY',
    showSymbol: true,
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'oldMoney';
      });
    },
    group: 'equity',
  },
  {
    title: 'U.S. Bond Markets',
    api: 'bitcoin-stats/markets',
    assetColumnName: 'Bond ETFs',
    showSymbol: true,
    dataFormatter: (data) => {
      return (data || []).filter(({ symbolType }) => {
        return symbolType === 'bond';
      });
    },
    group: 'bonds',
  },
];

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = (props) => {
  const [sats, setSats] = useState(true);
  const [filter, setFilter] = useState('all');
  const [search, setSearch] = useState(undefined);
  const filtersList = (
    <div className="filters-list">
      <ul>
        {groups.map(({ title, type }, index) => {
          return (
            <li key={index}>
              <Link
                className={type === filter ? 'selected' : ''}
                onClick={() => setFilter(type)}
              >
                {title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
  return (
    <div className="landing page">
      <div className="container">
        <div className="content">
          <div className="tables">
            <div className="filters">
              <div className="left">
                <div className="switch">
                  <Switch
                    checked={sats}
                    onChange={(checked) => {
                      setSats(checked);
                    }}
                    size="small"
                  />
                  <span className="text">SATS</span>
                </div>
                {filtersList}
              </div>
              <div className="right">
                <div className="search">
                  <Search
                    placeholder="Search for Assets"
                    onChange={(e) => {
                      setSearch((e.target.value || '').trim());
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mobile-filters">{filtersList}</div>
            {tableList
              .filter(({ group }) =>
                filter === 'all' ? true : filter === group
              )
              .map((item, index) => {
                return (
                  <StatsTable
                    {...item}
                    key={index}
                    sats={sats}
                    index={index}
                    showTitle={filter === 'all'}
                    search={search}
                  />
                );
              })}
            <div className="about">
              <Row gutter={96}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {/*<div className="section">
                    <p className="text">
                      Bitcoin trades 24/7; prices may not be accurately
                      reflected.
                    </p>
                  </div>*/}
                  <div className="points">
                    <p className="text">
                      Bitcoin price data according to{' '}
                      <a href="https://www.coingecko.com/en" target="_blank">
                        CoinGecko
                      </a>
                    </p>
                    <p className="text">
                      Asset data according to{' '}
                      <a href="https://twelvedata.com/" target="_blank">
                        twelvedata
                      </a>
                    </p>
                    <p className="text">
                      Disclaimer: The price data are delayed and refreshed once
                      a day at 12 am (UTC).
                    </p>
                  </div>
                </Col>
                {/* <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                  <div className="points">
                    <p className="text">
                      Bitcoin price data according to{' '}
                      <a href="https://www.coingecko.com/en" target="_blank">
                        CoinGecko
                      </a>
                    </p>
                    <p className="text">
                      Asset data according to{' '}
                      <a href="https://twelvedata.com/" target="_blank">
                        twelvedata
                      </a>
                    </p>
                    <p className="text">
                      Disclaimer: The price data are delayed and refreshed once
                      a day at 12 am (UTC).
                    </p>
                  </div>
                </Col>*/}
              </Row>
              <Row gutter={96}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="information">
                    <div className="section">
                      <p className="title">About</p>
                      <p className="text">
                        Pricedinbitcoin21 denominates various assets in Bitcoin,
                        including precious metals such as Gold and Silver,
                        public companies such as Apple and Tesla, ETFs (Exchange
                        Traded Funds) such as Select Sector SPDR ETFs and
                        iShares Treasury and Corporate Bond ETFs. More assets
                        are coming soon!
                      </p>
                      <p className="text">
                        For inquiries:{' '}
                        <a href="mailto: admin@pricedinbitcoin21.com">
                          admin@pricedinbitcoin21.com
                        </a>{' '}
                        or follow us on Twitter.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="section">
                    <p className="title">Why Price an Asset in Bitcoin?</p>
                    <p className="text">
                      Bitcoin is durable, portable, fungible, verifiable,
                      divisible, scarce, and censorship resistant. Unlike fiat
                      currency, Bitcoin has a hard cap of 21 million coins,
                      providing a constant measurement. We believe Bitcoin is
                      the best money.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Main.displayName = 'Home';

//Pre process the container with Redux Plugins
export default Main;
