import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Col, Row, Icon } from 'antd';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of footer with background of solid color
 * className : -
 * data : - array element -[]
 * logo : -
 * companySlogan : -
 * facebook : -
 * linkedin : -
 * instagram : -
 * twitter : -
 * copyright : -
 */

const Main = ({
  data,
  logo,
  companySlogan,
  facebook,
  linkedin,
  instagram,
  twitter,
  copyright,
  className,
}) => {
  return (
    <div className={'solid-bg-footer-2 ' + className}>
      <div className="container">
        <div className="top-section">
          <Row>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="logo">
                <img src={logo} />
                <p className="slogan">{companySlogan || 'company slogan'}</p>
              </div>
            </Col>
            {data
              ? data.map((item, index) => {
                  const { menus } = item;
                  return (
                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                      <div className="column">
                        {menus.map((item, index2) => {
                          const { menu } = item;
                          return (
                            <p>
                              <Link>{menu}</Link>
                            </p>
                          );
                        })}
                      </div>
                    </Col>
                  );
                })
              : null}
          </Row>
        </div>
        <div className="bottom-section">
          <div className="social">
            {facebook ? (
              <Link href={facebook}>
                <Icon type="facebook" theme="filled" />
              </Link>
            ) : null}
            {linkedin ? (
              <Link href={linkedin}>
                <Icon type="linkedin" theme="filled" />
              </Link>
            ) : null}
            {twitter ? (
              <Link href={twitter}>
                <Icon type="twitter-square" theme="filled" />
              </Link>
            ) : null}
            {instagram ? (
              <Link href={instagram}>
                <Icon type="instagram" theme="filled" />
              </Link>
            ) : null}
          </div>
          <p>{copyright || 'Copyright © 2020. All Rights Reserved.'}</p>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
