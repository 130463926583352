import { Component } from 'react';
import ComponentView from './view';

/**
 * @description Tags
 * @type Component
 * @author Inderdeep
 */
export default class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      tags: props.value || [],
    };
    this.onChange = this.onChange.bind(this);
  }

  /**
   * ComponentDidMount Hook
   */
  componentDidMount() {}

  /**
   * Component Will Receive props hook
   * @param newProps
   */
  componentWillReceiveProps(newProps) {
    //check if value has been changed, then only update in state
    if (JSON.stringify(newProps.value) !== JSON.stringify(this.props.value)) {
      this.setState({
        tags: newProps.value,
      });
    }
  }

  /**
   * onChange function for tags input
   * @param tags
   */
  onChange(tags) {
    const { onChange, regex, onError } = this.props;
    if (regex) {
      if (!regex.test(tags[tags.length - 1]) && onError instanceof Function) {
        onError(tags[tags.length - 1]);
      }
      tags = tags.filter((tag) => regex.test(tag));
    }
    this.setState({ tags });
    if (onChange instanceof Function) {
      onChange(tags);
    }
  }

  /**
   * on Remove of Tag
   * @param removedTag
   */
  removedTag(removedTag) {
    const value = this.state.value.filter((tag) => {
      if (tag.value) {
        return tag.value !== removedTag;
      } else {
        return tag !== removedTag;
      }
    });
    this.setState({ value });
    const { onChange } = this.props;
    if (onChange && onChange instanceof Function) {
      onChange(value, removedTag);
    }
  }

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

Main.displayName = 'Tags';
