import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleImage from 'images/sample.jpg';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of banner component
 * backgroundImageURL : SampleImage
 * backgroundVideoURL : -
 * contentAlignment : imageBanner - left , videoBanner - center
 * size : default(height : 220px)
 * children: -
 * title: "Title"
 * textAlignment : imageBanner - left , videoBanner - center
 */

const Main = ({
  className,
  image,
  video,
  contentAlignment,
  size,
  children,
  title,
  textAlignment,
}) => {
  return (
    <div
      className={
        'banner ' +
        (size === 'large' ? 'large ' : 'small') +
        (image ? ' image-banner ' : '') +
        (video ? ' video-banner ' : '') +
        className
      }
      style={
        image
          ? { backgroundImage: 'url(' + image + ')' }
          : !video
          ? { backgroundImage: 'url(' + SampleImage + ')' }
          : null
      }
    >
      {video ? (
        <video autoPlay="autoPlay" loop="loop" muted="muted" width="100%">
          <source src={video} type="video/mp4" />
        </video>
      ) : null}
      <div className="overlay " />
      <div
        className="container "
        style={{ justifyContent: contentAlignment, textAlign: textAlignment }}
      >
        {children ? (
          <div className="content">{children}</div>
        ) : (
          <div className="content">
            <p className="title">{title || 'Title'}</p>
            <p className="line" />
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
