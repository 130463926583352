import React, { Component } from 'react';
import ComponentView from './view';
import preProcess from 'containers/preprocess';
import { createAction } from '@arivaa-react/redux';
import { UI_SET_PROPERTY } from 'app-redux/actions';

/**
 * @description Home Container
 * @type Container
 * @author Inderdeep
 */
class Main extends Component {
  /**
   * Container
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
    };
  }

  closeDrawer() {
    const { toggleDrawer, drawer } = this.props;
    toggleDrawer(drawer);
  }

  /**
   * ComponentDidMount Hook
   */
  async componentDidMount() {}

  /**
   * Render Method
   * @returns {*}
   */
  render() {
    return ComponentView.bind(this)();
  }
}

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    toggleDrawer: (drawer) => {
      return dispatch(
        createAction(UI_SET_PROPERTY, {
          name: 'drawer',
          value: !drawer,
        })
      );
    },
  };
};
/**
 * Bind State to props
 * @returns {{Object}}
 * @param state
 */
const mapStateToProps = (state) => {
  return {
    horizontalMenu: state.ui.horizontalMenu,
    drawer: !!state.ui.drawer,
  };
};
Main.displayName = 'Home';
const Page = preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});

Page.routeProps = {
  header: 'secured',
  public: false,
  footer: false,
};
export default Page;
