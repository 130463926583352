import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Form, Input, Button } from 'antd';
const FormItem = Form.Item;

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of newsletter with multiple inputs
 * className : -
 * heading : -
 * buttonText : -
 */

const Main = ({ className, heading, buttonText }) => {
  return (
    <div className={'newsletter singleInput ' + className}>
      <div className="detail">
        <p className="title">{heading || 'Lorem Ipsum'}</p>
        <div className="form">
          <Form>
            <FormItem>
              <Input placeholder="Enter your email" className="input" />
            </FormItem>
            <div className="action">
              <Button className="primary-btn">{buttonText || 'submit'}</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
