import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of without querry contact component
 * className : -
 * phoneNumber : -
 * email : -
 * address : -
 */
const Main = ({ className, phoneNumber, email, address }) => {
  return (
    <div className={'contact without-query ' + className}>
      <div className="container">
        <div className="details">
          <p className="highlight">Looking to get in touch?</p>
          <p className="title">Contact Us</p>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="column">
              <div className="list">
                <div className="item">
                  <p className="message">
                    Please contact us using the following details:
                  </p>
                  <div>
                    <p>
                      <span className="label">Tel:</span>
                      <span className="value">{phoneNumber}</span>
                    </p>
                    <p>
                      <span className="label">Email:</span>
                      <span className="value">{email}</span>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <p className="message">
                    For any new enquiries please contact us on:
                  </p>
                  <div>
                    <p>
                      <span className="label">Tel:</span>
                      <span className="value">{phoneNumber}</span>
                    </p>
                    <p>
                      <span className="label">Email:</span>
                      <span className="value">{email}</span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="column">
              <div className="head-office">
                <p className="highlight">Head Office</p>
                <div className="text">
                  {address
                    ? address.map((item, index2) => {
                        return <p key={index2}>{item}</p>;
                      })
                    : null}
                </div>
                <div>
                  <p>
                    <span className="label">Tel:</span>
                    <span className="value">{phoneNumber}</span>
                  </p>
                  <p>
                    <span className="label">Fax:</span>
                    <span className="value">{phoneNumber}</span>
                  </p>
                  <p>
                    <span className="label">Email:</span>
                    <span className="value">{email}</span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
