import React, { Component } from 'react';
import { Table } from 'antd';
import { renderPercentage, summaryTables } from '../../helpers/table';
import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({ sats, symbolData, symboltype }) => {
  const isMobile = mobileAndTabletcheck();
  const table = summaryTables.find(({ tableKey }) => tableKey === symboltype);
  const hideColumns = table?.hideColumns || [];
  const columns = [
    {
      title: isMobile ? '24h' : 'Day',
      dataIndex: 'today',
      key: 'todayPercentage',
      render: renderPercentage('today', sats),
    },
    {
      title: isMobile ? '7d' : 'Week',
      dataIndex: 'weekly',
      key: 'weekly',
      render: renderPercentage('weekly', sats),
    },
    {
      title: isMobile ? '30d' : 'Month',
      dataIndex: 'monthly',
      key: 'monthly',
      render: renderPercentage('monthly', sats),
    },
    {
      title: 'YTD',
      dataIndex: 'ytd',
      key: 'ytd',
      render: renderPercentage('ytd', sats),
    },
    {
      title: '1Y',
      dataIndex: 'yearly',
      key: 'yearly',
      render: renderPercentage('yearly', sats),
    },
    {
      title: '3Y',
      dataIndex: 'yearly3',
      key: 'yearly3',
      render: renderPercentage('yearly3', sats),
    },
    {
      title: '5Y',
      dataIndex: 'yearly5',
      key: 'yearly5',
      render: renderPercentage('yearly5', sats),
    },
  ].filter(({ key }) => {
    return hideColumns ? hideColumns.indexOf(key) === -1 : true;
  });
  return (
    <div className="returns-table table">
      <Table columns={columns} dataSource={[symbolData]} pagination={false} />
    </div>
  );
};

Main.displayName = 'Returns-Table';
//Pre process the container with Redux Plugins
export default Main;
