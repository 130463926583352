import React from 'react';
import { Icon } from 'antd';

export var Header1Data = [
  {
    key: 'landing',
    name: 'Home',
  },
  {
    key: 'about',
    name: 'About Us ',
  },
  {
    key: 'understanding',
    name: 'Understanding Low T',
  },
  {
    name: 'Treatment',
    children: [
      {
        key: 'howTreatmentWorks',
        name: 'How Treatment Works',
      },
      {
        key: 'treatmentOptions',
        name: 'Treatment Options',
      },
    ],
  },
  {
    key: 'forms',
    name: 'Forms',
  },
  {
    key: 'whyUs',
    name: 'Why Choose Us',
  },
  {
    key: 'contactUs',
    name: 'Contact Us',
  },
];

export var Header2Data = [
  {
    key: 'about',
    name: 'About Us',
    children: [
      {
        key: 'about.finance',
        name: 'About Sixty Plus Finance',
      },
      {
        key: 'about.values',
        name: 'Values',
      },
      {
        key: 'about.team',
        name: 'The Leadership Team',
      },
    ],
  },
  {
    key: 'support',
    name: 'Customer Support',
    children: [
      {
        key: 'support.obligations',
        name: 'Obligations of your plan',
      },
      {
        key: 'support.advice',
        name: 'Financial Advice',
      },
      {
        key: 'support.safeguards',
        name: 'Your Safeguards',
      },
    ],
  },
  {
    key: 'products',
    name: 'Products',
    children: [
      {
        key: 'products.plan',
        name: 'Home Reversion Plan',
      },
      {
        key: 'products.ownership',
        name: 'Property Ownership',
      },
      {
        key: 'products.benefits',
        name: 'Benefits',
      },
      {
        key: 'products.eligibility',
        name: 'Eligibility',
      },
    ],
  },
  {
    key: 'faqs',
    name: 'FAQs',
  },
  {
    key: 'apply',
    name: 'Apply',
    children: [
      {
        key: 'apply.how',
        name: 'How do I Apply?',
      },
      {
        key: 'apply.costs',
        name: 'What are the upfront Costs?',
      },
    ],
  },
  {
    key: 'calculator',
    name: 'Calculator',
  },
  {
    key: 'forms',
    name: 'Forms',
    children: [
      {
        key: 'forms.SFS',
        name: 'SFS Form',
      },
    ],
  },
  {
    key: 'contact',
    name: 'Contact Us',
  },
  {
    key: 'login',
    name: (
      <span className="adviser">
        <Icon type="lock" /> Adviser Portal
      </span>
    ),
  },
];

export var Header3Data = [
  {
    key: 'portfolios',
    name: 'portfolios',
  },
  {
    key: 'services',
    name: 'services',
    children: [
      {
        key: 'about.finance',
        name: 'About Sixty Plus Finance',
      },
      {
        key: 'about.values',
        name: 'Values',
      },
      {
        key: 'about.team',
        name: 'The Leadership Team',
      },
    ],
  },
  {
    key: 'industries',
    name: 'industries',
    children: [
      {
        key: 'support.obligations',
        name: 'Obligations of your plan',
      },
      {
        key: 'support.advice',
        name: 'Financial Advice',
      },
      {
        key: 'support.safeguards',
        name: 'Your Safeguards',
      },
      {
        key: 'support.obligations',
        name: 'Obligations of your plan',
      },
      {
        key: 'support.advice',
        name: 'Financial Advice',
      },
      {
        key: 'support.safeguards',
        name: 'Your Safeguards',
      },
    ],
  },
  {
    key: 'about',
    name: 'about',
    children: [
      {
        key: 'products.plan',
        name: 'Home Reversion Plan',
      },
      {
        key: 'products.ownership',
        name: 'Property Ownership',
      },
      {
        key: 'products.benefits',
        name: 'Benefits',
      },
      {
        key: 'products.eligibility',
        name: 'Eligibility',
      },
    ],
  },
];
