import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleImage from 'images/sample.jpg';
import Link from '@arivaa-react/components/link';
import { ellipsis } from '@arivaa-react/helpers/web';

import StarRatings from 'react-star-ratings';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of portfolio card component
 * image : -
 * tags : - array element - []
 * title : -
 * description : -
 */

const Main = ({ data, index }) => {
  const { className, media, tags, title, link, rating, review } = data[index];

  return (
    <Link routeKey={link} className={'card portfolio ' + className}>
      <div className="image">
        <img src={media || SampleImage} />
      </div>
      <div className="card-details">
        <div className="tags">
          {tags
            ? tags.map((item, index) => {
                return (
                  <Link key={index} className="tag">
                    {item}
                  </Link>
                );
              })
            : null}
        </div>
        <div className="title">
          {ellipsis(
            title || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            10
          )}
        </div>
        <div className="review">
          <div className="stars">
            <StarRatings
              rating={rating || 5}
              starRatedColor="#f7be59"
              numberOfStars={5}
              name="rating"
              starDimension="25"
              starSpacing="2px"
            />
          </div>
          <div className="text">
            {review ||
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
          </div>
        </div>
      </div>
    </Link>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
