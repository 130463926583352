import { getError } from '@arivaa-react/redux';
import { GET_DASHBOARD } from '../actions';

/**
 * Dashboard Reducer
 * @param state
 * @param action
 */
export default function (state = {}, action) {
  switch (action.type) {
    case GET_DASHBOARD: {
      if (!getError(action)) {
        return action.payload.data;
      } else {
        return {};
      }
    }
  }
  return state;
}
