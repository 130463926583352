import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import { Button, Input, Form } from 'antd';

import Banner from '../../banner';

const { Item } = Form;
const { TextArea } = Input;
/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ description, title }) => {
  return (
    <div className="card business">
      <Banner
        className={'custom-image-banner'}
        image="https://images.unsplash.com/photo-1473773508845-188df298d2d1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
        size="small"
      >
        <p className="description">{description || 'Become our Partner'}</p>
        <p className="title">{title || 'Earn 30% Profit'}</p>
        <p className="line"></p>
        <Button className="primary-btn">Learn More</Button>
      </Banner>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (state) => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
