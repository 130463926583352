/*
 Bootstrap redux
 */
import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import {
  configureActions,
  createAction as createActionFn,
  getAxiosInstance,
  setAxiosInstance,
  getApiActionExecuter,
} from './helpers/action';

import { getError, getResponseError } from './helpers/axios';

export function createStore(obj) {
  const {
    initialState = {},
    actions = [],
    reducers = {},
    middlewares = [],
    enhancers = [],
    configureThirdPartyActions,
  } = obj || {};
  configureActions(actions);
  /**
    Combine enhancers and middlewares
  */
  const composedEnhancers = compose(
    applyMiddleware(thunk, promise, ...middlewares),
    ...enhancers
  );
  const store = createReduxStore(
    combineReducers({
      ...reducers,
    }),
    {
      ...initialState,
    },
    composedEnhancers
  );
  if (configureThirdPartyActions instanceof Function) {
    configureThirdPartyActions(store.dispatch);
  }
  return store;
}

export const createAction = createActionFn;

export {
  getAxiosInstance,
  setAxiosInstance,
  getError,
  getResponseError,
  getApiActionExecuter,
};
