import React from 'react';
import './styles.scss';
import { Form, Input, Button, Select, Icon } from 'antd';
import { getRouteUrls } from '@arivaa-react/react-router5';

const { Option } = Select;
const { Item } = Form;
var view = function () {
  const { form, hideModal, pageConfiguration, translate } = this.props;
  const urls = getRouteUrls().filter((url) => {
    return (
      pageConfiguration.findIndex(({ routeUrl }) => url === routeUrl) === -1
    );
  });

  const { routeUrl, pageTitle } = this.validations;
  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form">
        <div className="form">
          <Item
            hasFeedback={true}
            label={translate('systemPage.routeUrl.label')}
          >
            {getFieldDecorator(
              'routeUrl',
              routeUrl
            )(
              <Select
                placeholder={translate('systemPage.routeUrl.placeholder')}
              >
                {urls.map((url, index) => {
                  return (
                    <Option key={index} value={url}>
                      {url}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Item>
          <Item
            hasFeedback={true}
            label={translate('systemPage.pageTitle.label')}
          >
            {getFieldDecorator(
              'pageTitle',
              pageTitle
            )(
              <Input
                addonBefore={<Icon type="edit" />}
                maxLength={200}
                className="input"
                placeholder={translate('systemPage.pageTitle.placeholder')}
              />
            )}
          </Item>
        </div>
        <div className="actions">
          <Button htmlType={'submit'} type="primary" className="btn green-btn">
            {translate('systemPage.save')}
          </Button>
          <Button
            htmlType={'button'}
            onClick={hideModal}
            className="btn red-btn-text"
          >
            {translate('systemPage.cancel')}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
