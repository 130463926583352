import React from 'react';
import './styles.scss';
import { Form, Input, Button } from 'antd';
import ColorPicker from 'components/colorPicker';
const { TextArea } = Input;
const { Item } = Form;

var view = function () {
  const { form, hideModal, data, translate } = this.props;
  const { key, value, description } = this.validations;
  const { getFieldDecorator } = form;
  return (
    <Form onSubmit={this.handleSubmit.bind(this)}>
      <div className="new-form configuration-form">
        <div className="form">
          <Item hasFeedback={true} label={translate('theme.key.label')}>
            {getFieldDecorator(
              'key',
              key
            )(
              <Input
                maxLength={100}
                disabled={!!data}
                className="input uppercase"
                placeholder={translate('theme.key.placeholder')}
              />
            )}
          </Item>
          <Item hasFeedback={true} label={translate('theme.value.label')}>
            {getFieldDecorator('value', value)(<ColorPicker />)}
          </Item>
          <Item hasFeedback={true} label={translate('theme.description.label')}>
            {getFieldDecorator(
              'description',
              description
            )(
              <TextArea
                maxLength={2000}
                placeholder={translate('theme.description.placeholder')}
                className="input"
              />
            )}
          </Item>
        </div>
        <div className="actions">
          <Button htmlType={'submit'} type="primary" className="btn green-btn">
            {translate('theme.submit')}
          </Button>
          <Button
            htmlType={'button'}
            onClick={hideModal}
            className="btn red-btn-text"
          >
            {translate('theme.cancel')}
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default view;
