import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import SampleImage from 'images/sample.jpg';
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of blog cards component
 * image : -
 * tags : - array element - []
 * title : -
 * description : -
 */

const Main = ({ data, index }) => {
  const { className, media } = data[index];

  return (
    <div className={'card image-card ' + className}>
      <div className="image">
        <img src={media || SampleImage} />
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
