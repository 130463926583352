import React, { Component, useEffect } from 'react';
import './style.scss';
import { Row, Col } from 'antd';
import BlogCard from '../../components/blogCard';
import SampleImage from '../../images/sample.jpg';
import { GET_ENTITIES, GET_ENTITY } from '../../redux/actions';
import { createAction } from '../../@arivaa-react/redux/helpers/action';
import { formatDate } from '@arivaa-react/helpers/date-util';
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({ pageData }) => {
  const { post, relatedPosts } = pageData || {};

  return pageData ? (
    <div className="single-blog page">
      <div className="container">
        <div className="content">
          <Row gutter={48}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <div className="blog-details">
                <div className="tags">
                  {post.tags
                    ? post.tags.map((item, index) => {
                        return (
                          <a key={index} className="tag">
                            {item}
                          </a>
                        );
                      })
                    : null}
                </div>
                <p className="title">{post.title}</p>
                <p className="time-stamp">
                  Published on{' '}
                  {formatDate(post.createdAt, 'MM/DD/YYYY hh:mm A')}
                </p>
                <div className="image">
                  <img src={post.image} />
                </div>
                <div
                  className="editor-content"
                  dangerouslySetInnerHTML={{ __html: post.description }}
                ></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={6} lg={8} xl={8}>
              <div className="right">
                {(relatedPosts || []).map((post, index) => {
                  return (
                    <BlogCard
                      key={index}
                      data={{
                        ...post,
                        className: 'custom-class',
                      }}
                    />
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  ) : null;
};

Main.url = 'post/:key';
Main.displayName = 'Blog-Details';

Main.routeProps = {
  getPageData: async function (route, { params }) {
    try {
      let data = {};
      const { key } = params;
      data.post = (
        await createAction(GET_ENTITY, {
          entityName: 'blog-post',
          entityId: key,
        }).payload
      ).data;
      data.relatedPosts = (
        await createAction(GET_ENTITIES, {
          entityName: 'blog-post',
          search: `category;${data.post.category}`,
          all: true,
        }).payload
      ).data.filter(({ key }) => {
        return key !== data.post.key;
      });
      return data;
    } catch (e) {
      console.error('Error whle getting page data', { e });
      e.page = '/error404';
      throw e;
    }
  },
};
//Pre process the container with Redux Plugins
export default Main;
