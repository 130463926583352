import React, { useState } from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Button, Col, Row, Select, Tag } from 'antd';
import BlogCard from '../cards/blogCard';
import TestimonialCard from '../cards/testimonialCard';
import PortfolioCard from '../cards/portfolioCard';
import ProductCard from '../cards/productCard';
import ImageCard from '../cards/imageCard';
import JobCard from '../cards/jobCard';
import PriceCard from '../cards/priceCard';
import TextCard from '../cards/textCard';
import UserCard from '../cards/userCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const { Option } = Select;
const { CheckableTag } = Tag;
/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */
const Main = ({
  title,
  description,
  type,
  customCard,
  data,
  slider,
  slidesToShow,
  fillbg,
  fourColumns,
  header,
  viewAll,
  filters,
}) => {
  let card;

  const [checked, setChecked] = useState(false);

  function cardType(data, index) {
    switch (type) {
      case 'blog':
        card = <BlogCard data={data} index={index} />;
        break;
      case 'testimonial':
        card = <TestimonialCard data={data} index={index} />;
        break;
      case 'portfolio':
        card = <PortfolioCard data={data} index={index} />;
        break;
      case 'product':
        card = <ProductCard data={data} index={index} />;
        break;
      case 'text':
        card = <TextCard data={data} index={index} />;
        break;
      case 'price':
        card = <PriceCard data={data} index={index} />;
        break;
      case 'user':
        card = <UserCard data={data} index={index} />;
        break;
      case 'image':
        card = <ImageCard data={data} index={index} />;
        break;
      case 'job':
        card = <JobCard data={data} index={index} />;
        break;
      default:
        card = <BlogCard data={data} index={index} />;
    }
    return card;
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow || 3,
    slidesToScroll: 1,
  };

  function onChangeSelect(value) {
    console.log(`selected ${value}`);
  }

  function handleChange(checked) {
    setChecked(checked);
  }

  return (
    <div className={'card-list ' + (fillbg ? ' fill' : '')}>
      <div className="container">
        <div className="section">
          {header === false ? null : (
            <div className="header">
              <div className="text">
                <p className="title">
                  {title || 'LOREM IPSUM DOLOR SIT AMET CONSECTETUR'}
                </p>
                <p className="description">
                  {description ||
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'}
                </p>
              </div>
              {viewAll === false ? (
                <div className="action" />
              ) : (
                <div className="action">
                  <Button className="primary-btn">View All</Button>
                </div>
              )}
            </div>
          )}
          {filters ? (
            <div className="filters">
              <CheckableTag checked={true} onChange={handleChange}>
                All
              </CheckableTag>
              {filters.map((item, index) => {
                return (
                  <CheckableTag
                    index={index}
                    checked={checked}
                    onChange={handleChange}
                  >
                    {item}
                  </CheckableTag>
                );
              })}
            </div>
          ) : null}
          {slider ? (
            <Slider {...settings}>
              {data.map((item, index) => {
                cardType(data, index);
                return (
                  <div className="slide" key={index}>
                    {card}
                  </div>
                );
              })}
            </Slider>
          ) : (
            <Row gutter={24} type="flex" align="top">
              {data.map((item, index) => {
                cardType(data, index);
                return (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={fourColumns ? 6 : 8}
                    lg={fourColumns ? 6 : 8}
                    xl={fourColumns ? 6 : 8}
                  >
                    {card}
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
