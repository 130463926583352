import React, { useEffect, useState } from 'react';
import preprocess from './preprocess';
export default (Main, config) => {
  config = config || {};
  let App = (props) => {
    const [pageData, setPageData] = useState(props.pageData);
    const getData = async () => {
      window.startSpinning('.app-container');
      try {
        if (
          props.getPageData instanceof Function &&
          props.pageData === undefined
        ) {
          setPageData(
            await props.getPageData(props.route, {
              dispatch: props.dispatch,
              ...props.match,
            })
          );
        } else {
          setPageData(props.pageData);
        }
      } catch (e) {
        console.error('Error while getting page data', { e });
      }
      window.stopSpinning('.app-container');
    };
    useEffect(() => {
      getData();
    }, [props.match.url]);
    return <Main {...props} pageData={pageData} getPageData={getData} />;
  };
  App = preprocess(App, {
    connect: [null, null],
  });
  return App;
};
