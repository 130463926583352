/**
 * This is the client side entry point for the React app.
 */
import React from 'react';
import { Routes } from './routes';
//For Spinner Functionality
import '@arivaa-react/helpers/spinner';
//For providing polyfill for safari and older browsers for toBlob in canvas
import 'blueimp-canvas-to-blob';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import './styles';
const App = () => {
  return (
    <ConfigProvider locale={enUS}>
      <Routes />
    </ConfigProvider>
  );
};

export default App;
