/**
 * Dummy Browser for server side rendering
 * Normally we get window as undefined while server side rendering so to
 * tackle that where we know that we will need certain properties globally
 * in window or any other browser objects, Use this dummy browser
 *
 */
import config from '../config';

if (typeof window == 'undefined') {
  global.window = {};
  window.navigator = {
    userAgent: 'test',
  };
  window.location = {};
  window.addEventListener = () => {};
}

if (window.app === undefined) {
  window.app = {
    ...window.app,
  };
  window.app.config = {};
  window.app.host = config.HOST;
  window.app.server = config.HOST + config.API_PREFIX;
  window.app.darkMode = false;
  window.app.socialLogin = config.socialLogin;
}
