import React from 'react';
import preProcess from 'containers/preprocess';
import './styles.scss';
import CKEditor from '@arivaa-react/ckeditor4-wrapper';

import axios from 'axios';
import { STORAGE } from 'client/constants/api';
/**a
 * @description Ckeditor Container
 * @type Container
 * @author Inderdeep
 */
const Main = (props) => {
  return (
    <CKEditor
      {...props}
      uploadHeaders={[
        {
          name: 'authorization',
          value: axios.defaults.headers.common['authorization'],
        },
      ]}
      uploadUrl={STORAGE}
      uploadRequestData={[
        {
          name: 'fileCode',
          value: 'ckEditorFile',
        },
      ]}
    />
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};
Main.displayName = 'CKEditor-Container';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [null, bindAction],
  localize: true,
});
