import React from 'react';
import { CREATE_ENTITY } from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux/helpers/action';
import preprocess from 'containers/preprocess';
import { Tooltip, Button, Icon, message, Radio } from 'antd';
import { ConfirmForm } from '@arivaa-react/components/confirmInput';
import { ModalTrigger } from '@arivaa-react/components/modal';
import FilePicker from '@arivaa-react/components/filePicker';
import moment from 'moment';
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({ record, upload, translate }) => {
  const { key } = record;
  const confirmConfig = {
    disableValueInput: true,
    onSubmit: async ({ file, override }, { hideModal }) => {
      window.startSpinning('.stock-override');
      try {
        await upload({
          symbol: key,
          file,
          override,
        });
        message.success(translate('common.changes.save.success'));
        hideModal();
      } catch (e) {
        console.error('Error while saving stock override', { e });
        message.error(
          e?.response?.data?.message || translate('common.changes.save.error')
        );
      }
      window.stopSpinning('.stock-override');
    },
    extraItems: [
      {
        label: 'CSV File',
        key: 'file',
        validationRules: [
          { required: true, message: 'Please select the file to upload' },
        ],
        element: (
          <FilePicker
            uploadProps={{
              accept: 'text/csv',
            }}
          />
        ),
      },
      {
        label: 'Override existing data',
        validationRules: [{ required: false }],
        key: 'override',
        value: false,
        element: (
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        ),
      },
    ],
  };
  return (
    <ModalTrigger
      content={<ConfirmForm {...confirmConfig} />}
      modalProps={{
        title: 'Upload Price Data',
        footer: null,
        className: 'duplicate-form stock-override small-width',
      }}
    >
      <Tooltip title="Upload Price Data" onClick={() => {}}>
        <Button className="btn blue-btn-text">
          <Icon type="upload" />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    upload: (data) => {
      const action = createAction(CREATE_ENTITY, {
        entityName: 'symbolPrice/upload',
        ...data,
        createFormData: true,
      });
      return dispatch(action);
    },
  };
};

Main.displayName = 'Stats-Table';

//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [null, bindAction],
  localize: true,
});
