import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Button } from 'antd';
import SampleImage from 'images/sample.jpg';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of pricing with background
 * className : -
 * data : - array element -[]
 * heading : -
 * subDescription : -
 * image : -
 * buttonText : -
 */

const Main = ({
  className,
  heading,
  image,
  subDescription,
  data,
  buttonText,
}) => {
  return (
    <div className={'pricing with-background ' + className}>
      <div
        className="tickets"
        style={
          image
            ? { backgroundImage: 'url(' + image + ')' }
            : { backgroundImage: 'url(' + SampleImage + ')' }
        }
      >
        <div className="color-overlay">
          <div className="container">
            <div className="ticket-details">
              <div className="pricing-heading">
                <p className="title">{heading || 'Lorem Ipsum'}</p>
                <p className="sub-title">
                  {subDescription || 'Lorem ipsum dolor sit amet'}
                </p>
              </div>
              <div className="plans">
                {data
                  ? data.map((item, index) => {
                      const { type, amount, children, zoom } = item;

                      return (
                        <div
                          className={'plan' + (zoom ? ' zoom' : '')}
                          key={index}
                        >
                          <p className="type">{type || 'regular'}</p>
                          <p className="amount">${amount || '00'}</p>

                          <ul className="properties">
                            {children.map((child, index2) => {
                              const { properties } = child;
                              return <li key={index2}>{properties}</li>;
                            })}
                          </ul>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="button-div">
                <Button className="primary-btn-text button">
                  {buttonText || 'Get Ticket'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
