import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import PublicHeader from './publicHeader';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of single header with small text
 * className : -
 * data : - array element -[]
 * logoImage : -
 */

const Main = ({ data, logoImage, className }) => {
  return (
    <div className={className}>
      <PublicHeader navigationData={data} logo={logoImage} />
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
