import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of location with address
 * className : -
 * description : -
 * address : -
 */

const Main = ({ description, address, className }) => {
  return (
    <div className={'location ' + className}>
      <div className="section">
        <div className="container">
          <div className="location-content">
            <Row>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="map">
                  <iframe
                    width="100%"
                    height="320"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=695%20President%20Place%2C%20Suite%20200%2C%20Smyrna%2C%20TN%2037167.&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className="location-text">
                  <p>{description}</p>
                  <p className="blue-text">
                    You can find us at
                    <br />
                    {address}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
