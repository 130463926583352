import React from 'react';
import './styles.scss';
import CKEditor from 'ckeditor4-react';
CKEditor.editorUrl = '/ckeditor/ckeditor.js';

var view = function () {
  const { value } = this.state;
  return (
    <CKEditor
      onBeforeLoad={(CKEDITOR) => {
        CKEDITOR.disableAutoInline = true;
      }}
      onInstanceReady={this.onReady.bind(this)}
      data={value}
      config={{
        extraPlugins: 'uploadimage,codesnippet,emojione,prism',
        uploadUrl: 'http://dummy.com',
        codeSnippet_theme: 'coy',
      }}
      onChange={this.onChange}
    />
  );
};
export default view;
