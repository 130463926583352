import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from '../../cards/testimonialCard';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of testimonial with rating
 * className : -
 * data : - array element -[]
 * title : -
 */

const Main = ({ data, className, title }) => {
  return (
    <div className={'testimonial withRating ' + className}>
      <div className="container">
        <div className="section-heading">
          <p className="title">{title || 'Lorem Ipsum'}</p>
        </div>
        <Slider dots={true}>
          {data
            ? data.map((data, index1) => {
                const { slide, content } = data;
                return (
                  <div className={'slider ' + slide} key={index1}>
                    <div className="single-slide">
                      {content
                        ? content.map((data, index2) => {
                            const { comment, image, name, position } = data;
                            return (
                              <TestimonialCard
                                design="template1"
                                image={image}
                                comment={comment}
                                name={name}
                                designation={position}
                              />
                            );
                          })
                        : null}
                    </div>
                  </div>
                );
              })
            : null}
        </Slider>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
