import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Button } from 'antd';
import SampleVideo from '../../../../images/video.mov';
import SampleImage from '../../../../images/sample.jpg';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of information with video
 * className : -
 * title : -
 * subHeading : -
 * condition : -
 * description : -
 * buttonText : -
 * video : -
 * mediaShape: "circle" or "rectangle"
 * amount : -
 * image : -
 */

const Main = ({
  title,
  subHeading,
  condition,
  description,
  buttonText,
  video,
  mediaShape,
  image,
  amount,
  className,
  reverse,
  fillbg,
}) => {
  return (
    <div className={'information media ' + className + (fillbg ? ' fill' : '')}>
      <div className="container">
        <div className="section">
          <Row gutter={48} className={reverse ? 'reverse' : ''}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className="details">
                <p className="title">
                  {title || 'Lorem ipsum dolor sit amet, consectetur.'}
                </p>
                {subHeading ? (
                  <p className="sub-heading">{subHeading}</p>
                ) : null}
                {condition ? <p className="condition">{condition}</p> : null}
                {description ? (
                  <p className="description">{description}</p>
                ) : null}
                {buttonText ? (
                  <Button className="primary-btn">
                    {buttonText || "Let's start"}
                  </Button>
                ) : null}
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              {video ? (
                <div
                  className={
                    'media-container ' +
                    (mediaShape === 'rectangle' ? 'rectangle-shape' : '')
                  }
                >
                  <div className="banner-media-style">
                    <p className="media">
                      <video
                        autoPlay="autoPlay"
                        loop="loop"
                        muted="muted"
                        width="300"
                        height="150"
                      >
                        <source src={video || SampleVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </p>
                  </div>
                  {amount ? (
                    <div className="priceCircle">
                      <p className="price">Our Price</p>
                      <p className="amount">
                        {amount || '00'} <span className="lightText">$</span>
                        <span className="smallText">per month </span>
                      </p>
                    </div>
                  ) : null}
                </div>
              ) : (
                <div
                  className={
                    'media-container ' +
                    (mediaShape === 'rectangle' ? 'rectangle-shape' : '')
                  }
                >
                  <div className="banner-media-style">
                    <p className="media">
                      <img src={image || SampleImage} />
                    </p>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {};

Main.displayName = 'UI-Components';
//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
