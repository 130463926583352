/**
 * Pre process a container with redux wrappers
 */
import { connect } from 'react-redux';
import { localize } from 'react-localize-redux';
import { withRouter } from 'react-router';
import withSecurity from './with-security';
export default function (container, pluginsConfiguration) {
  if (pluginsConfiguration.localize) {
    container = localize(container, 'locale');
  }
  if (pluginsConfiguration.withRouter) {
    container = withRouter(container);
  }

  if (pluginsConfiguration.connect) {
    container = connect.apply(this, pluginsConfiguration.connect)(container);
  }
  if (pluginsConfiguration.withSecurity) {
    container = withSecurity(container, pluginsConfiguration.withSecurity);
  }
  return container;
}
