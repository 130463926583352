import React from 'react';
import './styles.scss';
import preProcess from 'containers/preprocess';
import { Row, Col, Form, Input, Button } from 'antd';

/**
 * @description Sample Component
 * @type Container
 * @author Jasjot
 */

/**
 * @default values of newsletter with multiple input
 * className : -
 * heading : -
 * buttonText : -
 */

const Main = ({ className, heading, buttonText }) => {
  return (
    <div className={'newsletter multipleInput ' + className}>
      <div className="container">
        <div className="form">
          <Form>
            <p className="section-heading">{heading || 'Lorem Ipsum'}</p>
            <Row>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Input type="text" placeholder="Name" />
              </Col>

              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Input type="text" placeholder="Email" />
              </Col>

              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Input type="text" placeholder="Message" />
              </Col>

              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Button htmlType="submit" className="primary-btn">
                  {buttonText || 'submit'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {};
};

/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = () => {
  return {};
};

//Pre process the container with Redux Plugins
export default preProcess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
